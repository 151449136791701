/* eslint-disable no-nested-ternary */
import React, { useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";

import { TextField } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { useFetchOptions } from "services/utils";

import { ParseToDate } from "app/utils/functions";
import i18n from "app/i18n";
import constants from "common/constants";

const CustomGqlSelector = ({
  onBlur,
  onChange,
  value = null,
  defaultValue,
  disabled,
  placeholder,
  entity,
  limit,
  getOptionKey,
  getOptionLabel,
  getOptionSelected,
  getOptionDisabled,
  fields,
  searchFields,
  searchIntFields,
  disableSearchInt,
  where,
  orderBy,
  multiple,
  disableSearch,
  setOptionsLength,
  searchFieldsAllowNullValues
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [searchInt, setSearchInt] = useState();
  const options = useFetchOptions(
    disableSearchInt,
    entity,
    limit,
    search,
    searchInt,
    fields,
    searchFields,
    searchIntFields,
    where,
    orderBy,
    searchFieldsAllowNullValues
  );

  // console.log("entity, limit, search, fields, searchFields, where", entity, limit, search, fields, searchFields,where)

  if (options.error) {
    return <Alert severity="error">{options.error.message}</Alert>;
  }
  let defaultVal;
  if (defaultValue && getOptionKey) {
    defaultVal = options.data?.find(
      d => d[getOptionKey] === defaultValue
    );
  }
  if (options?.data && setOptionsLength) {
    setOptionsLength(options.data.length);
  }
  return (
    <Autocomplete
      options={options.data || []}
      loading={options.loading}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t(placeholder)}
          onBlur={onBlur}
        />
      )}
      onChange={(_event, newValue) => onChange(newValue)}
      onInputChange={(_event, newSearch) => {
        if (!disableSearch) {
          setSearch(newSearch);
          setSearchInt(parseInt(newSearch, 10));
        }
      }}
      value={multiple ? value || [] : value}
      getOptionDisabled={getOptionDisabled}
      disabled={disabled}
      multiple={multiple}
      defaultValue={defaultVal}
    />
  );
};

CustomGqlSelector.defaultProps = {
  placeholder: "input.placeholder.default",
  getOptionLabel: option => option.name,
  getOptionSelected: (option, currValue) =>
    option.id === currValue?.id,
  fields: ["id", "name"],
  searchFields: ["name"],
  searchIntFields: [],
  where: {},
  limit: 15,
};

export const ticketCategorySearchIntFields = ["id"];

const ticketCategoryFields = [
  "id",
  "name",
  "entity",
  "visible_to_user",
  "visible_to_valet",
];
export const TicketCategorySelector = ({
  entity,
  onBlur,
  onChange,
  value,
  disabled,
}) => (
  <CustomGqlSelector
    entity="ticket_category"
    placeholder="input.placeholder.ticketCategory"
    where={{ entity: { _eq: entity } }}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    searchIntFields={ticketCategorySearchIntFields}
    disableSearchInt={false}
    limit={100}
    orderBy={{ name: "asc" }}
    fields={ticketCategoryFields}
    getOptionLabel={({ id, name, entity }) =>
      `#${id} - ${name} - ${i18n.t("ticketCategory." + entity)}`
    }
  />
);

export const ticketEventCategorySearchIntFields = ["id"];

const ticketEventCategoryFields = [
  "id",
  "name",
  "description",
  "visible_to_driver",
  "user_data",
];
export const TicketEventCategorySelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  placeholder,
}) => (
  <CustomGqlSelector
    entity="ticket_event_category"
    placeholder={
      placeholder || "input.placeholder.ticketEventCategory"
    }
    fields={ticketEventCategoryFields}
    searchIntFields={ticketEventCategorySearchIntFields}
    disableSearchInt={false}
    onBlur={onBlur}
    getOptionLabel={({ id, name }) =>
      `#${id} - ${name}`
    }
    onChange={onChange}
    value={value}
    disabled={disabled}
    limit={60}
    orderBy={{ name: "asc" }}
  />
);

export const ticketEventSubcategorySearchIntFields = ["id"];

const ticketEventSubcategoryFields = [
  "id",
  "name",
  "description",
  "ticket_templates",
  "ticket_event_category_id",
];
export const TicketEventSubcategorySelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  placeholder,
  ticketEventCategoryID,
}) => (
  <CustomGqlSelector
    entity="ticket_event_sub_category"
    placeholder={
      placeholder || "input.placeholder.ticketEventSubcategory"
    }
    fields={ticketEventSubcategoryFields}
    searchIntFields={ticketEventSubcategorySearchIntFields}
    disableSearchInt={false}
    getOptionLabel={({ id, name }) =>
      `#${id} - ${name}`
    }
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    orderBy={{ name: "asc" }}
    where={
      ticketEventCategoryID
        ? {
          ticket_event_category_id: { _eq: ticketEventCategoryID },
        }
        : {}
    }
  />
);

export const driverFields = ["id", "name", "surname"];
export const driverSearchFields = ["name", "surname"];
export const driverSearchIntFields = ["id"];

export const DriverSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  accountId,
  multiple,
}) => {
  const where = {};
  if (accountId) {
    // eslint-disable-next-line no-underscore-dangle
    where._or = [
      {
        owned_accounts: {
          id: { _eq: accountId },
        },
      },
      {
        joined_accounts: {
          account_id: { _eq: accountId },
        },
      },
    ];
  }
  return (
    <CustomGqlSelector
      entity="driver"
      placeholder="input.placeholder.driver"
      fields={driverFields}
      searchFields={driverSearchFields}
      searchIntFields={driverSearchIntFields}
      disableSearchInt={false}
      getOptionLabel={({ id, name, surname }) =>
        `${name} ${surname} #${id}`
      }
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      where={where}
      multiple={multiple}
      limit={100}
    />
  );
};

export const locationSearchIntFields = ["id"];

export const LocationSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  cityID,
  districtID,
  accountID,
}) => (
  <CustomGqlSelector
    entity="location"
    placeholder="input.placeholder.location"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    limit={300}
    disabled={disabled}
    getOptionLabel={({ id, name }) =>
      id && name ? `#${id} - ${name}` : undefined
    }
    searchIntFields={locationSearchIntFields}
    disableSearchInt={false}
    where={
      cityID &&
      districtID &&
      (accountID
        ? {
          _or: [
            { private: { _eq: false } },
            {
              _and: [
                { private: { _eq: true } },
                {
                  location_permissions: {
                    _and: [
                      { account_id: { _eq: accountID } },
                      { status: { _eq: "ACTIVE" } },
                    ],
                  },
                },
              ],
            },
          ],
          city_id: { _eq: cityID },
          district_id: { _eq: districtID },
        }
        : {
          city_id: { _eq: cityID },
          district_id: { _eq: districtID },
        })
    }
  />
);

const fleetLocationSearchIntFields = ["id"];

export const FleetLocationSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  cityName
}) => (
  <CustomGqlSelector
    entity="location"
    placeholder="input.placeholder.location"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    limit={300}
    disabled={disabled}
    searchIntFields={fleetLocationSearchIntFields}
    disableSearchInt={false}
    getOptionLabel={({ id, name }) =>
      `#${id || ""} - ${name || ""}`
    }
    where={
      cityName && {
        fleet_city: { name: { _eq: cityName } }
      }
    }
  />
);

const fleetCitySearchIntFields = ["id"];

export const FleetCitySelector = ({
  onBlur,
  onChange,
  value,
  disabled
}) => (
  <CustomGqlSelector
    entity="fleet_city"
    placeholder="locationDetails.basicInformations.fleetCity"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    searchIntFields={fleetCitySearchIntFields}
    disableSearchInt={false}
    getOptionLabel={({ id, name }) =>
      id && name ? `#${id} - ${name}` : undefined
    }
    limit={300}
    disabled={disabled}
  />
);

const accountSearchIntFields = ["id"];

export const AccountSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  where,
}) => (
  <CustomGqlSelector
    entity="account"
    placeholder="input.placeholder.account"
    getOptionLabel={({ id, name }) =>
      id && name ? `${name} #${id}` : undefined
    }
    searchIntFields={accountSearchIntFields}
    disableSearchInt={false}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    where={where}
    limit={30}
  />
);

const affiliateSearchIntFields = ["id"];

export const AffiliateSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  where,
}) => (
  <CustomGqlSelector
    entity="affiliate"
    placeholder="input.placeholder.affiliate"
    getOptionLabel={({ id, name }) =>
      id && name ? `${name} #${id}` : undefined
    }
    searchIntFields={affiliateSearchIntFields}
    disableSearchInt={false}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    where={where}
    limit={30}
  />
);

const vehicleFields = ["id", "name", "plate"];
const vehicleSearchFields = ["name", "plate"];
const vehicleSearchIntFields = ["id"];

export const VehicleSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  where,
}) => (
  <CustomGqlSelector
    entity="vehicle"
    fields={vehicleFields}
    placeholder="input.placeholder.vehicle"
    getOptionLabel={({ id, name, plate }) => `#${id} - ${name} - ${plate}`}
    searchFields={vehicleSearchFields}
    searchIntFields={vehicleSearchIntFields}
    disableSearchInt={false}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    where={where}
    limit={60}
    orderBy={{ name: "asc" }}
  />
);

const ratePlanFields = [
  "id",
  "name",
  "application_fee",
  "membership_fee",
];
const ratePlanSearchFields = ["name"];
export const RatePlanSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => (
  <CustomGqlSelector
    entity="rate_plan"
    placeholder="affiliate.placeholderLabel.selectRatePlan"
    fields={ratePlanFields}
    searchFields={ratePlanSearchFields}
    getOptionLabel={({ name }) => `${name}`}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
  />
);
const internalReasonFields = ["id", "name", "description"];
const internalReasonSearchFields = ["name", "description"];
export const InternalReasonSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  type,
  types,
}) => (
  <CustomGqlSelector
    entity="internal_reason"
    placeholder="input.placeholder.internalReason"
    fields={internalReasonFields}
    searchFields={internalReasonSearchFields}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    getOptionLabel={({ description }) => `${description}`}
    where={
      (type && { name: { _eq: type } }) ||
      (types && { name: { _in: types } })
    }
  />
);

export const CitySelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => (
  <CustomGqlSelector
    entity="location_city"
    placeholder="address.selectCity"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
  />
);

export const DistrictSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  cityID,
}) => (
  <CustomGqlSelector
    entity="location_district"
    placeholder="address.selectTown"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    limit={100}
    where={cityID && { city_id: { _eq: cityID } }}
  />
);

const locationTypeSearchFields = ["type"];
const locationTypeFields = ["id", "type"];

export const LocationTypeSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => (
  <CustomGqlSelector
    entity="location_type"
    placeholder="address.selectType"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    fields={locationTypeFields}
    getOptionLabel={({ type }) => (type ? `${i18n.t("address." + type)}` : "")}
    searchFields={locationTypeSearchFields}
    disabled={disabled}
  />
);

// const notifTemplateSearchFields = ["title.tr"];
const notifTemplateFields = [
  "id",
  "type",
  "title",
  "content",
  "name",
];
export const NotificationTemplateSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  templateType,
}) => (
  <CustomGqlSelector
    entity="email_sms_template"
    placeholder={
      templateType === constants.NOTIFICATION_TYPES.MAIL
        ? "input.placeholder.selectTemplate"
        : templateType === constants.NOTIFICATION_TYPES.SMS
          ? "input.placeholder.selectSmsTemplate"
          : "input.placeholder.notificationSmsTemplate"
    }
    fields={notifTemplateFields}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    getOptionLabel={({ name }) => `${name}`}
    where={{ type: { _eq: templateType } }}
  // searchFields={notifTemplateSearchFields}
  />
);

const insurancePlanSearchFields = ["name"];
const insurancePlanFields = [
  "id",
  "name",
  "price",
  "price_frequency",
];

export const InsurancePlanTemplateSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  getOptionDisabled,
  defaultValue,
}) => (
  <CustomGqlSelector
    entity="damage_waiver_plan"
    placeholder="input.placeholder.selectDamageWaiver"
    fields={insurancePlanFields}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    getOptionDisabled={getOptionDisabled}
    disabled={disabled}
    getOptionLabel={({ name, price, price_frequency }) =>
      name
        ? `${name} - ${i18n.t(
          "time." + price_frequency?.toLowerCase()
        )} ${price} ${price_frequency === "RESERVATION" ? "%" : "₺"
        }`
        : ""
    }
    getOptionKey="id"
    searchFields={insurancePlanSearchFields}
    defaultValue={defaultValue}
    disableSearch
    limit={100}
  />
);

const reservationSearchFields = [];
const reservationFields = [
  "id",
  "account_id",
  "driver_id",
  "vehicle_id",
  "start_date",
  "end_date",
];
export const ReservationSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  accountId,
  driverId,
}) => {
  const where = {};
  if (accountId) {
    where.account_id = { _eq: accountId };
  }
  if (driverId) {
    where.driver_id = { _eq: driverId };
  }
  return (
    <CustomGqlSelector
      entity="reservation"
      placeholder="input.placeholder.reservation"
      fields={reservationFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      getOptionLabel={({
        start_date: startDate,
        end_date: endDate,
      }) =>
        `Başlangıç Tarihi: ${startDate &&
        ParseToDate(startDate)} Bitiş Tarihi: ${endDate &&
        ParseToDate(endDate)}`
      }
      where={where}
      searchFields={reservationSearchFields}
    />
  );
};

// const PlanSearchFields = ["name"];
// const ratePlanFields = ["id", "name"];
export const RatePlanTemplateSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  notIncludedTypes,
  getOptionDisabled,
}) => {
  const where = {};
  if (notIncludedTypes) {
    where.type = { _nin: notIncludedTypes };
  }
  return (
    <CustomGqlSelector
      entity="rate_plan"
      placeholder="input.placeholder.selectRatePlan"
      fields={ratePlanFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      getOptionDisabled={getOptionDisabled}
      // getOptionLabel={({ content }) => content}
      getOptionLabel={({
        id,
        name,
        application_fee,
        membership_fee,
      }) =>
        name
          ? `${name} - ${i18n.t(
            "ratePlan.detail.appFee"
          )} ${application_fee} ${"₺"} ${" / "} ${i18n.t(
            "ratePlan.detail.membershipFee"
          )} ${membership_fee} ${"₺"} `
          : ""
      }
      searchFields={ratePlanSearchFields}
      where={where}
    />
  );
};
const gotrueUserSearchFields = ["email"];
const gotrueFields = ["id", "email"];
export const GotrueUserSelector = ({ onBlur, onChange, value }) => (
  <CustomGqlSelector
    entity="gotrue_user"
    placeholder="input.placeholder.selectRatePlan"
    fields={gotrueFields}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    getOptionLabel={({ email }) => `${email}`}
    searchFields={gotrueUserSearchFields}
  />
);

const accountMemberSearchFields = ["email"];
const accountMemberFields = [
  "account_id",
  "member_id",
  "member{id, name, surname}",
];
export const AccountMemberSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  accountId,
  searchFieldsAllowNullValues = false
}) => {
  const where = {};
  if (accountId) {
    where.account_id = { _eq: parseInt(accountId, 10) };
  }
  return (
    <CustomGqlSelector
      entity="account_member"
      placeholder="input.placeholder.accountMember"
      fields={accountMemberFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      getOptionLabel={({ member }) =>
        `${member.name} ${member.surname}`
      }
      where={where}
      searchFields={accountMemberSearchFields}
      searchFieldsAllowNullValues
    />
  );
};

export const VehicleModelSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="vehicle_model"
      placeholder="input.placeholder.vehicleModel"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
};

export const VehicleSegmentSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="vehicle_segment"
      placeholder="input.placeholder.vehicleSegment"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
};

export const TicketSourceSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="ticket_source"
      placeholder="input.placeholder.ticketSource"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
};

export const ValetSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="valet"
      placeholder="input.placeholder.valet"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      limit={40}
    />
  );
};

export const ZoneParkingSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="zone_parking"
      fields={["id,name,center"]}
      placeholder="input.placeholder.parking"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      limit={40}
    />
  );
};

export const ZoneWashingSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="zone_washing"
      fields={["id,name,center"]}
      placeholder="input.placeholder.washing"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      limit={40}
    />
  );
};

export const ZoneServiceSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="zone_service"
      fields={["id,name,center"]}
      placeholder="input.placeholder.service"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      limit={40}
    />
  );
};

export const ZoneGasStationSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="zone_gas_station"
      fields={["id,name,center"]}
      placeholder="input.placeholder.gasStation"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      limit={40}
    />
  );
};

export const OperationZoneSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="zone_operation"
      fields={["id,name"]}
      placeholder="input.placeholder.zone"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      limit={40}
    />
  );
};
export default CustomGqlSelector;

const driverListFields = ["driver_id", "driver_name"];
const driverListSearchFields = ["driver_name"];
export const DriverListSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  accountId,
  multiple,
}) => {
  const where = {};
  if (accountId) {
    // eslint-disable-next-line no-underscore-dangle
    where._or = [
      {
        owned_accounts: {
          id: { _eq: accountId },
        },
      },
      {
        joined_accounts: {
          account_id: { _eq: accountId },
        },
      },
    ];
  }

  // console.log("valllll ",value);

  let newVal = null;

  if (value) {
    if (value.id) {
      newVal = {
        driver_id: value.id,
        driver_name: value.name + " " + value.surname,
      };
    }
  }

  return (
    <CustomGqlSelector
      entity="driver_list"
      placeholder="input.placeholder.driver"
      fields={driverListFields}
      searchFields={driverListSearchFields}
      getOptionLabel={({ driver_id, driver_name }) =>
        `${driver_name} #${driver_id}`
      }
      onBlur={onBlur}
      onChange={onChange}
      value={newVal || value}
      disabled={disabled}
      where={where}
      multiple={multiple}
    />
  );
};

const salesRepresentativesFields = ["id", "name"];
export const SalesRepresentativesSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => (
  <CustomGqlSelector
    disableSearchInt
    entity="salesRepresentatives"
    placeholder="corporateSign.forms.step1.salesRepresentative"
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    disabled={disabled}
    limit={100}
    fields={salesRepresentativesFields}
  />
);

const gotrueUserManagerSearchFields = ["name", "surname"];
const gotrueUserManagerFields = ["id", "name", "surname"];
export const GotrueUserManagerSelector = ({
  onBlur,
  onChange,
  value,
}) => {
  const where = {
    role: { _eq: "manager" },
  };

  return (
    <CustomGqlSelector
      entity="gotrue_user"
      placeholder="input.placeholder.responsible"
      fields={gotrueUserManagerFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      getOptionLabel={({ name, surname }) => `${name} ${surname}`}
      searchFields={gotrueUserManagerSearchFields}
      where={where}
    />
  );
};

const vehicleMainStatusSearchFields = ["name"];
const vehicleMainStatusFields = ["id", "name"];
export const VehicleMainStatusSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="vehicle_main_status"
      placeholder="input.placeholder.vehicleMainStatus"
      fields={vehicleMainStatusFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      getOptionLabel={({ name }) =>
        `${i18n.t(`vehicleList.mainStatuses.${name.toLowerCase()}`)}`
      }
      disabled={disabled}
      searchFields={vehicleMainStatusSearchFields}
    />
  );
};

const vehicleStatusSearchFields = ["name"];
const vehicleStatusFields = ["id", "name"];
export const VehicleStatusSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  isServiceReservation,
  mainStatusID,
}) => {
  return (
    <CustomGqlSelector
      entity="vehicle_status"
      placeholder="input.placeholder.statusVehicle"
      fields={vehicleStatusFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      getOptionLabel={({ name }) =>
        `${i18n.t(`vehicleList.statuses.${name.toLowerCase()}`)}`
      }
      searchFields={vehicleStatusSearchFields}
      where={
        isServiceReservation
          ? mainStatusID
            ? {
              _and: [
                {
                  is_service_status: { _eq: isServiceReservation },
                },
                { vehicle_main_status_id: { _eq: mainStatusID } },
              ],
            }
            : {
              is_service_status: { _eq: isServiceReservation },
            }
          : mainStatusID
            ? {
              vehicle_main_status_id: { _eq: mainStatusID },
            }
            : {}
      }
    />
  );
};

const vehicleSubStatusSearchFields = ["name"];
const vehicleSubStatusFields = ["id", "name"];
export const VehicleSubStatusSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
  statusID,
  setOptionsLength,
}) => {
  return (
    <CustomGqlSelector
      entity="vehicle_sub_status"
      placeholder="input.placeholder.vehicleSubStatus"
      fields={vehicleSubStatusFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      getOptionLabel={({ name }) =>
        `${i18n.t(`vehicleList.subStatuses.${name.toLowerCase()}`)}`
      }
      searchFields={vehicleSubStatusSearchFields}
      where={
        statusID
          ? {
            vehicle_status_id: { _eq: statusID },
          }
          : {}
      }
      setOptionsLength={setOptionsLength}
    />
  );
};

const serviceLocationSearchFields = ["name"];
const serviceLocationFields = ["id", "name"];
export const ServiceLocationSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  return (
    <CustomGqlSelector
      entity="connected_car_service_location"
      placeholder="input.placeholder.serviceLocation"
      fields={serviceLocationFields}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      searchFields={serviceLocationSearchFields}
    />
  );
};

const damageSearchFields = ["comment"];
const damageIntSearchFields = ["id", "contact_no", "vehicle_id"];
const damageFields = [
  "id",
  "contact_no",
  "vehicle_id",
  "amount",
  "comment",
  "reservation_id",
  "created_at",
  "vehicle{plate}",
];
export const DamageSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {
  const where = { reservation_id: { _is_null: false }, contact_no: { _is_null: false } };
  return (
    <CustomGqlSelector
      entity="damage"
      placeholder="reservationDetails.modals.damage.contactNo"
      fields={damageFields}
      onBlur={onBlur}
      onChange={onChange}
      searchIntFields={damageIntSearchFields}
      disableSearchInt={false}
      value={value}
      disabled={disabled}
      getOptionLabel={o => {
        if (o.contact_no) {
          return o.contact_no + ",    " + o.amount + " TL,     " + o.vehicle.plate
        }
        return ""
      }
      }
      where={where}
      searchFields={damageSearchFields}
    />
  );
};

const colorSearchFields = ["name"];
const colorIntSearchFields = ["id"];
const colorFields = [
  "id",
  "name"
];
export const ColorSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {

  const { t } = useTranslation();

  return (
    <CustomGqlSelector
      entity="color"
      placeholder="basicInformation.color"
      fields={colorFields}
      onBlur={onBlur}
      onChange={onChange}
      searchIntFields={colorIntSearchFields}
      disableSearchInt={false}
      value={value}
      disabled={disabled}
      getOptionLabel={o => { return (o.id && o.name && typeof (o.name) === "string") ? t(`color.${o.name}`) : "-" }}
      searchFields={colorSearchFields}
    />
  );
};

const vehicleTypeSearchFields = ["name"];
const vehicleTypeIntSearchFields = ["id"];
const vehicleTypeFields = [
  "id",
  "name"
];
export const VehicleTypeSelector = ({
  onBlur,
  onChange,
  value,
  disabled,
}) => {

  const { t } = useTranslation();

  return (
    <CustomGqlSelector
      entity="vehicle_type"
      placeholder="input.placeholder.vehicleType"
      fields={vehicleTypeFields}
      onBlur={onBlur}
      onChange={onChange}
      searchIntFields={vehicleTypeIntSearchFields}
      disableSearchInt={false}
      value={value}
      disabled={disabled}
      getOptionLabel={o => o.name}
      searchFields={vehicleTypeSearchFields}
    />
  );
};