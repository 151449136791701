import React from "react";
import { Redirect } from "react-router-dom";
import { getUserRole } from "app/utils/functions";

// import dashboardRoutes from "../views/dashboard/DashboardRoutes";
import utilitiesRoutes from "../views/utilities/UtilitiesRoutes";
import sessionRoutes from "../views/sessions/SessionRoutes";

import materialRoutes from "../views/material-kit/MaterialRoutes";

import formsRoutes from "../views/forms/FormsRoutes";
import mapRoutes from "../views/map/MapRoutes";

import accountRoutes from "./AccountRoutes";
import affiliateRoutes from "./AffiliateRoutes";
import customerRoutes from "./CustomerRoutes";
import dashboardRoutes from "./DashboardRoutes";
import listRoutes from "./ListsRoutes";
import serviceRoutes from "./ServiceRoutes";
import driverRoutes from "./DriverRoutes";
import corporateRoutes from "./CorporateRoutes";
import creditRoutes from "./CreditRoutes";
import locationRoutes from "./LocationRoutes";
import vehicleRoutes from "./VehicleRoutes";
import ticketRoutes from "./TicketRoutes";
import rateRoutes from "./RateRoutes";
import billingRoutes from "./BillingRoutes";
import reservationRoutes from "./ReservationRoutes";
import pendingRoutes from "./PendingAppRoutes";
import expenseRoutes from "./ExpenseRoutes";
import findexRoutes from "./FindexRoutes";
import tollPassRoutes from "./TollPassRoutes";
import zoneRoutes from "./ZoneRoutes";
import hgbRoutes from "./HgbRoutes";
import notificationRoutes from "./NotificationRoutes";
import trafficPenaltyRoutes from "./TrafficPenaltyRoutes";
import taskRoutes from "./TaskRoutes";
import valetRoutes from "./ValetRoutes";
import notificationHandlerRoutes from "./RequestHandlerRoutes";
import rolesRoutes from "./RolesRoutes";
import salesRepresentativeRoutes from "./SalesRepresentativeRoutes";
import settingsRoutes from "./SettingsRoutes";
import reportRoutes from "./ReportRoutes";

const startPath = {
  manager: "/vehicles/list",
  account_holder: "/corporate/list",
  settings: "/settings/list"
}

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={startPath?.[getUserRole()] || "/vehicles/list"} />,
    permission: ["manager", "account_holder", "settings"]
  },
  {
    path: "/index.html",
    component: () => <Redirect to={startPath?.[getUserRole()] || "/vehicles/list"} />,
    permission: ["manager", "account_holder", "settings"]
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
    permission: ["manager", "account_holder", "settings"]
  },
];

const routes = [
  ...pendingRoutes,
  ...corporateRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...accountRoutes,
  ...driverRoutes,
  ...customerRoutes,
  ...listRoutes,
  ...serviceRoutes,
  ...affiliateRoutes,
  ...locationRoutes,
  ...vehicleRoutes,
  ...ticketRoutes,
  ...rateRoutes,
  ...billingRoutes,
  ...reservationRoutes,
  ...creditRoutes,
  ...expenseRoutes,
  ...findexRoutes,
  ...zoneRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...formsRoutes,
  ...mapRoutes,
  ...tollPassRoutes,
  ...trafficPenaltyRoutes,
  ...hgbRoutes,
  ...notificationRoutes,
  ...taskRoutes,
  ...valetRoutes,
  ...rolesRoutes,
  ...salesRepresentativeRoutes,
  ...settingsRoutes,
  ...reportRoutes,
  ...notificationHandlerRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
