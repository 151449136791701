import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    backgroundImage: "url(/assets/images/login-bg.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

const CenteredContainerImage = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      {children}
    </Grid>
  );
};

export default CenteredContainerImage;
