/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListBilling = MatxLoadable({
  loader: () => import("../views/billing"),
});

const billingRoutes = [
  // {
  //   path: "/billing/dashboard",
  //   component: ListBilling,
  //   permission: ["manager", "account_holder"]
  // },
  {
    path: "/billing/list/:id",
    component: ListBilling,
    permission: ["manager", "account_holder"]
  },
];

export default billingRoutes;
