import React from "react";
import { useTranslation } from "react-i18next";

import { IconButton, makeStyles } from "@material-ui/core";
import { AddCircle, MoreHoriz } from "@material-ui/icons";

import BasicChatBox from "app/ui/Molecule/BasicChatBox";
import TicketChatList from "app/ui/Organism/TicketChatList";

const useStyles = makeStyles(({ spacing, palette }) => ({
  iconButton: { padding: spacing(1), color: palette.common.white },
}));

const MainChatBox = ({
  open,
  user,
  width = 250,
  height = 300,
  onAddClick,
  onMoreClick,
  onHeaderClick,
  onItemClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <BasicChatBox
      open={open}
      title={t("chatBox.mainTitle")}
      width={width}
      height={height}
      onHeaderClick={onHeaderClick}
      headerActions={
        <>
          <IconButton
            onClick={onAddClick}
            className={classes.iconButton}
          >
            <AddCircle />
          </IconButton>

          <IconButton
            onClick={onMoreClick}
            className={classes.iconButton}
          >
            <MoreHoriz />
          </IconButton>
        </>
      }
    >
      {open && (
        <TicketChatList
          onTicketClick={onItemClick}
          department={user.department}
          open={open}
        />
      )}
    </BasicChatBox>
  );
};

export default MainChatBox;
