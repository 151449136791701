/* eslint-disable global-require,import/no-dynamic-require */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-dynamic-require */

import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import auth from "services/auth";
const error = require("../../../app/images/error.jpeg");

let imageFetched = false

const Image = ({
  alt,
  imgAddress,
  isLocal,
  height,
  width,
  style,
  resizeMode
}) => {

  const imageRef = useRef();

  const [src, setSrc] = useState("")

  useEffect(()=>{
    // console.log("ImageREF:", imageRef)

    getImage();
    
  }, [])

  const getToken = async () => {
    const user = auth.currentUser();
    if (user) {
      const token = await user.jwt(false).catch(console.error);
      return token;
    }
  
    return null;
  };

  const getImage = async () =>{
    if(!isLocal){

      // console.log("Fetching media....")
      const token = await getToken();

      axios.get(imgAddress, { headers: {'Authorization': `Bearer ${token}` }, responseType: 'arraybuffer'}).then((res) => {

        // let data = new Uint8Array(res.data);
        // let raw = String.fromCharCode.apply(null, data);
        // let base64 = btoa(raw);
        // let newSrc = "data:image;base64," + base64;

        const blob = new Blob([res.data], {
          type: 'image/jpeg',
        });

        // console.log("blob: ", blob, URL.createObjectURL(blob));
        let objectURL = URL.createObjectURL(blob)
        if (imageRef.current != null) {
          imageRef.current.src = objectURL;
        };
        if (imageRef.current != null && blob.size === 0) {
          imageRef.current.src = error
        }
        // setSrc(newSrc)
        // if(imageRef && imageRef.current){
        //   imageRef.current.img.src = src;
        // }

      })
    }
  }

  return (
    <img
      ref={imageRef}
      style={style}
      width={width}
      height={height}
      alt={alt}
      // src={isLocal ? require(imgAddress) : imgAddress}
      src={isLocal ? require(imgAddress) : src}
    />
  );
};
export default Image;
