/* eslint-disable  */
import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/Add";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import variables from "styles/_variables.scss";
import Icon from "../../Atoms/Icon";
import Button from "../../Atoms/Button";
// import "react-dropzone-uploader/dist/styles.css";
import config from "common/config";
import { deleteMediaURL, getToken, listMediaURL } from "app/utils/functions";
import { useSnackbar } from "notistack";

const StyledPaper = withStyles(() => ({
  root: {
    width: "100%",
    height: 140,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    backgroundColor: variables.zipcar_background,
    color: variables.zipcar_text_light,
    overflow: "hidden",
    "&>div": {
      border: "none",
      justifyContent: "center",
      "&>label": {
        flex: 1,
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  },
}))(Paper);

const WaitingIcon = withStyles(() => ({
  root: {
    color: variables.zipcar_text_light,
    fontSize: 12,
    fontWeight: 500,
    marginRight: "4px",
  },
}))(HourglassEmptyIcon);

const CheckIcon = withStyles(() => ({
  root: {
    color: variables.zipcar_primary,
    fontSize: 14,
    fontWeight: 500,
    marginRight: "4px",
  },
}))(CheckCircleIcon);

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const DropZone = forwardRef(
  (
    {
      bucket,
      filePath,
      setFilename,
      acceptTypes,
      multiple,
      width,
      height,
      buttonMode,
      isDriverOrIdentity,
      onChange,
      onSaved,
      keepUploadButton,
      avoidImmediateSave,
      isReplace
    },
    ref
  ) => {
    const { t } = useTranslation();
    const useStyles = makeStyles({
      root: {
        width: width - 32,
        height,
      },
      rootIcon: {
        color: variables.zipcar_text_light,
        fontSize: 18,
      },
      successIcon: {
        color: variables.zipcar_primary,
        fontSize: 18,
      },
      waitingIcon: {
        color: variables.zipcar_text_light,
        fontSize: 18,
      },
      dropzoneActive: {
        backgroundColor: "#FFAAFF",
        borderColor: "#FF1133",
      },
      isDragActive: {
        borderRadius: 8,
        borderStyle: "dashed",
        borderWidth: "2px",
        borderColor: variables.zipcar_secondary,
        background: `rgba(${hexToRgb(variables.zipcar_secondary).r},${
          hexToRgb(variables.zipcar_secondary).g
        },${hexToRgb(variables.zipcar_secondary).b},0.2)`,
      },
      fileList: {
        width: "90%",
        height: "100%",
        "&>p": {
          display: "flex",
          borderBottom: "solid 1px white",
          fontSize: "12px",
          fontWeight: 500,
          alignItems: "center",
          justifyContent: "flex-start",
          margin: "4px",
          padding: "4px 0px",
        },
        "&>ul": {
          fontSize: "10px",
          fontWeight: 500,
          margin: "0px 0px 0px 20px",
          padding: "0px",
          listStyle: "decimal-leading-zero",
        },
        "& li": {
          flex: 0,
          flexDirection: "row",
          lineHeight: "18px",
          "&>div": {
            display: "flex",
            overflow: "hidden",
            fontSize: "11px",
            fontWeight: 500,
            height: "23px",
            alignItems: "center",
            justifyContent: "flex-start",
          },
        },
      },
    });
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const [fileIsUploaded, setFileIsUploaded] = useState(false);
    // const [savedFiles, setSavedFiles] = useState([]);

    const onSave = async (file, folderName) => {
      // console.log("EVENT ID -4 :", folderName)
      if (filePath === undefined && setFilename === undefined && folderName === undefined) {
        console.error("You Need to set filePath or fileName!");
        return;
      }

      let fn = "";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucket", bucket);
      // formData.append("type", file.type);
      formData.append(
        "isDriverOrIdentity",
        isDriverOrIdentity || false
      );
      if (setFilename && setFilename.length > 0) {
        const fileName = file.name.split(".");
        if (isDriverOrIdentity) {
          formData.append(
            "filePath",
            `${filePath ? filePath + "/" : (folderName ? folderName + "/" : "")}${setFilename}`
          );
          fn = setFilename;
        } else {
          formData.append(
            "filePath",
            `${filePath ? filePath + "/" : (folderName ? folderName + "/" : "")}${setFilename}.${
              fileName[fileName.length - 1]
            }`
          );
          fn = setFilename + "." + fileName[fileName.length - 1];
        }
      } else {
        formData.append(
          "filePath",
          `${filePath ? filePath + "/" : (folderName ? folderName + "/" : "")}${file.name}`
        );
        fn = file.name;
      }
      // console.log("formData", formData.get("filePath"));
      const token = await getToken();
      if(isReplace){
        axios
        .get(listMediaURL(bucket, filePath), {headers: {'Authorization': `Bearer ${token}` }})
        .then(res => {
          // console.log(res);
          if(res.status === 200 && res.data?.success) {
            res.data.data?.forEach(item=>{
              axios.get(deleteMediaURL(bucket, item.name), {headers: {'Authorization': `Bearer ${token}` }})
              .then(resDel => {
                // console.log(resDel)
              })
              .catch(res => {
                console.warn(res);
              });
            })
          }
        })
        .catch(res => {
          console.warn(res);
        });
      }

      axios
      .post(`${config.mediaURI}/api/v1/saveMedia`, formData,{ headers: {'Authorization': `Bearer ${token}` }})
      .then(res => {
        if (res.status === 201) {
          setFileIsUploaded(true);
          onChangeZone();
          if (typeof onSaved === "function") {
            onSaved({
              path: formData.get("filePath"),
              file: fn,
            });
          }
          // setSavedFiles(d => d.concat({
          //   path: formData.get("filePath"),
          //   file: fn
          // }));
        }
        if(res?.data?.success){
          enqueueSnackbar(t("statusTypes.success"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("statusTypes.failed"), {
            variant: "error",
          });
        }
        
      })
      .catch(() => {
        enqueueSnackbar(t("statusTypes.failed"), {
          variant: "error",
        });
        setFileIsUploaded(false);
      });
    };

    const onDrop = useCallback(acceptedFiles => {
      // console.log("Accepted files:", acceptedFiles);
      // if (filePath || setFilename) {
        if (typeof onChange === "function") {
          onChange(acceptedFiles);
        }
        if(!avoidImmediateSave){
          acceptedFiles.forEach(file => {
            onSave(file);
          });
        } else {
          if (typeof onSaved === "function") {
            acceptedFiles.forEach(file => {
              onSaved(file);
            });
            
          }
        }
      // }
    }, []);

    // const onDropAccepted = useCallback(files => {
    //     console.log("onDropAccepted", files);
    //     // files.forEach((file) => {
    //     //   onSave(file)
    //     // })
    //   }, []);

    // const onDropRejected = useCallback(files => {
    //     console.log("onDropRejected", files);
    // },[]);

    const {
      getRootProps,
      getInputProps,
      open,
      isDragActive,
      acceptedFiles,
    } = useDropzone({
      accept: acceptTypes,
      onDrop,
      // onDropAccepted:(files)=>{console.log("onDropAccepted",files);},
      // onDropRejected:(files)=>{console.log("onDropRejected",files);},
      // getFilesFromEvent:(event)=>{console.log("event:", event);},
      noClick: true,
      multiple: multiple !== undefined,
      noDrag: buttonMode !== undefined,
    });

    const onSubmit = () => {
      // console.log("sending...", acceptedFiles);
      acceptedFiles.forEach(file => {
        onSave(file);
      });
    };

    useImperativeHandle(ref, () => ({
      onSave(fp) {
        filePath = fp;
        onSubmit();
      },
      getFiles() {
        return acceptedFiles;
      },

      uploadFiles(id, files){
        // console.log("EVENT ID -3 :", id)
        files.forEach(file => {
          onSave(file, id);
        });
      },      
      // getSavedFiles() {
      //   return savedFiles;
      // }
    }));

    const onChangeZone = useCallback(acceptedFiles => {
      if (typeof onChange === "function") {
        // onChange(acceptedFiles);
      }
    }, []);

    const files = acceptedFiles.map(file => (
      <li key={file.path}>
        <div>
          {file.path}{" "}
          {fileIsUploaded ? (
            <CheckIcon className={classes.checkIcon} />
          ) : (
            <WaitingIcon className={classes.waitingIcon} />
          )}
        </div>
      </li>
    ));

    if (buttonMode === undefined) {
      return (
        <StyledPaper elevation={0} classes={{ root: classes.root }}>
          <div
            style={{ flex: 1, display: "flex", outline: "none" }}
            {...getRootProps()}
          >
            <label
              className={isDragActive ? classes.isDragActive : null}
            >
              {(!fileIsUploaded && acceptedFiles.length === 0 || keepUploadButton) && (
                <ExpandMoreIcon
                  classes={{ root: classes.rootIcon }}
                />
              )}
              {(!fileIsUploaded &&
                acceptedFiles.length === 0 || keepUploadButton ) &&
                (isDragActive ? (
                  <span>
                    {t("locationDetails.dropFilesThisArea")}
                  </span>
                ) : (
                  <span>{t("locationDetails.importFiles")}</span>
                ))}
              {(files.length > 0 && !keepUploadButton) && (
                <div className={classes.fileList}>
                  <p>
                    <InsertDriveFile size={5} />
                    Selected Files
                  </p>
                  <ul>{files}</ul>
                </div>
              )}
              {/* {fileIsUploaded && <div style={{width:'100%',height:'100%',background:variables.zipcar_primary,color:variables.zipcar_white}}>
                ${t('locationDetails.fileUploaded')}
              </div>} */}
              <input {...getInputProps()} />
            </label>
          </div>
        </StyledPaper>
      );
    }
    let iconName = "add";
    let buttonText = isDriverOrIdentity
      ? t("photo.changePhoto")
      : t("photo.addPhoto");
    if (files.length > 0 && !fileIsUploaded) {
      iconName = "check_circle";
      buttonText = t("photo.fileSelected");
    } else if (fileIsUploaded) {
      iconName = "north_west";
      buttonText = t("photo.fileUploaded");
    }

    return (
      <div style={{ flex: 1, display: "flex", outline: "none" }}>
        <Button
          onClick={() => open()}
          icon={
            <Icon
              name={iconName}
              size="medium"
              color={
                fileIsUploaded
                  ? variables.zipcar_white
                  : variables.zipcar_text_normal
              }
            />
          }
          iconPosition="left"
          color={
            fileIsUploaded
              ? variables.zipcar_primary
              : variables.zipcar_background
          }
          textColor={
            fileIsUploaded
              ? variables.zipcar_white
              : variables.zipcar_text_normal
          }
          fontSize={14}
          iconSize={50}
          height={36}
        >
          {buttonText}
        </Button>

        <input {...getInputProps()} />
      </div>
    );
  }
);

export default DropZone;
