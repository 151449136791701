/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import CustomSimpleSelector from "app/ui/Organism/CustomSimpleSelector";
import DropZone from "app/ui/Molecule/DropZone";
import AutoCompleteTaxOffices from "app/ui/Molecule/AutoCompleteTaxOffices";
import { Controller, useForm } from "react-hook-form";
import Text from "app/ui/Atoms/Text";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidTaxNumber } from "app/utils/functions";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";

import variables from "styles/_variables.scss";
import FormItem from "app/ui/Atoms/FormItem";
import {
  TextField,
  FormControl,
  Button,
  Box,
  // IconButton,
  // OutlinedInput,
  // InputLabel,
  // InputAdornment,
} from "@material-ui/core";
// import { Delete } from "@material-ui/icons";
import i18n from "app/i18n";
import AutoCompleteSalesRepresentatives from "app/ui/Molecule/AutoCompleteSalesRepresentatives";
import { SalesRepresentativesSelector } from "app/ui/Organism/CustomGqlSelector";

const companyType = [
  {
    label: i18n.t(
      "corporateSign.forms.step1.companyTypes.soleProprietorship"
    ),
    value: 1,
  },
  {
    label: i18n.t(
      "corporateSign.forms.step1.companyTypes.limitedCompany"
    ),
    value: 2,
  },
  {
    label: i18n.t(
      "corporateSign.forms.step1.companyTypes.incorporatedCompany"
    ),
    value: 3,
  },
  {
    label: i18n.t(
      "corporateSign.forms.step1.companyTypes.ordinaryPartnership"
    ),
    value: 4,
  },
];

const numberOfEmployee = [
  {
    label: `1 - 10 ${i18n.t("corporateSign.person")}`,
    value: 10,
  },
  {
    label: `11 - 50 ${i18n.t("corporateSign.person")}`,
    value: 50,
  },
  {
    label: `51 - 200 ${i18n.t("corporateSign.person")}`,
    value: 200,
  },
  {
    label: `201 - 500 ${i18n.t("corporateSign.person")}`,
    value: 500,
  },
  {
    label: `501 - 1000 ${i18n.t("corporateSign.person")}`,
    value: 1000,
  },
  {
    label: `1001 - 5000 ${i18n.t("corporateSign.person")}`,
    value: 5000,
  },
  {
    label: `5001 - 10000 ${i18n.t("corporateSign.person")}`,
    value: 10000,
  },
];

const generatePotentitalDriver = (min, max) => {
  const result = [];
  const numRate = Math.ceil((max - min) / 6);
  const person = i18n.t("corporateSign.person");

  for (let i = min; i <= max; i += numRate) {
    if (max - i >= numRate) {
      const val = `${i === min ? i : i - 1} - ${i + (numRate - 1)}`;
      result.push({
        label: `${val} ${person}`,
        value: val,
      });
      i += 1;
    } else {
      const val = `${i - 1} - ${max}`;
      result.push({
        label: `${val}  ${person}`,
        value: val,
      });
    }
  }

  return result;
};

const potentialDriverSets = {
  10: generatePotentitalDriver(1, 10),
  50: generatePotentitalDriver(11, 50),
  200: generatePotentitalDriver(51, 200),
  500: generatePotentitalDriver(201, 500),
  1000: generatePotentitalDriver(501, 1000),
  5000: generatePotentitalDriver(1001, 5000),
  10000: generatePotentitalDriver(5001, 10000),
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
  },
  form: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: "left",
  },
  elements: {
    marginBottom: "1rem",
  },
  passwordInput: {
    height: 54,
  },
  stepper: {
    padding: 48,
    [theme.breakpoints.down("sm")]: {
      padding: "48px 0px",
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
  },
  resetContainer: {
    display: "flex",
    padding: "0px 48px",
    textAlign: "left",
  },
  btn: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: variables.zipcar_primary,
    color: variables.zipcar_white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 6px -6px",
    width: 120,
    height: 40,
    padding: "14px 28px",
    borderRadius: "25px",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_primary,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 35px",
    },
  },
  requiredText: {
    color: variables.zipcar_salmon,
    textAlign: "center",
  },
  dropzoneFiles: {
    border: "solid 1px #ddd",
    minHeight: "64px",
    borderRadius: 8,
    marginTop: 8,
    padding: 8,
    fontSize: 11,
  },
}));

function getSteps() {
  return [
    `${i18n.t("corporateSign.forms.step1.tabs.corporateInfo")}`,
    `${i18n.t("corporateSign.forms.step1.tabs.addDoc")}`,
    `${i18n.t("corporateSign.forms.step1.tabs.managerInfo")}`,
  ];
}

const schema = yup.object().shape({
  companyName: yup.string().required(i18n.t("validation.required")),
  taxNumber: yup
    .string()
    .min(10, i18n.t("validation.minLength"))
    .max(10, i18n.t("validation.maxLength"))
    .test("Digits only", i18n.t("validation.digitsOnly"), v =>
      /^\d+$/.test(v)
    )
    .test("VKN Validity",i18n.t("validation.taxIDNotValid"), value => 
      isValidTaxNumber(value)
    )
    .required(i18n.t("validation.required")),
  taxOffice: yup
    .string()
    .nullable(i18n.t("validation.required"))
    .required(i18n.t("validation.required")),
  salesRepresentative: yup
    .string()
    .nullable(i18n.t("validation.required")),
  billingAddress: yup
    .string()
    .required(i18n.t("validation.required")),
  companyType: yup.string().required(i18n.t("validation.required")),
  numberOfEmployee: yup
    .string()
    .required(i18n.t("validation.required")),
  potentialDrivers: yup
    .string()
    .required(i18n.t("validation.required")),
});

const form11Fields = [
  "companyName",
  "taxNumber",
  "taxOffice",
  "salesRepresentative",
  "billingAddress",
  "billingAddress2",
  "companyType",
  "numberOfEmployee",
  "potentialDrivers",
];

const Step11Form = forwardRef(
  ({ allData, onSubmit, onChange }, ref) => {
    const submit = useRef();
    const classes = useStyles();
    const [potentialDrivers, setPotentialDrivers] = useState([]);
    const {
      handleSubmit,
      register,
      errors,
      control,
      watch,
      setValue,
    } = useForm({
      mode: "all",
      resolver: yupResolver(schema),
    });

    const watchFields = watch(form11Fields);
    useEffect(() => {
      if (onChange) {
        onChange({ ...watchFields });
      }

      if (watchFields.numberOfEmployee !== undefined) {
        if (
          potentialDriverSets[watchFields.numberOfEmployee] !==
          undefined
        ) {
          setPotentialDrivers(
            potentialDriverSets[watchFields.numberOfEmployee]
          );
        }
      }
    }, [
      watchFields.companyName,
      watchFields.taxNumber,
      watchFields.taxOffice,
      watchFields.salesRepresentative,
      watchFields.billingAddress,
      watchFields.billingAddress2,
      watchFields.companyType,
      watchFields.numberOfEmployee,
      watchFields.potentialDrivers,
    ]);

    const triggerValidation = async () => {
      submit.current.click();
    };

    useImperativeHandle(ref, () => ({
      onCompleteForm() {
        onChange({ ...watchFields });
      },
      validateForm() {
        triggerValidation();
      },
    }));

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        {/* <div
          style={{
            width: "390px",
            border: "solid 1px red",
            wordWrap: "break-word",
          }}
        >
          {JSON.stringify(watchFields)}
        </div> */}

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.salesRepresentative")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="salesRepresentative"
              control={control}
              defaultValue={allData.step1?.step1?.salesRepresentative}
              onChange={input => {
                setValue("salesRepresentative", input);
                onChange({
                  ...watchFields,
                  salesRepresentative: input,
                });
              }}
              error={!!errors.salesRepresentative}
              // eslint-disable-next-line no-shadow
              render={({ onChange, value }) => (
                <>
                  <SalesRepresentativesSelector 
                    onChange={onChange}
                    value={value}
                  />
                  {/* <AutoCompleteSalesRepresentatives
                    onSelect={onChange}
                    value={value}
                    setInputValue={onChange}
                    helperText={errors.salesRepresentative?.message}
                    error={!!errors.salesRepresentative}
                  /> */}
                </>
              )}
            />
          </FormControl>
        </Box>

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.companyName")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "companyName",
              }}
              helperText={errors.companyName?.message}
              error={!!errors.companyName}
              placeholder={i18n.t(
                "corporateSign.placeholders.companyName"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step1?.companyName}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.companyTaxNumber")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "taxNumber",
              }}
              helperText={errors.taxNumber?.message}
              error={!!errors.taxNumber}
              placeholder={i18n.t(
                "corporateSign.placeholders.taxNumber"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step1?.taxNumber}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.companyTaxOffice")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="taxOffice"
              control={control}
              defaultValue={allData.step1?.step1?.taxOffice}
              onChange={input => {
                setValue("taxOffice", input);
                onChange({ ...watchFields, taxOffice: input });
              }}
              error={!!errors.companyType}
              // eslint-disable-next-line no-shadow
              render={({ onChange, value }) => (
                <>
                  <AutoCompleteTaxOffices
                    onSelect={onChange}
                    value={value}
                    setInputValue={onChange}
                    helperText={errors.taxOffice?.message}
                    error={!!errors.taxOffice}
                  />
                </>
              )}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.billingAddress")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "billingAddress",
              }}
              helperText={errors.billingAddress?.message}
              error={!!errors.billingAddress}
              placeholder={i18n.t(
                "corporateSign.placeholders.billingAddress"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step1?.billingAddress}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.billingAddress2")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "billingAddress2",
              }}
              helperText={errors.billingAddress2?.message}
              error={!!errors.billingAddress2}
              placeholder={i18n.t(
                "corporateSign.placeholders.billingAddress2"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step1?.billingAddress2}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.companyType")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="companyType"
              control={control}
              defaultValue={allData.step1?.step1?.companyType}
              onChange={input => {
                setValue("companyType", input);
                onChange({ ...watchFields, companyType: input });
              }}
              error={!!errors.companyType}
              // eslint-disable-next-line no-shadow
              render={({ name, onBlur, onChange, value }) => (
                <>
                  <CustomSimpleSelector
                    ref={register}
                    name={name}
                    options={companyType}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    placeholder="corporateSign.forms.step1.companyType"
                  />
                  <FormHelperText
                    error
                    disabled={!!errors.companyType}
                    style={{ marginLeft: "14px", marginTop: "8px" }}
                  >
                    {errors.companyType?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.numberOfEmployee")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="numberOfEmployee"
              control={control}
              defaultValue={allData.step1?.step1?.numberOfEmployee}
              error={!!errors.numberOfEmployee}
              // eslint-disable-next-line no-shadow
              render={({ name, onBlur, onChange, value }) => (
                <>
                  <CustomSimpleSelector
                    ref={register}
                    name={name}
                    options={numberOfEmployee}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    placeholder="corporateSign.forms.step1.numberOfEmployee"
                  />
                  <FormHelperText
                    error
                    disabled={!!errors.numberOfEmployee}
                    style={{ marginLeft: "14px", marginTop: "8px" }}
                  >
                    {errors.numberOfEmployee?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        </Box>
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.potentialDrivers")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="potentialDrivers"
              control={control}
              defaultValue={allData.step1?.step1?.potentialDrivers}
              onChange={input => {
                setValue("potentialDrivers", input);
                onChange({ ...watchFields, potentialDrivers: input });
              }}
              error={!!errors.potentialDrivers}
              // eslint-disable-next-line no-shadow
              render={({ name, onBlur, onChange, value }) => (
                <>
                  <CustomSimpleSelector
                    ref={register}
                    name={name}
                    options={potentialDrivers}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    placeholder="corporateSign.forms.step1.potentialDrivers"
                  />
                  <FormHelperText
                    error
                    disabled={!!errors.potentialDrivers}
                    style={{ marginLeft: "14px", marginTop: "8px" }}
                  >
                    {errors.potentialDrivers?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        </Box>
        <input
          type="submit"
          ref={submit}
          style={{ display: "none" }}
        />
      </form>
    );
  }
);
const Step12Form = forwardRef(
  ({ allData, onSubmit, onChange }, ref) => {
    const classes = useStyles();
    const taxPlate = useRef();
    // const establishment = useRef();
    const lastEstablishment = useRef();
    const signature = useRef();
    const operate = useRef();
    const [taxPlateItems, setTaxPlateItems] = useState([]);
    const [lastEstablishmentItems, setLastEstablishment] = useState(
      []
    );
    const [signatureItems, setSignatureItems] = useState([]);
    const [operateItems, setOperateItems] = useState([]);

    const [errors, setErrors] = useState({});

    useEffect(() => {
      setErrors(errors);

      if (
        allData.step1?.step2?.taxPlate &&
        allData.step1?.step2?.taxPlate.length
      ) {
        setTaxPlateItems(allData.step1.step2.taxPlate);
      }

      if (
        allData.step1?.step2?.lastEstablishment &&
        allData.step1?.step2?.lastEstablishment.length
      ) {
        setLastEstablishment(allData.step1.step2.lastEstablishment);
      }

      if (
        allData.step1?.step2?.signature &&
        allData.step1?.step2?.signature.length
      ) {
        setSignatureItems(allData.step1.step2.signature);
      }

      if (
        allData.step1?.step2?.operate &&
        allData.step1?.step2?.operate.length
      ) {
        setOperateItems(allData.step1.step2.operate);
      }

      if (
        taxPlateItems.length > 0 ||
        lastEstablishmentItems.length > 0 ||
        signature.length > 0 ||
        operate.length > 0
      ) {
        const changes = {};
        if (taxPlateItems && taxPlateItems.length > 0) {
          changes.taxPlate = taxPlateItems;
        }

        if (
          lastEstablishmentItems &&
          lastEstablishmentItems.length > 0
        ) {
          changes.lastEstablishment = lastEstablishmentItems;
        }

        if (signatureItems && signatureItems.length > 0) {
          changes.signature = signatureItems;
        }

        if (operateItems && operateItems.length > 0) {
          changes.operate = operateItems;
        }

        onChange(changes);
      }

      // if (
      //   allData.step1.step2.taxPlate &&
      //   allData.step1.step2.taxPlate.length
      // ) {
      //   setTaxPlateItems(allData.step1.step2.taxPlate);
      // }
    }, [
      errors,
      allData,
      taxPlateItems,
      lastEstablishmentItems,
      signatureItems,
      operateItems,
    ]);

    const handleSubmit = () => {
      // onSubmit();
      // return
      const checkErrors = {
        taxPlate: taxPlate.current && taxPlateItems.length === 0,
        lastEstablishment:
          lastEstablishment.current &&
          lastEstablishmentItems.length === 0,
        signature: signature.current && signatureItems.length === 0,
        operate:
          allData.step1?.step1?.companyType !== 1 &&
          operate.current &&
          operateItems.length === 0,
      };
      setErrors({
        ...errors,
        taxPlate: checkErrors.taxPlate,
        // establishment: checkErrors.establishment,
        lastEstablishment: checkErrors.lastEstablishment,
        signature: checkErrors.signature,
        operate: checkErrors.operate,
      });

      let isValid = true;
      Object.keys(checkErrors).forEach(z => {
        if (checkErrors[z] === true) {
          isValid = false;
        }
      });

      if (isValid) {
        onChange({
          taxPlate: taxPlateItems,
          lastEstablishment: lastEstablishmentItems,
          signature: signatureItems,
          operate: operateItems,
        });

        onSubmit();
      }
    };

    useImperativeHandle(ref, () => ({
      validateForm() {
        // onSubmit();
        handleSubmit();
      },
      onCompleteForm() {
        onChange({
          taxPlate: taxPlateItems,
          lastEstablishment: lastEstablishmentItems,
          signature: signatureItems,
          operate: operateItems,
        });
      },
    }));
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <Box>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.taxPlate")}
          </Text>
          <FormItem>
            <DropZone
              ref={taxPlate}
              bucket="account"
              filePath={`applications/${allData?.tempId}/taxPlate`}
              acceptTypes={["Image/*", ".pdf"]}
              onSaved={d =>
                setTaxPlateItems(x =>
                  x.concat({
                    path: d.path,
                    file: d.file,
                  })
                )
              }
              multiple
              width="100%"
              height={64}
            />
            <div className={classes.dropzoneFiles}>
              {taxPlateItems &&
                taxPlateItems.map((d, i) => {
                  return (
                    <div>
                      {`${i + 1}. ${d.file}`}
                      {/* {" >>> "}
                      <a href={d.file}>
                        <Delete style={{ fontSize: 12 }} />
                        Sil
                      </a> */}
                    </div>
                  );
                })}
              {/* {taxPlate.current &&
                taxPlate.current.getSavedFiles().map((x, i) => (
                  <div>
                    {i + 1}. {x.path}
                  </div>
                ))} */}
            </div>
            {errors.taxPlate && (
              <Text className={classes.requiredText}>
                {i18n.t("corporateSign.errors.thisFileRequired")}
              </Text>
            )}
          </FormItem>
        </Box>
        {/* {allData.step1?.step1?.companyType !== 1 && (
          <Box>
            <Text weight="bold">
              {i18n.t("corporateSign.forms.step1.establishment")}
            </Text>
            <FormItem>
              <DropZone
                ref={establishment}
                bucket="account"
                setFilename="establishment"
                filePath={`applications/${allData?.tempId}`}
                width="100%"
                height={64}
              />
              {errors.establishment && (
                <Text className={classes.requiredText}>
                  {i18n.t("corporateSign.errors.thisFileRequired")}
                </Text>
              )}
            </FormItem>
          </Box>
        )} */}
        {allData.step1?.step1?.companyType !== 1 &&
          allData.step1?.step1?.companyType !== 4 && (
            <Box>
              <Text weight="bold">
                {i18n.t(
                  "corporateSign.forms.step1.lastEstablishment"
                )}
              </Text>
              <FormItem>
                <DropZone
                  ref={lastEstablishment}
                  bucket="account"
                  // setFilename="lastEstablishment"
                  filePath={`applications/${allData?.tempId}/lastEstablishment`}
                  acceptTypes={["Image/*", ".pdf"]}
                  onSaved={d =>
                    setLastEstablishment(x =>
                      x.concat({
                        path: d.path,
                        file: d.file,
                      })
                    )
                  }
                  multiple
                  width="100%"
                  height={64}
                />
                {errors.lastEstablishment && (
                  <Text className={classes.requiredText}>
                    {i18n.t("corporateSign.errors.thisFileRequired")}
                  </Text>
                )}
                <div className={classes.dropzoneFiles}>
                  {lastEstablishmentItems &&
                    lastEstablishmentItems.map((d, i) => {
                      return (
                        <div>
                          {`${i + 1}. ${d.file}`}
                          {/* {" >>> "}
                          <a href={d.file}>
                            <Delete style={{ fontSize: 12 }} />
                            Sil
                          </a> */}
                        </div>
                      );
                    })}
                  {/* {taxPlate.current &&
                    taxPlate.current.getSavedFiles().map((x, i) => (
                      <div>
                        {i + 1}. {x.path}
                      </div>
                    ))} */}
                </div>
              </FormItem>
            </Box>
          )}
        <Box>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.signature")}
          </Text>
          <FormItem>
            <DropZone
              ref={signature}
              bucket="account"
              filePath={`applications/${allData?.tempId}/signature`}
              acceptTypes={["Image/*", ".pdf"]}
              onSaved={d =>
                setSignatureItems(x =>
                  x.concat({
                    path: d.path,
                    file: d.file,
                  })
                )
              }
              multiple
              width="100%"
              height={64}
            />
            {errors.signature && (
              <Text className={classes.requiredText}>
                {i18n.t("corporateSign.errors.thisFileRequired")}
              </Text>
            )}
            <div className={classes.dropzoneFiles}>
              {signatureItems &&
                signatureItems.map((d, i) => {
                  return (
                    <div>
                      {`${i + 1}. ${d.file}`}
                      {/* {" >>> "}
                      <a href={d.file}>
                        <Delete style={{ fontSize: 12 }} />
                        Sil
                      </a> */}
                    </div>
                  );
                })}
              {/* {taxPlate.current &&
                taxPlate.current.getSavedFiles().map((x, i) => (
                  <div>
                    {i + 1}. {x.path}
                  </div>
                ))} */}
            </div>
          </FormItem>
        </Box>
        <Box>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.operate")}
          </Text>
          <FormItem>
            <DropZone
              ref={operate}
              bucket="account"
              filePath={`applications/${allData?.tempId}/operate`}
              acceptTypes={["Image/*", ".pdf"]}
              onSaved={d =>
                setOperateItems(x =>
                  x.concat({
                    path: d.path,
                    file: d.file,
                  })
                )
              }
              multiple
              width="100%"
              height={64}
            />
            {allData.step1?.step1?.companyType !== 1 &&
              errors.operate && (
                <Text className={classes.requiredText}>
                  {i18n.t("corporateSign.errors.thisFileRequired")}
                </Text>
              )}
            <div className={classes.dropzoneFiles}>
              {operateItems &&
                operateItems.map((d, i) => {
                  return (
                    <div>
                      {`${i + 1}. ${d.file}`}
                      {/* {" >>> "}
                      <a href={d.file}>
                        <Delete style={{ fontSize: 12 }} />
                        Sil
                      </a> */}
                    </div>
                  );
                })}
              {/* {taxPlate.current &&
                taxPlate.current.getSavedFiles().map((x, i) => (
                  <div>
                    {i + 1}. {x.path}
                  </div>
                ))} */}
            </div>
          </FormItem>
        </Box>
      </form>
    );
  }
);

const form13Fields = [
  "holderName",
  "holderSurname",
  "holderPhone",
  "holderEmail",
  "holderPassword",
  "holderRePassword",
];

const accHolderSchema = yup.object().shape({
  holderName: yup.string().required(i18n.t("validation.required")),
  holderSurname: yup.string().required(i18n.t("validation.required")),
  holderPhone: yup
    .string()
    .min(17, i18n.t("validation.required"))
    .required(i18n.t("validation.required")),
  holderEmail: yup
    .string()
    .email(i18n.t("validation.wrongEmailFormat"))
    .required(i18n.t("validation.required")),
  holderPassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
      i18n.t("validation.passwordStrength")
    )
    .min(8, i18n.t("validation.minLength"))
    .max(32, i18n.t("validation.maxLength"))
    .required(i18n.t("validation.required")),
  holderRePassword: yup
    .string()
    .min(8, i18n.t("validation.minLength"))
    .max(32, i18n.t("validation.maxLength"))
    .oneOf(
      [yup.ref("holderPassword"), null],
      i18n.t("validation.passwordsMustMatch")
    )
    .required(i18n.t("validation.required")),
});

const Step13Form = forwardRef(
  ({ allData, onSubmit, onChange }, ref) => {
    // const Step13Form = ({ allData, onSubmit, onChange }) => {
    const classes = useStyles();
    const submit = useRef();
    const {
      handleSubmit,
      register,
      errors,
      watch,
      // setValue,
      // setError,
      control,
    } = useForm({
      mode: "onBlur",
      resolver: yupResolver(accHolderSchema),
    });

    const watchFields = watch(form13Fields);
    useEffect(() => {
      if (onChange) {
        onChange({ ...watchFields });
      }
    }, [
      watchFields.holderName,
      watchFields.holderSurname,
      watchFields.holderPhone,
      watchFields.holderEmail,
      watchFields.holderPassword,
      watchFields.holderRePassword,
    ]);

    useImperativeHandle(ref, () => ({
      onCompleteForm() {
        onChange({ ...watchFields });
      },
      validateForm() {
        submit.current.click();
      },
    }));

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.holderName")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "holderName",
              }}
              helperText={errors.holderName?.message}
              error={!!errors.holderName}
              placeholder={i18n.t(
                "corporateSign.placeholders.holderName"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step3?.holderName}
            />
          </FormControl>
        </Box>

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.holderSurname")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "holderSurname",
              }}
              helperText={errors.holderSurname?.message}
              error={!!errors.holderSurname}
              placeholder={i18n.t(
                "corporateSign.placeholders.holderSurname"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step3?.holderSurname}
            />
          </FormControl>
        </Box>

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.holderPhone")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <Controller
              name="holderPhone"
              control={control}
              defaultValue={allData.step1?.step3?.holderPhone}
              rules={{
                required: true,
                min: 6,
              }}
              error={!!errors.holderPhone}
              // eslint-disable-next-line no-shadow
              render={({ name, onBlur, onChange, value }) => (
                <>
                  <MuiPhoneNumber
                    name={name}
                    value={value}
                    defaultCountry="tr"
                    variant="outlined"
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!errors.holderPhone}
                  />
                  <FormHelperText
                    error
                    disabled={!!errors.holderPhone}
                  >
                    {errors.holderPhone?.message}
                  </FormHelperText>
                </>
              )}
            />
            {/* <TextField
              inputProps={{
                ref: register,
                name: "holderPhone",
              }}
              helperText={errors.holderPhone?.message}
              error={!!errors.holderPhone}
              placeholder={i18n.t(
                "corporateSign.placeholders.holderPhone"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step3?.holderPhone}
              // onChange={val => {
              //   setValue("holderPhone", formatPhoneNumber(val));
              // }}
            /> */}
          </FormControl>
        </Box>

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.holderMail")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              inputProps={{
                ref: register,
                name: "holderEmail",
              }}
              helperText={errors.holderEmail?.message}
              error={!!errors.holderEmail}
              placeholder={i18n.t(
                "corporateSign.placeholders.holderMail"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step3?.holderEmail}
              autoComplete="off"
            />
          </FormControl>
        </Box>

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.holderPassword")}
          </Text>
          <FormControl margin="dense" fullWidth variant="outlined">
            <TextField
              inputProps={{
                ref: register,
                name: "holderPassword",
              }}
              type="password"
              helperText={errors.holderPassword?.message}
              error={!!errors.holderPassword}
              placeholder={i18n.t(
                "corporateSign.placeholders.holderPassword"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step3?.holderPassword}
            />
          </FormControl>
        </Box>

        <Box className={classes.elements}>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step1.holderRepassword")}
          </Text>
          <FormControl margin="dense" fullWidth variant="outlined">
            <TextField
              inputProps={{
                ref: register,
                name: "holderRePassword",
              }}
              type="password"
              helperText={errors.holderRePassword?.message}
              error={!!errors.holderRePassword}
              placeholder={i18n.t(
                "corporateSign.placeholders.holderPasswordKind"
              )}
              variant="outlined"
              defaultValue={allData.step1?.step3?.holderRePassword}
            />
          </FormControl>
        </Box>

        <input
          type="submit"
          ref={submit}
          style={{ display: "none" }}
        />
      </form>
    );
  }
);

const Step11Content = forwardRef(
  ({ allData, onChange, onSubmit }, ref) => {
    return (
      <div>
        <Step11Form
          ref={ref}
          allData={allData}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);

const Step12Content = forwardRef(
  ({ allData, onChange, onSubmit }, ref) => {
    return (
      <div>
        <Step12Form
          ref={ref}
          allData={allData}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);

const Step13Content = forwardRef(
  ({ allData, onChange, onSubmit }, ref) => {
    // export const Step13Content = ({ allData, onChange, onSubmit }) => {
    return (
      <div>
        <Step13Form
          ref={ref}
          allData={allData}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);

function getStepContent(
  step,
  allData,
  setStep1Data,
  currentStep,
  onSubmit
) {
  switch (step) {
    case 0:
      return (
        <div>
          <Step11Content
            ref={el => {
              currentStep.current[step] = el;
            }}
            allData={allData}
            onSubmit={onSubmit}
            onChange={e => {
              setStep1Data({ ...allData.step1, step1: e });
            }}
          />
        </div>
      );
    case 1:
      return (
        <div>
          <Step12Content
            ref={el => {
              currentStep.current[step] = el;
            }}
            allData={allData}
            onSubmit={onSubmit}
            onChange={e => {
              setStep1Data({ ...allData.step1, step2: e });
            }}
          />
        </div>
      );
    case 2:
      return (
        <div>
          <Step13Content
            ref={el => {
              currentStep.current[step] = el;
            }}
            allData={allData}
            onSubmit={onSubmit}
            onChange={e => {
              setStep1Data({ ...allData.step1, step3: e });
            }}
          />
        </div>
      );
    default:
      return "Unknown step";
  }
}

const Step1 = forwardRef(
  ({ allData, setAllData, setFinishSteps }, ref) => {
    const classes = useStyles();
    const steps = getSteps();
    const currentStep = useRef([]);
    const [activeStep, setActiveStep] = useState(0);
    const [step1Data, setStep1Data] = useState();

    const onSubmit = () => {
      setAllData(step1Data);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleNext = () => {
      if (currentStep.current) {
        currentStep.current[activeStep].validateForm();
      }
    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    useImperativeHandle(ref, () => ({
      onCompleteForm() {
        if (currentStep.current) {
          currentStep.current.onCompleteForm();
        }
        setAllData(step1Data);
      },
    }));

    useEffect(() => {
      if (activeStep > 2) {
        setFinishSteps(2);
      }
    }, [activeStep]);

    return (
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={classes.stepper}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel className={classes.textAlignLeft}>
                <Text size={18} weight="bold">
                  {label}
                </Text>
              </StepLabel>
              <StepContent>
                <Box>
                  {getStepContent(
                    index,
                    allData,
                    setStep1Data,
                    currentStep,
                    onSubmit
                  )}
                </Box>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      {i18n.t("common.back")}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleNext()}
                      className={classes.btn}
                    >
                      {activeStep === steps.length - 1
                        ? `${i18n.t("common.finish")}`
                        : `${i18n.t("common.forward")}`}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Box
            square
            elevation={0}
            className={classes.resetContainer}
          >
            <Text size={16} weight="bold">
              {i18n.t("corporateSign.steps.contactInfo")}{" "}
              {i18n.t("corporateSign.steps.stepCompletedMessage")}
            </Text>
          </Box>
        )}
      </div>
    );
  }
);

export default Step1;
