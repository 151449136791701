/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListTollPasses = MatxLoadable({
  loader: () => import("../views/tollpass"),
});

const tollPassRoutes = [
  {
    path: "/tollpass/list",
    component: ListTollPasses,
    permission: ["manager"]
  },
];

export default tollPassRoutes;
