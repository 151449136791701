/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListCredits = MatxLoadable({
  loader: () => import("../views/credits"),
});

const creditRoutes = [
  {
    path: "/credits/list",
    component: ListCredits,
    permission: ["manager"]
  },
];

export default creditRoutes;
