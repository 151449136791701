import React, { useEffect, useState } from "react";

import { Box, FormControlLabel, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import constants from "common/constants";
import { useScrollTo, uploadTicketFile } from "app/utils/functions";

import {
  useTicketDetailSub,
  useMakeTicketActionAndUpdate,
  useArchivedTicketDetailSub,
} from "services/gql/ticket";
import { useTranslation } from "react-i18next";

import ChatInput from "app/ui/Atoms/ChatInput";
import OverlayLoader from "app/ui/Atoms/OverlayLoader";

import TicketDetailCard from "app/ui/Molecule/TicketDetailCard";
import LoadContainer from "app/ui/Molecule/LoadContainer";

import TicketActionMessages from "app/ui/Organism/TicketActionMessages";

import Text from "app/ui/Atoms/Text";
import { useConditionalQueryHook } from "services/utils";

const TicketActionBox = ({
  ticketId,
  eventId,
  user,
  onEventTicketClick,
  initialMessage,
  setTicketMessage,
  isArchived
}) => {
  const { t } = useTranslation();
  const [newMsg, setNewMsg] = useState(
    initialMessage ? initialMessage : ""
  );
  const [checked, setCheched] = useState(false);

  const [msgEndRef, scrollToBottom] = useScrollTo();

  const [loading, setLoading] = useState(false);

  const makeAction = useMakeTicketActionAndUpdate(ticketId);

  const ticketDetail = useConditionalQueryHook(isArchived, useArchivedTicketDetailSub(ticketId), useTicketDetailSub(ticketId))

  useEffect(() => {
    scrollToBottom();
  }, [ticketDetail?.data?.ticket?.actions?.length, scrollToBottom]);

  if (ticketDetail?.error) {
    return (
      <Alert severity="error">{ticketDetail.error.message}</Alert>
    );
  }

  if (ticketDetail?.loading) {
    return <LoadContainer />;
  }

  if (!ticketDetail?.data) {
    return <Alert severity="warning">No data...</Alert>;
  }
  const {
    data: { ticket },
  } = ticketDetail;

  const changeAction = type => async (newValue, oldValue) => {
    setLoading(true);
    await makeAction
      .change(type, oldValue, newValue, "", 0)
      .catch(console.error);
    setLoading(false);
  };
  // console.log("ticketactionbox:ticker:", ticket);

  const eventActions = [];
  ticket?.event?.actions && ticket.event.actions.forEach(item => {
    item.actions.forEach(item2 => {
      eventActions.push(item2);
    });
  });
  eventActions.sort(function(a, b) {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  let closer = "";

  if (ticket?.actions.length > 0) {
    // eslint-disable-next-line no-unused-expressions
    ticket?.actions.map(action => {
      if (
        action.content.type === "STATUS" &&
        (action.content.to === "SOLVED" ||
          action.content.to === "SOLVEDHAPPY")
      ) {
        if (action.sender)
          closer = `${action.sender.name} ${action.sender.surname}`;
        else closer = t("ticketAssignee.unknown");
      }
    });
  }
  if (closer === "") {
    if (ticket?.status === "SOLVED" || ticket?.status === "SOLVEDHAPPY")
      closer = t("ticketAssignee.unknown");
    else closer = t("statusTypes.open");
  }

  // console.log("ticketactionbox:eventActions:", eventActions);
  return (
    <OverlayLoader loading={loading}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
      >
        <Box flex="content">
          <TicketDetailCard
            ticket={ticket}
            eventId={eventId}
            onEventTicketClick={onEventTicketClick}
            onStatusChange={changeAction(
              constants.TICKET_ACTION_TYPE.STATUS
            )}
            onAssigneeChange={changeAction(
              constants.TICKET_ACTION_TYPE.ASSIGN
            )}
            onPriorityChange={changeAction(
              constants.TICKET_ACTION_TYPE.PRIORITY
            )}
          />
        </Box>
        {(isArchived === false) && (<Box
          display="flex"
          flexDirection="column"
          flex="1"
          width="100%"
          alignItems="flex-end"
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={() => setCheched(!checked)}
              />
            }
            label={t("chatBox.allEventHistory")}
          />
        </Box>)}
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          width="100%"
          alignItems="flex-end"
        >
          <TicketActionMessages
            actions={checked ? eventActions : ticket?.actions}
            userId={user.id}
            ticketId={ticketId}
            ticketDescription={ticket?.event?.description}
          />
          <div ref={msgEndRef} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          width="100%"
          alignItems="flex-end"
        >
          {closer === t("statusTypes.open") ? (
            <Text> {t("ticketEventListPage.stillOpenTicket")} </Text>
          ) : (
            <Text>
              {t("ticketEventListPage.ticketClosedBy", {
                name: closer,
              })}
            </Text>
          )}
        </Box>
        <Box>
          <ChatInput
            onChange={v => {
              setNewMsg(v);
              if (setTicketMessage) {
                setTicketMessage(v);
              }
            }}
            value={newMsg}
            onFileAttach={async file => {
              setLoading(true);
              try {
                await uploadTicketFile(ticketId, file);
                await makeAction.file(file.name);
              } catch (err) {
                console.error(err);
              }
              setLoading(false);
            }}
            onSend={() => {
              makeAction
                .message(newMsg)
                .then(() => setNewMsg(""))
                .catch(console.error);
            }}
          />
        </Box>
      </Box>
    </OverlayLoader>
  );
};

export default TicketActionBox;
