import React from "react";
import Button from "../Atoms/Button";
import variables from "../../../styles/_variables.scss";

const ModalActions = ({ actions }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {actions &&
        actions.map(action => {
          return (
            <div style={{ marginLeft: "8px" }}>
              <Button
                ref={action.ref}
                type={action.type}
                icon={action.icon}
                iconPosition={action.iconPosition}
                disabled={action.disabled}
                height="36px"
                width="100px"
                color={action.color}
                textColor={variables.zipcar_white}
                fontSize={12}
                onClick={action.onClick}
                loading={action.loading}
              >
                {action.text}
              </Button>
            </div>
          );
        })}
    </div>
  );
};
export default ModalActions;
