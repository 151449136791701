import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { tr, en } from "./locales";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  load: "languageOnly",
  defaultLanguage: "tr",
  otherLanguages: ["en"],
  debug: false,
  resources: {
    tr: {
      translation: tr,
    },
    en: {
      translation: en,
    },
  },
  fallbackLng: ["tr"],
	order: ['cookie', 'localStorage'],
	cache: ['cookie'],
  // ns: ["common"],

  // defaultNS: "common",
  // interpolation: {
  // 	escapeValue: false
  // },
  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);
export default i18n;
