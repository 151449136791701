import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "react-credit-cards";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "app/utils/creditCard";

import "react-credit-cards/es/styles-compiled.css";
import {
  TextField,
  FormHelperText,
  FormControl,
  Box,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
// import variables from "styles/_variables.scss";
import i18n from "app/i18n";
// import Text from "app/ui/Atoms/Text";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

const formWrapper = {
  marginTop: "3rem",
};
const row = {
  display: "flex",
};
const rowItemsLeft = {
  width: "100%",
  paddingRigt: "1rem",
};
const rowItemsRight = {
  width: "100%",
  paddingLeft: "1rem",
};

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: "left",
  },
}));

const schema = yup.object().shape({
  number: yup
    .string()
    .min(16, i18n.t("validation.minLength"))
    .required(i18n.t("validation.required")),
  name: yup.string().required(i18n.t("validation.required")),
  expiry: yup.string().required(i18n.t("validation.required")),
  cvc: yup.string().required(i18n.t("validation.required")),
});

const Step2 = forwardRef(
  ({ allData, setAllData, onSubmit, currentAccount }, ref) => {
    const classes = useStyles();
    const submit = useRef();
    const cardForm = useRef();
    const [isFormValid, setIsFormValid] = useState(false);
    const { handleSubmit, register, errors, setValue } = useForm({
      mode: "all",
      resolver: currentAccount ? null : yupResolver(schema),
    });
    const [step2Data, setStep2Data] = useState({
      name: "",
      number: "",
      expiry: "",
      cvc: "",
      focused: "",
      issuer: "",
    });

    useEffect(() => {
      if (allData?.step2) {
        setStep2Data({
          name: allData?.step2?.name,
          number: allData?.step2?.number,
          expiry: allData?.step2?.expiry,
          cvc: allData?.step2?.cvc,
        });
      }
    }, [allData?.step2]);

    const [isInfoChanged, setIsInfoChanged] = useState(false);
    const handleCallback = ({ issuer }, isValid) => {
      if (isValid) {
        setStep2Data({
          ...step2Data,
          issuer,
        });
      }
    };

    const handleInputFocus = ({ target }) => {
      setStep2Data({
        ...step2Data,
        focused: target.name,
      });
    };

    const handleInputChange = ({ target }) => {
      let value;
      switch (target.name) {
        case "number":
          value = formatCreditCardNumber(target.value);
          break;
        case "expiry":
          value = formatExpirationDate(target.value);
          break;
        case "cvc":
          value = formatCVC(target.value);
          break;
        default:
          value = target.value;
      }

      setStep2Data({ ...step2Data, [target.name]: value });
      setValue(target.name, value);
      setIsInfoChanged(true);
    };

    useImperativeHandle(ref, () => ({
      onCompleteForm() {
        if (isInfoChanged) {
          setAllData(step2Data);
        }
        submit.current.click();
        return isFormValid;
      },
    }));

    const onValid = () => {
      setIsFormValid(true);
      onSubmit();
    };

    return (
      <div style={formWrapper}>
        {/* {JSON.stringify(step2Data)} */}
        {/* {currentAccount ? "CrediCard Optional" : "CrediCard must"} */}
        <Card
          number={step2Data.number}
          name={step2Data.name}
          expiry={step2Data.expiry}
          cvc={step2Data.cvc}
          focused={step2Data.focused}
          callback={handleCallback}
        />
        <form
          ref={cardForm}
          onSubmit={handleSubmit(onValid)}
          className={classes.form}
        >
          <Box>
            <FormControl margin="dense" fullWidth>
              <TextField
                inputProps={{
                  ref: register,
                  name: "number",
                }}
                helperText={errors.number?.message}
                error={!!errors.number}
                type="tel"
                className="form-control"
                placeholder={i18n.t("creditCard.cardNo")}
                pattern="[\d| ]{16,16}"
                variant="outlined"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                defaultValue={allData.step2?.number}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl margin="dense" fullWidth>
              <TextField
                inputProps={{
                  ref: register,
                  name: "name",
                }}
                helperText={errors.name?.message}
                error={!!errors.name}
                type="text"
                className="form-control"
                placeholder={i18n.t("creditCard.fullName")}
                variant="outlined"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                defaultValue={allData.step2?.name}
              />
            </FormControl>
          </Box>

          <Box style={row}>
            <Box style={rowItemsLeft}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  inputProps={{
                    ref: register,
                    name: "expiry",
                  }}
                  helperText={errors.expiry?.message}
                  error={!!errors.expiry}
                  type="tel"
                  className="form-control"
                  placeholder={i18n.t("creditCard.expiry")}
                  pattern="\d\d/\d\d"
                  variant="outlined"
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  defaultValue={allData.step2?.expiry}
                />
              </FormControl>
            </Box>

            <Box style={rowItemsRight}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  inputProps={{
                    ref: register,
                    name: "cvc",
                  }}
                  helperText={errors.cvc?.message}
                  error={!!errors.cvc}
                  type="tel"
                  className="form-control"
                  placeholder={i18n.t("creditCard.cvc")}
                  pattern="\d{3,4}"
                  variant="outlined"
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  defaultValue={allData.step2?.cvc}
                />
              </FormControl>
            </Box>
          </Box>
          <FormHelperText
            className="mt-8"
            style={{ color: "red", letterSpacing: "0.7px" }}
          >
            Kredi Kartı bilgilerinizi doldurmadan önce kartınızın
            Debit Kart / Bankamatik kartı olmamasına ve internet
            kullanımına açık olmasına dikkat edin.
          </FormHelperText>
          <input
            type="hidden"
            name="issuer"
            value={step2Data.issuer}
          />
          <input
            type="submit"
            ref={submit}
            style={{ display: "none" }}
          />
        </form>
      </div>
    );
  }
);

export default Step2;
