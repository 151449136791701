import React, { useRef } from "react";

import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
} from "@material-ui/core";
import { AttachFile, Send } from "@material-ui/icons";

const ChatInput = ({ value, onChange, onSend, onFileAttach }) => {
  const inputRef = useRef();
  return (
    <>
      <Box display="none">
        <input
          type="file"
          ref={inputRef}
          onChange={e => {
            if (e.target.files && e.target.files[0]) {
              onFileAttach(e.target.files[0]);
            }
          }}
        />
      </Box>
      <TextField
        onChange={({ target: { value: newValue } }) =>
          onChange(newValue)
        }
        onKeyDown={e => {
          if (e.keyCode === 13) {
            onSend();
          }
        }}
        value={value}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onSend}>
                <Send />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (!inputRef.current) {
                    return;
                  }
                  inputRef.current.click();
                }}
              >
                <AttachFile />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default ChatInput;
