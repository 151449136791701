import React from "react";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { useTranslation } from "react-i18next";

import * as utilFns from "app/utils/functions";

const ArrayInput = ({
  onChange,
  addButtonText = "input.addButton",
  errors = [],
  value = [],
  InputItem,
  emptyItem = {},
}) => {
  const { t } = useTranslation();
  return (
    <>
      {value.map((item, index) => (
        <Box mb={2} mt={1} id={`ticket_${index}`}>
          <Paper elevation={3}>
            <Box p={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="subtitle1">
                  {`#${index + 1}`}
                </Typography>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    onChange(utilFns.removeArrayItem(value, index));
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </Grid>
              <InputItem
                id={index}
                value={item}
                error={errors[index]}
                onChange={newValue =>
                  onChange(
                    utilFns.updateArrayIndex(value, index, newValue)
                  )
                }
              />
            </Box>
          </Paper>
        </Box>
      ))}
      <Divider variant="middle" />
      <FormControl fullWidth margin="normal">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            onChange([...value, emptyItem]);
          }}
        >
          {t(addButtonText)}
        </Button>
      </FormControl>
    </>
  );
};

export default ArrayInput;
