import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import { getUserRole } from "../../../app/utils/functions";

const styles = theme => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  }
});

class MatxVerticalNav extends Component {
  constructor(props){
    super(props);
    this.state = {
      collapsed: true
    };
    this.renderLevels = (data, level, parentKey) => {
      let {roles} = this.props;
  
      // console.log("ROLEs:", roles)
  
      let offset = level * 20;
  
      return data.map((item, index) => {
  
        let k = (parentKey && parentKey != "") ? parentKey + "." + item.key : item.key
  
        // console.log("KEY:", k, item.key, "ROLE:", getUserRole(), roles[k])
        if(item.key){
          if ( !roles || !roles[k] || !roles[k].r){
            return
          }
        }
  
        if (item.children) {
          return (
            <MatxVerticalNavExpansionPanel item={item} key={index}>
              {this.renderLevels(item.children, level + 1, k)}
            </MatxVerticalNavExpansionPanel>
          );
        } else {
          return (
            <NavLink key={index} to={item.path} className="nav-item" style={{paddingLeft: offset}}>
              <TouchRipple key={item.name} name="child" className="w-100">
                {(() => {
                  if (item.icon) {
                    return (
                      <Icon className="item-icon text-middle">{item.icon}</Icon>
                    );
                  } else {
                    return (
                      <span className="item-icon icon-text">{item.iconText}</span>
                    );
                  }
                })()}
                <span className="text-middle pl-20 item-text">{item.name}</span>
                <div className="mx-auto"></div>
                {item.badge && (
                  <div className={`badge bg-${item.badge.color}`}>
                    {item.badge.value}
                  </div>
                )}
              </TouchRipple>
            </NavLink>
          );
        }
      });
    };
  
    this.handleClick = () => {
      this.setState({ collapsed: !this.state.collapsed });
    };
    
  }

  render() {
    return (
      <div className="navigation">
        {this.renderLevels(this.props.navigation, 0)}
      </div>
    );
  }
}

export default withStyles(styles)(MatxVerticalNav);
