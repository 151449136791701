/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ReportsList = MatxLoadable({
  loader: () => import("../views/reports"),
});

const reportRoutes = [
  {
    path: "/reports",
    component: ReportsList,
    permission: ["manager"]
  },
];

export default reportRoutes;
