import React from "react";
import { CircularProgress } from "@material-ui/core";
import CenteredContainerImage from "../Molecule/CenteredContainerImage";

const LoadScreen = () => {
  return (
    <CenteredContainerImage>
      <CircularProgress size={80} color="secondary" />
    </CenteredContainerImage>
  );
};

export default LoadScreen;
