import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import ChatBox from "app/ui/Molecule/ChatBox";

const useChatBoxStyles = makeStyles(({ spacing, palette }) => ({
  box: { overflow: "auto", position: "relative" },
  iconButton: { padding: spacing(1), color: palette.common.white },
  title: {
    color: palette.common.white,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    marginLeft: "12px",
  },
}));

const BasicChatBox = ({
  title,
  height,
  width,
  children,
  headerActions,
  onHeaderClick,
  open,
}) => {
  const classes = useChatBoxStyles();
  return (
    <Box width={width}>
      <ChatBox
        open={open}
        header={
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            onClick={onHeaderClick}
          >
            <Grid item>
              <Typography className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item>{headerActions}</Grid>
          </Grid>
        }
        body={
          <Box
            height={height}
            width={width}
            className={classes.box}
            p={1}
          >
            {children}
          </Box>
        }
      />
    </Box>
  );
};

export default BasicChatBox;
