/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListSettings = MatxLoadable({
  loader: () => import("app/views/settings/settingsList"),
});

const settingsRoutes = [
  {
    path: "/settings/list",
    component: ListSettings,
    permission: ["settings"]
  },
];

export default settingsRoutes;
