import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-css";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Image from "app/ui/Atoms/Image";
import variables from "styles/_variables.scss";
import Icon from "app/ui/Atoms/Icon";
import { Box } from "@material-ui/core";
import config from "common/config";
import { CloudDownload } from "@material-ui/icons";
import Text from "app/ui/Atoms/Text";
import { downloadMedia, getMediaURL, getToken } from "app/utils/functions";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// import Button from "app/ui/Atoms/Button";
// import Text from "app/ui/Atoms/Text";
// import IconButton from "app/ui/Atoms/IconButton";

const StyledPaper = withStyles(() => ({
  root: {},
}))(Paper);

const ImageList = forwardRef(
  (
    {
      type,
      bucket,
      pathPrefix,
      width,
      height,
      imageWidth,
      imageHeight,
      maxThumbCount = 3,
      onClick,
      onSelectMode = false,
      onSelectEvent,
      isDriverOrIdentity,
      // externalFiles,
      hideLaunchIcon,
      lightboxSupport,
      isNewTab,
    },
    ref
  ) => {
    // const { t } = useTranslation();
    const useStyles = makeStyles({
      root: {
        width: `${width - 32}px`,
        height: `${height - 32}px`,
      },
      rootIcon: {
        color: variables.zipcar_text_light,
        fontSize: 18,
      },
      masonryGrid: {
        display: "flex",
        marginLeft: "-30px",
        width: "auto",
      },
      masonryGridColumn: {
        paddingLeft: "30px",
        backgroundClip: "padding-box",
      },
      masonryGridColumnDiv: {
        position: "relative",
        // border: onSelectMode ? "solid 1px green" : "none",
        cursor: onSelectMode ? "pointer" : "default",
        "& img": {
          display: "block",
          marginBottom: "30px",
          borderRadius: 32 / 2,
          scale: onSelectMode ? 0.8 : 1,
        },
        "&>div": {
          position: "absolute",
          width: "100%",
          height: "100%",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
          paddingRight: "10px",
          paddingBottom: "10px",
        },
      },
      image: {
        width: imageWidth,
        height: imageHeight,
        overflow: "hidden",
        flexDirection: "column",
        marginRight: "4px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        border: 0,
        backgroundColor: "transparent",
      },
      miniImageList: {
        backgroundColor: "transparent",
        display: "flex",
        flex: 0,
        flexDirection: "row",
        alignItems: "center",
      },
      moreIcon: {
        background: variables.zipcar_background,
        color: variables.zipcar_primary,
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontWeight: 500,
        cursor: "pointer",
        "&:hover": {
          background: variables.zipcar_text_hover,
        },
      },
    });
    const classes = useStyles();
    const [images, setImages] = useState(null);
    const [files, setFiles] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    function _imageEncode (arrayBuffer) {
      let u8 = new Uint8Array(arrayBuffer)
      let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
      let mimetype="image/jpeg"
      return "data:"+mimetype+";base64,"+b64encoded
  }

    const onReload = async () => {
      if (pathPrefix === undefined) {
        console.error("You Need to set pathPrefix!");
        return;
      }
      
      pathPrefix = pathPrefix.toString();

      if (pathPrefix.charAt(pathPrefix.length-1) !== "/" && !isDriverOrIdentity) {
        pathPrefix = pathPrefix + "/";
      }

      const token = await getToken();

      axios
        .get(
          `${config.mediaURI}/api/v1/listMedia?bucket=${bucket}&path=${pathPrefix}`, { headers: {'Authorization': `Bearer ${token}` }}
        )
        .then(res => {
          if (res.data === null) {
            setFiles([]);
          } else {
            setFiles(res.data);
            if (lightboxSupport) {

              if(res && res.data && res.data.data){
                Promise.all(res.data?.data?.map(r => {
                  return axios.get(`${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${r.name}`, { headers: {'Authorization': `Bearer ${token}` }, responseType: 'arraybuffer'})
                })).then(imageResults => {
                  
                  imageResults.map((ir, indx) => {
                    // console.log("Image Result:", indx, ir)
                  })

                  setImages(
                    imageResults.map((ir, indx) => {
                      return _imageEncode(ir.data)
                    })
                  );
                  // imageResults.map((ir, indx) => {
                  //   console.log("Image Result:", indx, ir, _imageEncode(ir.data))
                  // })

                })
              }

              // setImages(
              //   res.data?.data
              //     ?.map(x => {
              //       return `${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${x.name}`;
              //     })
              //     .filter(x =>
              //       ["png", "jpeg", "jpg"].includes(
              //         x.split(".").pop()
              //       )
              //     )
              // );
            }
          }
          setSelectedFiles([]);
          setTimeout(onSelectEvent, 100);
        })
        .catch(res => {
          // console.log(res);
        });
    };

    const toggleSelection = id => {
      if (selectedFiles.indexOf(id) >= 0) {
        setSelectedFiles(selectedFiles.filter(e => e !== id));
      } else {
        const sdf = [...selectedFiles];
        sdf.push(id);
        setSelectedFiles(sdf);
      }
      setTimeout(onSelectEvent, 100);
    };

    useEffect(() => {
      if (files == null) {
        onReload();
      }
      setSelectedFiles(selectedFiles);
    }, [files, selectedFiles]);

    useImperativeHandle(ref, () => ({
      reload() {
        onReload();
      },
      getSelectedItems() {
        return selectedFiles;
      },
      resetSelection() {
        setSelectedFiles([]);
      },
    }));

    if (type === "masonry") {
      return (
        <StyledPaper elevation={0} classes={{ root: classes.root }}>
          <div style={{ flex: 1, display: "flex", outline: "none" }}>
            <Masonry
              breakpointCols={2}
              className={classes.masonryGrid}
              columnClassName={classes.masonryGridColumn}
            >
              {files &&
                files.data &&
                files.data.map((item, index) => {
                  const isFileSelected =
                    selectedFiles.indexOf(item.name) >= 0;
                  return (
                    <div
                      role="button"
                      className={classes.masonryGridColumnDiv}
                      onClick={() => {
                        if (onSelectMode) toggleSelection(item.name);
                      }}
                      onKeyDown={() => {}}
                      tabIndex={index}
                    >
                      {onSelectMode && (
                        <div>
                          <Icon
                            name={
                              isFileSelected
                                ? "check_circle"
                                : "radio_button_unchecked"
                            }
                            size={25}
                            color={
                              isFileSelected
                                ? variables.zipcar_primary
                                : variables.zipcar_text_light
                            }
                          />
                        </div>
                      )}
                      {isNewTab ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${config.mediaURI}/api/getMedia?bucket=${bucket}&name=${item.name}`}
                        >
                          <Image
                            imgAddress={`${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${item.name}`}
                            // imgAddress={`http://localhost:8080/api/getMedia?bucket=${bucket}&name=${item.name}`}
                          />
                        </a>
                      ) : (
                        <Image
                          imgAddress={`${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${item.name}`}
                          // imgAddress={`http://localhost:8080/api/getMedia?bucket=${bucket}&name=${item.name}`}
                        />
                      )}
                    </div>
                  );
                })}
            </Masonry>
          </div>
        </StyledPaper>
      );
    }

    return (
      <StyledPaper
        elevation={0}
        classes={{
          root: classes.miniImageList,
        }}
      >
        {(!files ||
          !files.data ||
          files.data.length === 0 ||
          files.data.length <= maxThumbCount) &&
          !isDriverOrIdentity &&
          !hideLaunchIcon && (
            <Box mr={2}>
              <Icon
                name="launch_rounded"
                color={variables.zipcar_primary}
                onClick={onClick}
                size={24}
              ></Icon>
            </Box>
          )}
        {files &&
          files.data &&
          files.data.map((item, index) => {
            if (index + 1 > maxThumbCount) {
              return null;
            }

            const fields = item?.name?.split(".");

            // console.log("File:", item, fields, ["png", "jpeg", "jpg"].includes(fields[fields.length-1]))

            let isImage = false;
            if (
              fields?.length > 0 &&
              ["png", "jpeg", "jpg"].includes(
                fields[fields.length - 1]
              )
            ) {
              isImage = true;
            }

            if (isNewTab) {
              return (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${config.mediaURI}/api/getMedia?bucket=${bucket}&name=${item.name}`}
                >
                  <Image
                    imgAddress={`${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${item.name}`}
                    // imgAddress={`http://localhost:8080/api/getMedia?bucket=${bucket}&name=${item.name}`}
                    width={imageWidth || 50}
                    height={imageHeight || "100%"}
                  />
                </a>
              );
            }

            return (
              <button
                type="button"
                onClick={() => {
                  if (lightboxSupport && images?.length > 0) {
                    setPhotoIndex(index);
                    if (isImage) {
                      setIsOpen(true);
                    }
                  }
                }}
                className={classes.image}
              >
                {isDriverOrIdentity && (
                  <Image
                    imgAddress={`${
                      config.mediaURI
                    }/api/v1/getMedia?bucket=${bucket}&name=${
                      item.name
                    }&date=${new Date().getTime()}`}
                    width={imageWidth || 50}
                    height={imageHeight || 50}
                  />
                )}
                {!isDriverOrIdentity && isImage && (
                  <Image
                    imgAddress={`${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${item.name}`}
                    style={{
                      maxHeight: imageHeight || 50,
                      maxWidth: imageWidth || 50,
                    }}
                  />
                )}
                {!isDriverOrIdentity && !isImage && (
                  <button
                    type="button"
                    style={{
                      width: imageWidth || 50,
                      height: imageHeight || 50,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      console.log("download media", item?.name?.split("/").length > 1
                          ? item?.name?.split("/")[1]
                          : item?.name,
                        getMediaURL(bucket, item?.name));

                      downloadMedia(
                        item?.name?.split("/").length > 1
                          ? item?.name?.split("/")[1]
                          : item?.name,
                        getMediaURL(bucket, item?.name)
                      );
                    }}
                    className={classes.image}
                  >
                    <CloudDownload fontSize="medium" />
                    <Text
                      color={variables.zipcar_text_light}
                      weight={500}
                      style={{ textAlign: "center" }}
                    >
                      {item?.name?.split("/").length > 1
                        ? item?.name?.split("/")[1]
                        : item?.name}
                    </Text>
                  </button>
                )}
                {/* {JSON.stringify(images)} */}
              </button>
            );
          })}

        {files &&
          files.data &&
          files.data.length > maxThumbCount &&
          typeof onClick === "function" && (
            <div className={classes.moreIcon}>
              {files &&
                files.data &&
                files.data.length - maxThumbCount}
              <Icon
                name="add"
                size={18}
                color={variables.zipcar_primary}
                onClick={() => onClick()}
                style={{
                  borderRadius: "0%",
                  border: "solid 3px green",
                  margin: "0px",
                  padding: "0px",
                }}
              />
            </div>
          )}

        {isOpen && (
          <Lightbox
            reactModalStyle={{zIndex:2000}}
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
            toolbarButtons={[]}
          />
        )}
      </StyledPaper>
    );
  }
);

export default ImageList;
