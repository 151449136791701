import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core";
import TextInput from "app/ui/Atoms/TextInput";
import Text from "app/ui/Atoms/Text";
import Button from "app/ui/Atoms/Button";
import "./listview.scss";
import DateTimePickerInput from "app/ui/Atoms/DateTimePickerInput";
import { ColorSelector } from "app/ui/Organism/CustomGqlSelector";
import { AmenitiesSelector } from "app/ui/Organism/CustomSimpleSelector";
import { VehicleTypeSelector } from "../../Organism/CustomGqlSelector";
import { ParseToDate } from "app/utils/functions";

const useStyles = makeStyles(() => ({
  root: ({ backgroundColor, border}) => ({
    width: "100%",
    backgroundColor: backgroundColor,
    border: border,
    borderRadius: border ? 10 : 0,
    // maxWidth: 360,
    // maxHeight: 300
  }),
  left: ({ width }) => ({
    width: width ? `${width}%` : "40%",
    padding: 0,
  }),
  middle: {
    width: "1%",
    padding: 0,
    paddingRight: "10px",
  },
  right: ({ width }) => ({
    width: width ? `${99 - width}%` : "59%",
    padding: 0,
  }),
}));

const ListView = ({
  data,
  textSize,
  leftTextWeight,
  rightTextWeight = 500,
  setEditableData,
  backgroundColor,
  border,
  color,
  editedData,
  setEditedData,
  width,
}) => {
  const classes = useStyles({ width, backgroundColor, border });
  const editOrShowLabel = (
    item,
    isEditable,
    dataLabel,
    value,
    type
  ) => {
    if (isEditable) {
      return type === "date" ? (
        <DateTimePickerInput
          selectedDate={
            (editedData && editedData[dataLabel]) || value || item
          }
          placeholder={
            (editedData && editedData[dataLabel]) || value || item
          }
          width={250}
          height={30}
          handleDateChange={e => {
            if (setEditedData) {
              setEditedData({ ...editedData, [dataLabel]: e });
            }
            if (setEditableData) {
              setEditableData(dataLabel, e);
            }
          }}
        />
        ) : 
        type === "colorSelector" ? (
          <div style={{ width: "50%", height: "50%" }}>
            <ColorSelector
              value={(dataLabel && editedData && editedData[dataLabel]) ? {id: editedData[dataLabel].color_id, name: editedData[dataLabel].name} : value}
              onChange={e => {
                if (setEditedData) {
                  setEditedData({ ...editedData, [dataLabel]: {color_id: e?.id, name: e?.name} });
                }
                if (setEditableData) {
                  setEditableData(dataLabel, {color_id: e?.id, name: e?.name});
                }
              }}
            />
          </div>
      ) : 
      type === "vehicleTypeSelector" ? (
        <div style={{ width: "50%", height: "50%" }}>
          <VehicleTypeSelector
            value={(dataLabel && editedData && editedData[dataLabel]) ? {id: editedData[dataLabel].type_id, name: editedData[dataLabel].type_name} : value}
            onChange={e => {
              if (setEditedData) {
                setEditedData({ ...editedData, [dataLabel]: {type_id: e?.id, type_name: e?.name} });
              }
              if (setEditableData) {
                setEditableData(dataLabel, {type_id: e?.id, type_name: e?.name});
              }
            }}
          />
      </div>
    ) : 
    type === "amenitiesSelector" ? (
          <AmenitiesSelector
            value={editedData[dataLabel] ?? value}
            multiple={true}
            fullWidth
            onChange={e => {
              if (setEditedData) {
                setEditedData({ ...editedData, [dataLabel]: e });
              }
              if (setEditableData) {
                setEditableData(dataLabel, e);
              }
            }}
          />
        ) :
        (
        <TextInput
          value={value || item}
          width={300}
          height={30}
          placeholder={value || item}
          multiline
          rows={1}
          rowsMax={1}
          rowsMin={1}
          handleChange={e => {
            if (setEditedData) {
              setEditedData({ ...editedData, [dataLabel]: e });
            }
            if (setEditableData) {
              setEditableData(dataLabel, e);
            }
          }}
        />
      );
    }
    if (typeof item === "string") {
      return (
        <Text
          weight={rightTextWeight}
          size={textSize || 14}
          color={color}
        >
          {item}
        </Text>
      );
    }
    if (dataLabel === "joinedAccounts" && item.length !== 0) {
      return item.map(account => (
        <Button type="text" height={32}>
          {account}
        </Button>
      ));
    }
    return item;
  };

  const renderData = () => {
    return data?.map(el => (
      <ListItem className="listitem">
        <ListItem className={classes.left}>
          <Text
            weight={leftTextWeight}
            size={textSize || 14}
            color={color}
          >
            {el?.left}
          </Text>
        </ListItem>
        {(el.right || el.right === 0) && (
          <ListItem className={classes.middle}>
            <Text size={textSize || 14} color={color}>
              :
            </Text>
          </ListItem>
        )}
        <ListItem className={classes.right}>
          {editOrShowLabel(
            el?.right,
            el?.isEditable,
            el?.dataLabel,
            el?.value,
            el?.type
          )}
        </ListItem>
      </ListItem>
    ));
  };

  // const renderData = () => {
  //   return data.map(el => (
  //     <ListItem className="listitem">

  //       <div className={classes.left}>
  //         <Text
  //           weight={leftTextWeight}
  //           size={textSize || 14}
  //           color={color}
  //         >
  //           {el?.left}
  //         </Text>
  //       </div>
  //       {(el.right || el.right === 0) && (
  //         <div className={classes.middle}>
  //           <Text size={textSize || 14} color={color}>
  //             :
  //           </Text>
  //         </div>
  //       )}
  //       <div className={classes.right}>
  //         {editOrShowLabel(
  //           el?.right,
  //           el?.isEditable,
  //           el?.dataLabel,
  //           el?.value,
  //           el?.type
  //         )}
  //       </div>
  //     </ListItem>
  //   ));
  // };

  return <List className={classes.root}>{renderData()}</List>;
};

export default ListView;
