import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Container,
  Box,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";

import Text from "app/ui/Atoms/Text";
import Image from "app/ui/Atoms/Image";
import Login from "app/ui/Pages/Auth/Login";
import Register from "app/ui/Pages/Auth/Register";
import i18n from "app/i18n";
import variables from "styles/_variables.scss";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import { usePrevious } from "app/utils/usePrevious";
import CodeVerification from "app/ui/Molecule/CodeVerification";



const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
  },
  btn: {
    marginLeft: theme.spacing(1),
    backgroundColor: variables.zipcar_primary,
    color: variables.zipcar_white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 6px -6px",
    width: 130,
    height: 44,
    padding: "14px 28px",
    borderRadius: "25px",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_primary,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 35px",
    },
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 0,
    padding: "148px 0 148px 0",
    height: "100vh",
    backgroundColor: variables.zipcar_primary,
    [theme.breakpoints.down("md")]: {
      height: "10vh",
      justifyContent: "center",
      padding: 0,
    },
  },
  rightSide: {
    textAlign: "center",
    height: "100vh",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      minHeight: "90vh",
    },
  },
  logo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  singUp: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 48,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: 0,
    },
  },
  form: {
    padding: 48,
  },
  no: {
    display: "none",
  },
}));

const Auth = ({
  onSubmit,
  attemptCount,
  onForgotPassword,
  recover,
  triggerMicrosoftLogin,
  loading,
  errorMessage,
  verified,
  authorized,
  verifyCode
}) => {
  const [currentAccount, setCurrentAccount] = useState(false);
  const [formSwitch, setformSwitch] = useState(true);
  // const [showTwoWayVerify, setShowTwoWayVerify] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const classes = useStyles();
  function LoginForm() {
    setformSwitch(!formSwitch);
  }

  const { enqueueSnackbar } = useSnackbar();

  const prevLoading = usePrevious(loading);


  useEffect(() => {
    if (window.location.hash === "#current=1") {
      setCurrentAccount(true);
    }
    // const obj = queryString.parse(window.location.hash);
    // console.log("params", obj);

    // if (obj.recovery_token) {
    //   recover(obj.recovery_token);
    // }
  }, [window.location]);

  useEffect(()=>{
    console.log("ERROR MESSAGE:", errorMessage)
    if(errorMessage){
      enqueueSnackbar(
        "Lütfen giriş bilgilernizi kontrol ediniz.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      );
    }
  },[errorMessage])

  const RenderTitle = ({}) => {

    if(authorized && !verified){
      return(
        <CodeVerification verifyCode={verifyCode}/>
      )
    } else {
      return(
        <Box className={formSwitch ? classes.form : classes.no}>
          <Text weight={700} size={30}>
            {i18n.t("corporateSign.welcome")}
          </Text>
        </Box>
      )
    }

  }

  const RenderContent = ({}) => {
    if(authorized && !verified){
      return(
        <>
        </>
      )
    }
    

    if (formSwitch && !currentAccount){  
      return(
        <Login
          onSubmit={onSubmit}
          attemptCount={attemptCount}
          onForgotPassword={onForgotPassword}
          triggerMicrosoftLogin={triggerMicrosoftLogin}
          loading={loading}
          forgotPassword={forgotPassword}
          setForgotPassword={setForgotPassword}
        />
      )
    } else {
      return(
        <Register currentAccount={currentAccount} />
      )
    }

  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Paper className={classes.leftSide}>
            <Box className={classes.logo}>
              <img
                src="/assets/images/logo-zipcar-login.png"
                alt="company-logo"
                width="150"
                height="100%"
              />
            </Box>
            <Box className={classes.content}>
              {/* <Text>Slider comes here !</Text> */}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Paper className={classes.rightSide}>
            <Box>
              {formSwitch ? (
                <Box className={classes.singUp}>
                  <Text size="large">
                    {i18n.t("corporateSign.haveAccount")}
                  </Text>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={LoginForm}
                    className={classes.btn}
                  >
                    {i18n.t("corporateSign.register")}
                  </Button>
                </Box>
              ) : (
                <Box className={classes.singUp}>
                  <Text size="large">
                    {i18n.t("corporateSign.alreadyAccount")}
                  </Text>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={LoginForm}
                    className={classes.btn}
                  >
                    {i18n.t("corporateSign.login")}
                  </Button>
                </Box>
              )}
            </Box>
            <RenderTitle/>
            <Container>
              <RenderContent/>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Auth;