import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import Text from "../Text";

const LicensePlate = ({ plate, country }) => {
  const useStyles = makeStyles({
    container: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "row",
      width: 120,
      height: 32,
      border: "1px solid black",
    },
    country: {
      display: "flex",
      width: "17%",
      backgroundColor: "#3960E2",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    plate: {
      display: "flex",
      width: "83%",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.country}>
        <Box>
          <Text weight="bold" color="white" size={10}>
            {country || "TR"}
          </Text>
        </Box>
      </div>
      <div className={classes.plate}>
        <Text weight="bold" font="medium">
          {plate}
        </Text>
      </div>
    </div>
  );
};

export default LicensePlate;
