import React from "react";

import { Select, MenuItem, Chip } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import constants from "common/constants";

const CustomSimpleSelector = ({
  multiple,
  options,
  onChange,
  value = null,
  disabled,
  fullWidth,
  placeholder = "input.placeholder.default",
  className,
}) => {
  const { t } = useTranslation();
  return multiple ? (
    <Select
      multiple
      value={value}
      label={placeholder}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: placeholder,
        id: "outlined-age-native-simple",
      }}
      renderValue={selected => (
        <div>
          <Chip
            key={selected.length}
            label={selected.length}
            style={{ position: "absolute", bottom: 10 }}
          />
        </div>
      )}
    >
      {options?.map(option => (
        <MenuItem key={String(option.value)} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <Select
      onChange={({ target: { value: newValue } }) =>
        onChange(newValue)
      }
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      displayEmpty
      className={className}
    >
      <MenuItem key="not-selected" value={null}>
        {t(placeholder)}
      </MenuItem>
      {options?.map(option => (
        <MenuItem key={String(option.value)} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </Select>
  );
};

const ticketEntityOptions = [
  { label: "entity.vehicle", value: constants.ENTITY.VEHICLE },
  { label: "entity.account", value: constants.ENTITY.ACCOUNT },
  { label: "entity.driver", value: constants.ENTITY.DRIVER },
  { label: "entity.location", value: constants.ENTITY.LOCATION },
  { label: "entity.valet", value: constants.ENTITY.VALET },
  { label: "entity.iugo", value: constants.ENTITY.IUGO },
];
export const TicketEntitySelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={ticketEntityOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.ticketEntity"
  />
);

const ticketAssigneeOptions = [
  {
    label: "ticketAssignee.operationService",
    value: constants.ASSIGNEE.OPERATION,
  },
  {
    label: "ticketAssignee.memberService",
    value: constants.ASSIGNEE.MEMBER,
  },
  { label: "ticketAssignee.iugo", value: constants.ASSIGNEE.IUGO },
  {
    label: "ticketAssignee.specific",
    value: constants.ASSIGNEE.SPECIFIC,
  },
];

export const TicketAssigneeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={ticketAssigneeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.ticketAssignee"
  />
);

const priorityOptions = [
  { label: "priority.1", value: constants.PRIORITY.PRIMARY },
  { label: "priority.2", value: constants.PRIORITY.SECONDARY },
];

export const PrioritySelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={priorityOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.priority"
  />
);

const statusOptions = [
  { label: "statusTypes.open", value: constants.STATUS.ACTIVE },
  { label: "statusTypes.solved", value: constants.STATUS.SOLVED },
  {
    label: "statusTypes.solvedHappy",
    value: constants.STATUS.SOLVEDHAPPY,
  },
];

export const StatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={statusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const reservationTypeOptions = [
  {
    label: "tripType.roundTrip",
    value: constants.CAR_MODES.ROUNDTRIP,
  },
  {
    label: "tripType.freeFloat",
    value: constants.CAR_MODES.FREEFLOATING,
  },
  // { label: "tripType.oneWay", value: constants.CAR_MODES.ONEWAY },
  { label: "tripType.all", value: constants.CAR_MODES.ALL },
];

const sortOptions = [
  {
    label: "newReservation.standardSort",
    value: constants.SORT.STANDART,
  },
  { label: "newReservation.accToName", value: constants.SORT.NAME },
  {
    label: "newReservation.accToLocation",
    value: constants.SORT.LOCATION,
  },
];

export const ReservationTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={reservationTypeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.reservationType"
  />
);

// export const SortSelector = ({
//   onChange,
//   value,
//   disabled,
//   fullWidth,
// }) => (
//   <CustomSimpleSelector
//     options={sortOptions}
//     onChange={onChange}
//     value={value}
//     disabled={disabled}
//     fullWidth={fullWidth}
//     placeholder="input.placeholder.reservationType"
//   />
// );

const provisionUnitOptions = [
  { label: "TL", value: "CERTAIN" },
  { label: "affiliate.placeholderLabel.percent", value: "PERCENT" },
];

export const ProvisionUnitSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={provisionUnitOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="affiliate.placeholderLabel.chooseUnit"
  />
);

const durationUnitOptions = [
  { label: "time.minute", value: "m" },
  { label: "time.hour", value: "h" },
  { label: "time.day", value: "d" },
  { label: "time.week", value: "w" },
  { label: "time.month", value: "M" },
  { label: "time.year", value: "y" },
];

export const DurationUnitSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={durationUnitOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="affiliate.placeholderLabel.chooseUnit"
  />
);

const lastReservationDurationUnitOptions = [
  { label: "time.m", value: "m" },
  { label: "time.h", value: "h" },
  { label: "time.d", value: "d" },
  { label: "time.w", value: "w" },
  { label: "time.M", value: "M" },
  { label: "time.y", value: "y" },
];

export const LastReservationDurationUnitSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={lastReservationDurationUnitOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.timeUnit"
  />
);

export const LastReservationDurationAmountSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => {
  const { t } = useTranslation();
  var options = [];

  for (let i = 1; i <= 60; i++) {
    options.push({ label: t("time.last") + " " + i, value: i });
  }
  return (
    <CustomSimpleSelector
      options={options}
      onChange={onChange}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      placeholder="input.placeholder.timeAmount"
    />
  );
};
// const sortOptions = [
//   { label: "newReservation.standardSort", value: constants.SORT.STANDART },
//   { label: "newReservation.accToName", value: constants.SORT.NAME },
//   { label: "newReservation.accToLocation", value: constants.SORT.LOCATION },
// ];

export const SortSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={sortOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.ticketEntity"
  />
);

export const AccountListSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  data,
}) => {
  const options = data.map(el => ({ label: el.name, value: el.id }));
  return (
    <CustomSimpleSelector
      options={options}
      onChange={onChange}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      placeholder="input.placeholder.account"
    />
  );
};

export const AffiliateListSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  options,
}) => (
  <CustomSimpleSelector
    options={options}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.ticketEntity"
  />
);

export const GoTrueListSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  options,
  type,
}) => {
  let selections = [];
  if (type === "delete") {
    selections = options?.map(el => ({
      label: el?.gotrue_user?.email,
      value: el?.gotrue_user?.id,
    }));
  }
  if (type === "add") {
    selections = options?.map(el => ({
      label: `${el?.email} - ${el?.name} ${el?.surname}`,
      value: el?.id,
    }));
  }
  return (
    <CustomSimpleSelector
      options={selections}
      onChange={onChange}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      placeholder="input.placeholder.ticketEntity"
    />
  );
};

const unitOptions = [
  {
    label: "ticketAssignee.memberService",
    value: constants.ASSIGNEE.MEMBER,
  },
  {
    label: "ticketAssignee.operationService",
    value: constants.ASSIGNEE.OPERATION,
  },
  { label: "ticketAssignee.iugo", value: constants.ASSIGNEE.IUGO },
  {
    label: "ticketAssignee.specific",
    value: constants.ASSIGNEE.SPECIFIC,
  },
];

export const UnitSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={unitOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.unit"
  />
);

const driverStatusOptions = [
  {
    label: "switchButton.active",
    value: constants.DRIVER_STATUS.ACTIVE,
  },
  {
    label: "switchButton.pending",
    value: constants.DRIVER_STATUS.PENDING,
  },
  {
    label: "switchButton.findexInvalid",
    value: constants.DRIVER_STATUS.FINDEXINVALID,
  },
  {
    label: "switchButton.findexPending",
    value: constants.DRIVER_STATUS.FINDEXPENDING,
  },
  {
    label: "switchButton.waiting",
    value: constants.DRIVER_STATUS.PASSIVE,
  },
  {
    label: "switchButton.punished",
    value: constants.DRIVER_STATUS.SUSPENDED,
  },
];

export const DriverStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={driverStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const reservationStatusOptions = [
  {
    label: "statusTypes.open",
    value: constants.RESERVATION_STATUS_TYPE.RESERVED,
  },
  {
    label: "statusTypes.canceled",
    value: constants.RESERVATION_STATUS_TYPE.CANCELED,
  },
  {
    label: "statusTypes.closed",
    value: constants.RESERVATION_STATUS_TYPE.ENDED,
  },
  {
    label: "statusTypes.late",
    value: constants.RESERVATION_STATUS_TYPE.LATE,
  },
  {
    label: "statusTypes.timeexpired",
    value: constants.RESERVATION_STATUS_TYPE.TIMEEXPIRED,
  },
];

export const ReservationStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={reservationStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const accountTypeOptions = [
  {
    label: "statusTypes.personal",
    value: constants.ACCOUNT_TYPE.PERSONAL,
  },
  {
    label: "statusTypes.family",
    value: constants.ACCOUNT_TYPE.FAMILY,
  },
  {
    label: "statusTypes.business",
    value: constants.ACCOUNT_TYPE.BUSINESS,
  },
];

export const AccountTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={accountTypeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.accountType"
  />
);

const accountStatusOptions = [
  {
    label: "statusTypes.active",
    value: constants.DRIVER_STATUS.ACTIVE,
  },
  {
    label: "statusTypes.pending",
    value: constants.DRIVER_STATUS.PENDING,
  },
  {
    label: "statusTypes.passive",
    value: constants.DRIVER_STATUS.PASSIVE,
  },
];

export const AccountStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={accountStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const serviceStatusOptions = [
  {
    label: "serviceTypes.service",
    value: constants.SERVICE_STATUS.SERVICE,
  },
  {
    label: "serviceTypes.washing",
    value: constants.SERVICE_STATUS.WASHING,
  },
  {
    label: "serviceTypes.take_to_service",
    value: constants.SERVICE_STATUS.TAKE_TO_SERVICE,
  },
  {
    label: "serviceTypes.take_from_service",
    value: constants.SERVICE_STATUS.TAKE_FROM_SERVICE,
  },
];

export const ServiceTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={serviceStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="serviceTypes.select"
  />
);

const invoiceItemStatusOptions = [
  {
    label: "statusTypes.paid",
    value: constants.INVOICE_ITEM_PAYMENT_STATUS.PAID,
  },
  {
    label: "statusTypes.unpaid",
    value: constants.INVOICE_ITEM_PAYMENT_STATUS.UNPAID,
  },
  {
    label: "statusTypes.canceled",
    value: constants.INVOICE_ITEM_PAYMENT_STATUS.CANCELLED,
  },
];

export const InvoiceItemStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={invoiceItemStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const timeIntervalSelectorOptions = [
  {
    label: "time.past",
    value: "PAST",
  },
  {
    label: "time.now",
    value: "NOW",
  },
  {
    label: "time.future",
    value: "FUTURE",
  },
];

export const TimeIntervalSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={timeIntervalSelectorOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.time"
  />
);

const applicationTableOptions = [
  {
    label: "affiliate.applicationInfo.application",
    value: constants.APPLICATION_STATUS.APPLICATION,
  },
  {
    label: "affiliate.applicationInfo.pendingApplication",
    value: constants.APPLICATION_STATUS.PENDINGAPPLICATION,
  },
];

export const ApplicationStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={applicationTableOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.applicationStatus"
  />
);

const debtStatusOptions = [
  {
    label: "billingList.debtStatus.indebt",
    value: constants.DEBT_STATUS.DEBTFULL,
  },
  {
    label: "billingList.debtStatus.debtless",
    value: constants.DEBT_STATUS.DEBTLESS,
  },
];

export const DebtStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={debtStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.debtStatus"
  />
);

const affiliateApprovalStatusOptions = [
  {
    label: "statusTypes.approved",
    value: constants.AFFILLIATEACCOUNTSTATUS.APPROVED,
  },
  {
    label: "statusTypes.notapproved",
    value: constants.AFFILLIATEACCOUNTSTATUS.NOTAPPROVED,
  },
];

export const AffiliateApprovalStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={affiliateApprovalStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.approveStatus"
  />
);

const vehicleMainStatusOptions = [
  {
    label: "statusTypes.newVehicleStatuses.active",
    value: constants.VEHICLE_MAIN_STATUS.ACTIVE,
  },
  {
    label: "statusTypes.newVehicleStatuses.passive",
    value: constants.VEHICLE_MAIN_STATUS.PASSIVE,
  },
  {
    label: "statusTypes.newVehicleStatuses.out_of_hand",
    value: constants.VEHICLE_MAIN_STATUS.OUT_OF_HAND,
  },
  {
    label: "statusTypes.newVehicleStatuses.stolen",
    value: constants.VEHICLE_MAIN_STATUS.STOLEN,
  },
];

export const VehicleMainStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleMainStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.vehicleMainStatus"
  />
);

const vehicleStatusOptions = [
  {
    label: "statusTypes.newVehicleStatuses.infleet",
    value: constants.VEHICLE_STATUS.INFLEET,
  },
  {
    label: "statusTypes.newVehicleStatuses.defleet",
    value: constants.VEHICLE_STATUS.DEFLEET,
  },
  {
    label: "statusTypes.newVehicleStatuses.service",
    value: constants.VEHICLE_STATUS.SERVICE,
  },
  {
    label: "statusTypes.newVehicleStatuses.washing",
    value: constants.VEHICLE_STATUS.WASHING,
  },
  {
    label: "statusTypes.newVehicleStatuses.in_transfer",
    value: constants.VEHICLE_STATUS.IN_TRANSFER,
  },
  {
    label: "statusTypes.newVehicleStatuses.aging",
    value: constants.VEHICLE_STATUS.AGING,
  },
  {
    label: "statusTypes.newVehicleStatuses.out_of_hand",
    value: constants.VEHICLE_STATUS.OUT_OF_HAND,
  },
  {
    label: "statusTypes.newVehicleStatuses.corporate",
    value: constants.VEHICLE_STATUS.CORPORATE,
  },
  {
    label: "statusTypes.newVehicleStatuses.rent.active",
    value: constants.VEHICLE_STATUS.ACTIVE,
  },
];

export const VehicleStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.statusVehicle"
  />
);

const vehicleSubStatusOptions = [
  {
    label: "statusTypes.newVehicleStatuses.damage",
    value: constants.VEHICLE_SUB_STATUS.DAMAGE,
  },
  {
    label: "statusTypes.newVehicleStatuses.mechanic",
    value: constants.VEHICLE_SUB_STATUS.MECHANIC,
  },
  {
    label: "statusTypes.newVehicleStatuses.malfunction",
    value: constants.VEHICLE_SUB_STATUS.MALFUNCTION,
  },
  {
    label: "statusTypes.newVehicleStatuses.battery",
    value: constants.VEHICLE_SUB_STATUS.BATTERY,
  },
  {
    label: "statusTypes.newVehicleStatuses.tire",
    value: constants.VEHICLE_SUB_STATUS.TIRE,
  },
  {
    label: "statusTypes.newVehicleStatuses.other_service",
    value: constants.VEHICLE_SUB_STATUS.OTHER_SERVICE,
  },
  {
    label: "statusTypes.newVehicleStatuses.valet_wash",
    value: constants.VEHICLE_SUB_STATUS.VALET_WASH,
  },
  {
    label: "statusTypes.newVehicleStatuses.on_site",
    value: constants.VEHICLE_SUB_STATUS.ON_SITE,
  },
  {
    label: "statusTypes.newVehicleStatuses.sertech",
    value: constants.VEHICLE_SUB_STATUS.SERTECH,
  },
  {
    label: "statusTypes.newVehicleStatuses.otovinn",
    value: constants.VEHICLE_SUB_STATUS.OTOVINN,
  },
  {
    label: "statusTypes.newVehicleStatuses.valet_transfer",
    value: constants.VEHICLE_SUB_STATUS.VALET_TRANSFER,
  },
  {
    label: "statusTypes.newVehicleStatuses.towing",
    value: constants.VEHICLE_SUB_STATUS.TOWING,
  },
  {
    label: "statusTypes.newVehicleStatuses.ud",
    value: constants.VEHICLE_SUB_STATUS.UD,
  },
  {
    label: "statusTypes.newVehicleStatuses.kd",
    value: constants.VEHICLE_SUB_STATUS.KD,
  },
  {
    label: "statusTypes.newVehicleStatuses.second_hand",
    value: constants.VEHICLE_SUB_STATUS.SECOND_HAND,
  },
];

export const VehicleSubStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleSubStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.vehicleSubStatus"
  />
);

const vehicleTireTypeOptions = [
  {
    label: "tireTypes.summer",
    value: "SUMMER",
  },
  {
    label: "tireTypes.winter",
    value: "WINTER",
  },
];
export const VehicleTireTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleTireTypeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.tireType"
  />
);

const vehicleGSMOptions = [
  {
    label: "statusTypes.true",
    value: "true",
  },
  {
    label: "statusTypes.false",
    value: "false",
  },
];
export const VehicleGSMSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleGSMOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.gsm"
  />
);

const vehicleSupplyVoltageOptions = [
  {
    label: "statusTypes.have",
    value: "true",
  },
  {
    label: "statusTypes.donthave",
    value: "false",
  },
];
export const VehicleSupplyVoltageSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleSupplyVoltageOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.battery"
  />
);

const vehicleRegionOptions = [
  {
    label: "statusTypes.in_zone",
    value: "IN_ZONE",
  },
  {
    label: "statusTypes.out_zone",
    value: "OUT_ZONE",
  },
  {
    label: "statusTypes.in_prohibited",
    value: "IN_PROHIBITED",
  },
];
export const VehicleRegionSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleRegionOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.region"
  />
);

const vehicleDataFetchOptions = [
  {
    label: "1 günden fazla veri almayanlar",
    value: "no_data",
  },
  {
    label: "Son 1 gün içinde veri alanlar",
    value: "data",
  },
];
export const VehicleDataFetchSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={vehicleDataFetchOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.dataFetch"
  />
);

const hgbStatusOptions = [
  {
    label: "statusTypes.active",
    value: constants.HGB_STATUS.ACTIVE,
  },
  {
    label: "statusTypes.cancelled",
    value: constants.HGB_STATUS.CANCELLED,
  },
];

export const HgbStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={hgbStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.hgbStatus"
  />
);

const usageTypeOptions = [
  {
    label: "carModes.roundtrip",
    value: constants.CAR_MODES.ROUNDTRIP,
  },
  {
    label: "carModes.freefloating",
    value: constants.CAR_MODES.FREEFLOATING,
  },
  // {
  //   label: "carModes.all",
  //   value: constants.CAR_MODES.ALL,
  // },
];

export const VehicleUsageTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={usageTypeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.vehicleUsageMode"
  />
);

const valetRoleOptions = [
  {
    label: "statusTypes.standard",
    value: constants.VALET_ROLES.STANDARD,
  },
  {
    label: "statusTypes.super",
    value: constants.VALET_ROLES.SUPER,
  },
];

export const ValetRoleSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={valetRoleOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.valetRole"
  />
);

const valetRelocationStateOptions = [
  {
    label: "statusTypes.active",
    value: constants.STATUS.ACTIVE,
  },
  {
    label: "statusTypes.passive",
    value: constants.STATUS.PASSIVE,
  },
];

export const ValetRelocationStateSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={valetRelocationStateOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.valetRelocationStatus"
  />
);

const valetRelocationShiftOptions = [
  {
    label: "statusTypes.valet_relocation_first_shift",
    value: 1,
  },
  {
    label: "statusTypes.valet_relocation_second_shift",
    value: 2,
  },
];

export const ValetRelocationShiftSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={valetRelocationShiftOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.valetRelocationShift"
  />
);

const taskStatusOptions = [
  {
    label: "statusTypes.pending",
    value: constants.TASK_STATUSES.PENDING,
  },
  {
    label: "statusTypes.planned",
    value: constants.TASK_STATUSES.PLANNED,
  },
  {
    label: "statusTypes.started",
    value: constants.TASK_STATUSES.STARTED,
  },
  {
    label: "statusTypes.ended",
    value: constants.TASK_STATUSES.ENDED,
  },
  {
    label: "statusTypes.canceled",
    value: constants.TASK_STATUSES.CANCELED,
  },
];

export const TaskStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={taskStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const taskCategoryOptions = [
  {
    label: "statusTypes.service",
    value: constants.TASK_CATEGORIES.SERVICE,
  },
  {
    label: "statusTypes.to_service",
    value: constants.TASK_CATEGORIES.TO_SERVICE,
  },
  {
    label: "statusTypes.from_service",
    value: constants.TASK_CATEGORIES.FROM_SERVICE,
  },
  {
    label: "statusTypes.washing",
    value: constants.TASK_CATEGORIES.WASHING,
  },
  {
    label: "statusTypes.transport",
    value: constants.TASK_CATEGORIES.TRANSPORT,
  },
  {
    label: "statusTypes.adhoc",
    value: constants.TASK_CATEGORIES.ADHOC,
  },
  {
    label: "statusTypes.tire_repair",
    value: constants.TASK_CATEGORIES.TIRE_REPAIR,
  },
  {
    label: "statusTypes.equipment_control",
    value: constants.TASK_CATEGORIES.EQUIPMENT_CONTROL,
  },
  {
    label: "statusTypes.damage_control",
    value: constants.TASK_CATEGORIES.DAMAGE_CONTROL,
  },
  {
    label: "statusTypes.test_drive",
    value: constants.TASK_CATEGORIES.TEST_DRIVE,
  },
  {
    label: "statusTypes.device_control",
    value: constants.TASK_CATEGORIES.DEVICE_CONTROL,
  },
  {
    label: "statusTypes.failure_control",
    value: constants.TASK_CATEGORIES.FAILURE_CONTROL,
  },
];

export const TaskCategorySelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={taskCategoryOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.category"
  />
);

const valetStatusOptions = [
  {
    label: "statusTypes.active",
    value: constants.VALET_STATUSES.ACTIVE,
  },
  {
    label: "statusTypes.passive",
    value: constants.VALET_STATUSES.PASSIVE,
  },
  {
    label: "statusTypes.on_duty",
    value: constants.VALET_STATUSES.ON_DUTY,
  },
  {
    label: "statusTypes.off_duty",
    value: constants.VALET_STATUSES.OFF_DUTY,
  },
  {
    label: "statusTypes.on_break",
    value: constants.VALET_STATUSES.ON_BREAK,
  },
];

export const ValetStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={valetStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.status"
  />
);

const taskPriorityOptions = [
  {
    label: "statusTypes.moderate",
    value: constants.TASK_PRIORITIES.MODERATE,
  },
  {
    label: "statusTypes.urgent",
    value: constants.TASK_PRIORITIES.URGENT,
  },
];

export const TaskPrioritySelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={taskPriorityOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.priority"
  />
);

const notificationReceiverOptions = [
  {
    label: "notificationTable.driver",
    value: constants.ENTITY.DRIVER,
  },
  {
    label: "notificationTable.valet",
    value: constants.ENTITY.VALET,
  },
];

export const NotificationReceiverSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={notificationReceiverOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.notificationReceiver"
  />
);

const serviceWashingSubTaskOptions = [
  {
    label: "serviceSubtask.WASHING",
    value: constants.WASHING_SUB_TASKS.WASHING,
  },
  {
    label: "serviceSubtask.WASHING_AT_PLACE",
    value: constants.WASHING_SUB_TASKS.WASHING_AT_PLACE,
  },
  {
    label: "serviceSubtask.FIELD_CREW",
    value: constants.WASHING_SUB_TASKS.FIELD_CREW,
  },
  {
    label: "serviceSubtask.OTOVINN",
    value: constants.WASHING_SUB_TASKS.OTOVINN,
  },
  {
    label: "serviceSubtask.VALET",
    value: constants.WASHING_SUB_TASKS.VALET,
  },
];

const serviceServiceSubTaskOptions = [
  {
    label: "serviceSubtask.FAULT",
    value: constants.SERVICE_SUB_TASKS.FAULT,
  },
  {
    label: "serviceSubtask.DAMAGE",
    value: constants.SERVICE_SUB_TASKS.DAMAGE,
  },
  {
    label: "serviceSubtask.MAINTENANCE",
    value: constants.SERVICE_SUB_TASKS.MAINTENANCE,
  },
  {
    label: "serviceSubtask.MOBILIZ",
    value: constants.SERVICE_SUB_TASKS.MOBILIZ,
  },
  {
    label: "serviceSubtask.TIRE",
    value: constants.SERVICE_SUB_TASKS.TIRE,
  },
  {
    label: "serviceSubtask.BATTERY",
    value: constants.SERVICE_SUB_TASKS.BATTERY,
  },
  {
    label: "serviceSubtask.TRANSPORT",
    value: constants.SERVICE_SUB_TASKS.TRANSPORT,
  },
  {
    label: "serviceSubtask.TIRE_CHANGE",
    value: constants.SERVICE_SUB_TASKS.TIRE_CHANGE,
  },
  {
    label: "serviceSubtask.OTHER",
    value: constants.SERVICE_SUB_TASKS.OTHER,
  },
];

export const ServiceSubtaskSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  type,
}) => (
  <CustomSimpleSelector
    options={
      !type
        ? [
            ...serviceServiceSubTaskOptions,
            ...serviceWashingSubTaskOptions,
          ]
        : type === "SERVICE"
        ? serviceServiceSubTaskOptions
        : serviceWashingSubTaskOptions
    }
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.subtask"
  />
);

const receiverGroupOptions = [
  {
    label: "receiverGroup.NO_ACTION",
    value: constants.RECEIVER_GROUP.NO_ACTION,
  },
  {
    label: "receiverGroup.NO_LICENSE",
    value: constants.RECEIVER_GROUP.NO_LICENSE,
  },
  {
    label: "receiverGroup.NO_PAYMENT",
    value: constants.RECEIVER_GROUP.NO_PAYMENT,
  },
  {
    label: "receiverGroup.INVALID_KKB",
    value: constants.RECEIVER_GROUP.INVALID_KKB,
  },
  {
    label: "receiverGroup.ALL_PENDING",
    value: constants.RECEIVER_GROUP.ALL_PENDING,
  },
  {
    label: "receiverGroup.LAST_RESERVATION",
    value: constants.RECEIVER_GROUP.LAST_RESERVATION,
  },
];

export const ReceiverGroupSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={receiverGroupOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.group"
  />
);

const locationTypeOptions = [
  {
    label: "locationType.mall",
    value: constants.LOCATION_TYPES.MALL,
  },
  {
    label: "locationType.university",
    value: constants.LOCATION_TYPES.UNIVERSITY,
  },
];

export const LocationTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={locationTypeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.locationType"
  />
);

const alarmStatusOptions = [
  {
    label: "statusTypes.active",
    value: constants.ALARM_STATUS.ACTIVE,
  },
  {
    label: "statusTypes.inprogress",
    value: constants.ALARM_STATUS.INPROGRESS,
  },
  {
    label: "statusTypes.closed",
    value: constants.ALARM_STATUS.CLOSED,
  },
];

export const AlarmStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={alarmStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.alarmStatus"
  />
);

const privateLocationOptions = [
  {
    label: "locationDetails.private",
    value: true,
  },
  {
    label: "locationDetails.public",
    value: false,
  },
];

export const PrivateLocationSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={privateLocationOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.privacyLocation"
  />
);

const newEmailSmsTemplateContentTitleOptions = [
  {
    label: "sendMailOrSms.driverName",
    value: constants.TEMPLATE_CONTENT_TITLE.NAME,
  },
  {
    label: "sendMailOrSms.driverSurname",
    value: constants.TEMPLATE_CONTENT_TITLE.SURNAME,
  },
];

export const NewEmailSmsTemplateContentTitleSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={newEmailSmsTemplateContentTitleOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.driverInfo"
  />
);

export const DateRangeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  className,
  selectedIndex,
  options,
}) => (
  <CustomSimpleSelector
    options={options}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    className={className}
    selectedIndex={selectedIndex}
  />
);

const permissionStatusOptions = [
  {
    label: "driverTable.free",
    value: constants.PERMISSION_STATUS.FREE,
  },
  {
    label: "driverTable.gray",
    value: constants.PERMISSION_STATUS.GRAY_LIST,
  },
  {
    label: "driverTable.black",
    value: constants.PERMISSION_STATUS.BLACK_LIST,
  },
];

export const PermissionStatusSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={permissionStatusOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.grayBlackList"
  />
);

const amenitiesOptions = t => {
  let ret = [
    {
      label: "amenities.back_camera",
      value: t("amenities.back_camera"),
    },
    {
      label: "amenities.sensor",
      value: t("amenities.sensor"),
    },
    {
      label: "amenities.bluetooth",
      value: t("amenities.bluetooth"),
    },
    {
      label: "amenities.cruise_control",
      value: t("amenities.cruise_control"),
    },
    {
      label: "amenities.car_play_android",
      value: t("amenities.car_play_android"),
    },
    {
      label: "amenities.navigation",
      value: t("amenities.navigation"),
    },
    {
      label: "amenities.sun_roof",
      value: t("amenities.sun_roof"),
    },
    {
      label: "amenities.road_pass_service",
      value: t("amenities.road_pass_service"),
    },
    {
      label: "amenities.electric_car",
      value: t("amenities.electric_car"),
    },
    {
      label: "amenities.hybrid",
      value: t("amenities.hybrid"),
    },
    {
      label: "amenities.heated_seats",
      value: t("amenities.heated_seats"),
    },
    {
      label: "amenities.winter_tire",
      value: t("amenities.winter_tire"),
    },
    {
      label: "amenities.usb",
      value: t("amenities.usb"),
    },
  ];

  return ret;
};

export const AmenitiesSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  multiple,
}) => {
  const { t } = useTranslation();

  return (
    <CustomSimpleSelector
      options={amenitiesOptions(t)}
      onChange={onChange}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      placeholder="input.placeholder.amenities"
      multiple={multiple}
    />
  );
};

const riskDegreeOptions = [
  {
    label: "driverLeagueTable.riskDegrees.safe",
    value: "SAFE",
  },
  {
    label: "driverLeagueTable.riskDegrees.medium",
    value: "MEDIUM",
  },
  {
    label: "driverLeagueTable.riskDegrees.high",
    value: "HIGH",
  },
];

export const RiskDegreeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
  placeholder,
}) => (
  <CustomSimpleSelector
    options={riskDegreeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder={placeholder || "input.placeholder.riskDegree"}
  />
);

const violationTypeOptions = [
  {
    label: "violations.overSpeeding",
    value: 0,
  },
  {
    label: "violations.hardBraking",
    value: 1,
  },
  {
    label: "violations.rapidAcceleration",
    value: 2,
  },
  {
    label: "violations.sharpCornering",
    value: 3,
  },
];

export const ViolationTypeSelector = ({
  onChange,
  value,
  disabled,
  fullWidth,
}) => (
  <CustomSimpleSelector
    options={violationTypeOptions}
    onChange={onChange}
    value={value}
    disabled={disabled}
    fullWidth={fullWidth}
    placeholder="input.placeholder.violationType"
  />
);

export default CustomSimpleSelector;
