import React from "react";
import AutoCompleteDropDown from "app/ui/Atoms/AutoCompleteDropDown";
import TextInput from "app/ui/Atoms/TextInput";
import i18n from "app/i18n";
import { useFetchSalesRepresentatives } from "services/gql/salesRepresentatives";

const AutoCompleteSalesRepresentatives = ({
  setInputValue,
  onSelect,
  value,
  error,
  helperText,
}) => {
  // return <div style={{}}>Bekleyin..</div>;
  const { data, loading } = useFetchSalesRepresentatives();

  if (loading) {
    return (
      <TextInput
        label={i18n.t("common.loading")}
        variant="outlined"
        disabled
      />
    );
  }

  return (
    <AutoCompleteDropDown
      value={value && value}
      inputValue={value && value}
      width="100%"
      onSelect={e => onSelect(e)}
      setInputValue={e => setInputValue(e)}
      data={
        data?.salesRepresentatives &&
        data.salesRepresentatives?.salesReps
          ? data.salesRepresentatives?.salesReps
          : []
      }
      showKey="name"
      type="default"
      error={error}
      helperText={helperText}
    />
  );
};

export default AutoCompleteSalesRepresentatives;
