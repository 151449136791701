/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const RatePlanList = MatxLoadable({
  loader: () => import("../views/rates"),
});

const RatePlanDetail = MatxLoadable({
  loader: () => import("../views/rates/ratePlanDetail"),
});

const rateRoutes = [
  {
    path: "/rates/:id",
    component: RatePlanDetail,
    permission: ["manager"]
  },
  {
    path: "/rates",
    component: RatePlanList,
    permission: ["manager"]
  },
];

export default rateRoutes;
