import React from "react";

const GqlFormController = ({
  FormComponent,
  useMutation,
  parseFormData = d => d,
  previousFormData,
  onFail,
  onSuccess,
}) => {
  const [mutation, mutationState] = useMutation();

  // console.log("prevform",previousFormData);
  const onSubmit = async formData => {
    try {
      const result = await mutation(parseFormData(formData));
      if (onSuccess) {
        onSuccess(result, formData);
      }
    } catch (err) {
      if (onFail) {
        onFail(err, formData);
      }
    }
  };
  return (
    <FormComponent
      onSubmit={onSubmit}
      loading={mutationState.loading}
      previousData={previousFormData || {}}
    />
  );
};

export default GqlFormController;
