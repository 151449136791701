import { useQuery, gql } from "@apollo/client";

const LIST_TAX_OFFICES = gql`
  query LIST_TAXOFFICES {
    taxOffices {
      offices
    }
  }
`;

export function useFetchTaxOffices() {
  const { data, error, loading } = useQuery(LIST_TAX_OFFICES);
  return {
    data,
    error,
    loading,
  };
}
