/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const NotificationHandler = MatxLoadable({
  loader: () => import("../ui/Pages/ApproveNotificationRequest"),
});

const DriverDeleteHandler = MatxLoadable({
  loader: () => import("../ui/Pages/ApproveDriverDeleteRequest"),
});

const DeviceChangeHandler = MatxLoadable({
  loader: () =>
    import("../ui/Pages/ApproveDriverDeviceChangeRequest"),
});

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const notificationHandlerRoutes = [
  {
    path: "/approve_notification",
    component: NotificationHandler,
    settings,
    permission: ["manager"],
  },
  {
    path: "/approve_delete_drivers",
    component: DriverDeleteHandler,
    settings,
    permission: ["manager"],
  },
  {
    path: "/approve_driver_device_change",
    component: DeviceChangeHandler,
    settings,
    permission: ["manager"],
  },
];

export default notificationHandlerRoutes;
