import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  from,
} from "@apollo/client";
import React from "react";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { RetryLink } from "@apollo/client/link/retry";
import config from "common/config";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.error(graphQLErrors);
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError.message}`);
  }
});

const httpLink = createHttpLink({
  uri: config.hasura.rs,
});

const wsClient = new SubscriptionClient(config.hasura.ws, {
  reconnect: true,
});

const wsLink = new WebSocketLink(wsClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    const isMutation =
      operation &&
      operation.query &&
      operation.query.definitions &&
      Array.isArray(operation.query.definitions) &&
      operation.query.definitions.some(
        def =>
          def.kind === "OperationDefinition" &&
          def.operation === "mutation"
      );
    if (isMutation) {
      return !!error && count < 25;
    }
    return !!error && count < 6;
  },
});

export const client = new ApolloClient({
  link: from([errorLink, retryLink, splitLink]),
  cache: new InMemoryCache({
    possibleTypes: {
      // UpdateReservationCheckResult: [
      //   "InvalidAccount",
      //   "InvalidInterval",
      //   "InvalidReservation",
      //   "InvalidRole",
      //   "InvalidVehicle",
      //   "UpdateReservationCheckSuccess",
      //   "VehicleNotAvailable",
      // ],
      Price: [
        "InvalidVehicle",
        "InvalidAccount",
        "InvalidInterval",
        "GetPriceSuccess",
      ],
    },
  }),
});

const AnonymApollo = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AnonymApollo;
