import React, { useRef, useImperativeHandle } from "react";

import {
  FormControl,
  FormLabel,
  TextField,
  Divider,
  Paper,
  Box,
  Button,
  FormHelperText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button2 from "app/ui/Atoms/Button";

import Text from "app/ui/Atoms/Text";
import { useTranslation } from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import i18n from "app/i18n";
import * as validation from "app/utils/validation";
import DropZone from "app/ui/Molecule/DropZone";
import {
  TicketEventCategorySelector,
  TicketSourceSelector,
} from "app/ui/Organism/CustomGqlSelector";
import TicketArrayInput from "app/ui/Organism/TicketArrayInput";
import OverlayLoader from "app/ui/Atoms/OverlayLoader";
import constants from "common/constants";
import { ReservationTypeSelector } from "./CustomSimpleSelector";
import { TicketEventSubcategorySelector } from "./CustomGqlSelector";

const useStyles = makeStyles(() => ({
  dropzoneFiles: {
    border: "solid 1px #ddd",
    minHeight: "64px",
    borderRadius: 8,
    marginTop: 8,
    padding: 8,
    fontSize: 11,
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const schema = yup.object().shape({
  source: yup.object().required(i18n.t("validation.required")),
  description: yup.string().required(i18n.t("validation.required")),
  tickets: yup.array(validation.ticketField),
  fleetType: validation.fleetTypeField,
});

const getTicketsFromEventCategory = (category, defaultValues) =>
  category.ticket_templates.map(
    ({
      entity,
      category_id: categoryId,
      category_name: categoryName,
    }) => {
      const output = {
        entity,
        category: {
          id: categoryId,
          name: categoryName,
        },
      };
      if (defaultValues && defaultValues[entity]) {
        output[entity.toLowerCase()] = defaultValues[entity];
      }
      if (entity === constants.ENTITY.VEHICLE && defaultValues) {
        output[constants.ENTITY.ACCOUNT.toLowerCase()] = defaultValues[constants.ENTITY.ACCOUNT];
        output[constants.ENTITY.DRIVER.toLowerCase()] = defaultValues[constants.ENTITY.DRIVER];
      }
      output.assignee =
          entity &&
          (entity === constants.ENTITY.VEHICLE ||
            entity === constants.ENTITY.LOCATION || entity === constants.ENTITY.VALET)
            ? constants.ASSIGNEE.OPERATION
            : entity === constants.ENTITY.IUGO ? constants.ASSIGNEE.IUGO : constants.ASSIGNEE.MEMBER;
      output.priority = constants.PRIORITY.PRIMARY;
      output.status = constants.STATUS.ACTIVE;
      return output;
    }
  );

const TicketEventAddForm = React.forwardRef(
  ({ onSubmit, loading, defaultEntityValue, reservationId, usageType }, ref) => {
    const classes = useStyles();
    const filesRef = useRef();
    const { t } = useTranslation();
    const {
      handleSubmit,
      control,
      errors,
      register,
      watch,
      setValue,
      getValues,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const watchTicketEventCategory = watch("category", null);
    const watchTicketEventSubcategory = watch("subcategory", null);
    const watchFiles = watch("files", []);

    useImperativeHandle(ref, () => ({
      uploadFiles(eventId) {
        // console.log("EVENT ID -2 :", eventId);
        if (filesRef.current) {
          if (getValues("files")) {
            filesRef.current.uploadFiles(eventId, getValues("files"));
          }
        }
      },
    }));

    return (
      <form onSubmit={handleSubmit((input) => onSubmit({...input, reservationId:reservationId}))}>
        <FormControl fullWidth margin="normal">
          <Controller
            name="source"
            render={props => (
              <TicketSourceSelector
                {...props}
                onChange={ticketSource => {
                  setValue("source", ticketSource);
                  props.onChange(ticketSource);
                }}
              />
            )}
            control={control}
            defaultValue={null}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Controller
            name="category"
            render={props => (
              <TicketEventCategorySelector
                {...props}
                onChange={ticketEventCategory => {
                  /* if (!ticketEventCategory) {
                    setValue("tickets", []);
                    return;
                  }
                  setValue(
                    "tickets",
                    getTicketsFromEventCategory(
                      ticketEventCategory,
                      defaultEntityValue
                    )
                  ); */
                  props.onChange(ticketEventCategory);
                  if (!ticketEventCategory)
                    setValue("subcategory", null);
                }}
              />
            )}
            control={control}
            defaultValue={null}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Controller
            name="subcategory"
            render={props => (
              <TicketEventSubcategorySelector
                {...props}
                ticketEventCategoryID={watchTicketEventCategory?.id}
                onChange={ticketEventSubcategory => {
                  if (!ticketEventSubcategory) {
                    setValue("tickets", []);
                    setValue("subcategory", null);
                    return;
                  }
                  setValue(
                    "tickets",
                    getTicketsFromEventCategory(
                      ticketEventSubcategory,
                      defaultEntityValue
                    )
                  );
                  props.onChange(ticketEventSubcategory);
                }}
                disabled={!watchTicketEventCategory}
              />
            )}
            control={control}
            defaultValue={null}
          />
        </FormControl>
        {watchTicketEventSubcategory && (
          <FormControl fullWidth margin="normal">
            <Paper variant="outlined">
              <Box p={1}>
                <FormLabel>{t("input.clue")}</FormLabel>
                <Divider />
                {watchTicketEventSubcategory.description}
              </Box>
            </Paper>
          </FormControl>
        )}
        <FormControl fullWidth margin="normal" error={!!errors?.fleetType}>
          <Controller
            name="fleetType"
            render={props => (<>
              <ReservationTypeSelector
                {...props}
                onChange={fleetType => {
                  setValue("fleetType", fleetType);
                  props.onChange(fleetType);
                }}
              />
              {errors?.fleetType && (
                <FormHelperText>{errors?.fleetType.message}</FormHelperText>
              )}
              </>
            )}
            control={control}
            defaultValue={usageType}
          />
        </FormControl>
        {watchTicketEventCategory && (
          <FormControl fullWidth margin="normal">
            <Paper variant="outlined">
              <Box p={1}>
                <FormLabel>{t("input.helperText")}</FormLabel>
                <Divider />
                {watchTicketEventCategory.description}
              </Box>
            </Paper>
          </FormControl>
        )}
        <FormControl fullWidth margin="normal">
          <TextField
            inputProps={{ ref: register, name: "description" }}
            multiline
            rows={4}
            error={errors.description}
            helperText={errors.description?.message}
            placeholder={t("input.placeholder.description")}
          />
        </FormControl>
        <Controller
          name="files"
          control={control}
          render={() => (
            <Box>
              <Text weight="bold">{i18n.t("common.addDoc")}</Text>
              <DropZone
                ref={filesRef}
                bucket="event"
                keepUploadButton
                avoidImmediateSave
                acceptTypes={["Image/*", ".pdf"]}
                onSaved={d => {
                  // console.log("Image Saved:", d);
                  // props.onChange(() => [...watchFiles, d])
                  // console.log("WATCH Files:", watchFiles, getValues("files"), d)

                  let f = getValues("files");
                  if (f) {
                    f = [...f, d];
                  } else {
                    f = [d];
                  }

                  setValue("files", f);
                }}
                multiple
                width="100%"
                height={64}
              />
              {console.log("watchFiles:", watchFiles, filesRef)}

              <div className={classes.dropzoneFiles}>
                {getValues("files") &&
                  getValues("files").map((d, i) => {
                    return (
                      <div className={classes.fileItem}>
                        <div>{`${i + 1}. ${d.name}`}</div>
                        <Button2
                          type="iconButton"
                          icon="delete"
                          iconSize={16}
                          onClick={() => {
                            const r = getValues("files");
                            setValue(
                              "files",
                              r.filter((c, j) => i !== j)
                            );
                          }}
                          iconButtonStyle={{
                            padding: 0,
                            marginRight: 16,
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </Box>
          )}
        />
        <Controller
          name="tickets"
          render={props => {
            //console.log("itemprops",props);
            return(
            <TicketArrayInput {...props} errors={errors.tickets} />
          )}}
          control={control}
          // defaultValue={defaultEntityValue}
          defaultValue={[]}
        />
        <FormControl fullWidth margin="normal">
          <OverlayLoader loading={loading}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !watchTicketEventSubcategory}
            >
              {t("input.submitButton")}
            </Button>
          </OverlayLoader>
        </FormControl>
      </form>
    );
  }
);

export default TicketEventAddForm;
