/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListRoles = MatxLoadable({
  loader: () => import("../views/roles"),
});

const rolesRoutes = [
  {
    path: "/roles/list",
    component: ListRoles,
    permission: ["manager"]
  },
];

export default rolesRoutes;
