/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const LocationList = MatxLoadable({
  loader: () => import("../views/locations/"),
});

const LocationDetail = MatxLoadable({
  loader: () => import("../views/locations/locationDetail"),
});

const locationRoutes = [
  {
    path: "/locations/dashboard",
    component: LocationList,
    permission: ["manager"]
  },
  {
    path: "/locations/:id",
    component: LocationDetail,
    permission: ["manager"]
  },
];

export default locationRoutes;
