export default {
  PRIORITY: {
    PRIMARY: 1,
    SECONDARY: 2,
    INVALID: -1,
  },
  ASSIGNEE: {
    OPERATION: "OPERATION_SERVICES",
    MEMBER: "MEMBER_SERVICES",
    IUGO: "IUGO",
    SPECIFIC: "SPECIFIC",
  },
  ENTITY: {
    ACCOUNT: "ACCOUNT",
    VALET: "VALET",
    DRIVER: "DRIVER",
    LOCATION: "LOCATION",
    VEHICLE: "VEHICLE",
    RESERVATION: "RESERVATION",
    IUGO: "IUGO",
  },
  STATUS: {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    PASSIVE: "PASSIVE",
    UNKNOWN: "UNKNOWN",
    OPEN: "OPEN",
    SOLVED: "SOLVED",
    SOLVEDHAPPY: "SOLVEDHAPPY",
    IMMEDIATE: "IMMEDIATE",
    POSTIMMEDIATE: "POSTIMMEDIATE",
    NOBILL: "NOBILL",
    PERIODIC: "PERIODIC",
    SERVICE: "SERVICE",
  },
  AFFILLIATEACCOUNTSTATUS: {
    APPROVED: "APPROVED",
    NOTAPPROVED: "NOTAPPROVED",
  },
  SORT: {
    STANDART: "STANDART",
    NAME: "NAME",
    LOCATION: "LOCATION",
  },
  INVOICE: {
    TOLLPASS: "TOLL",
    TRAFFICPENALTY: "TRAFFIC_PENALTY",
    WAIVER: "WAIVER",
    DAMAGE: "DAMAGE",
    RESERVATIONPENALTY: "RESERVATION_PENALTY",
    EXTRAKM: "EXTRA_KM",
    BASE: "RESERVATION",
    RATEPLAN: "RATE_PLAN",
    WAIVERPERIODIC: "WAIVER_PERIODIC",
    SERVICECOST: "SERVICECOST",
    RESERVATIONPREPAYMENT: "PREPAYMENT"
  },
  INVOICE_TYPE: {
    RESERVATION: "Reservation_Bill",
    ADJUSTMENT: "Adj_Bill",
    MEMBERSHIP: "Membership_Bill",
    INVOICE: "INVOICE",
    REFUND: "REFUND",
    MEMBERSHIP: "Membership_Bill",
  },
  INVOICE_TYPE_NEW: {
    RESERVATION: "RESERVATION",
    ADJUSTMENT: "ADJUSTMENT",
    MEMBERSHIP: "MEMBERSHIP",
  },
  DEBT_STATUS: {
    DEBTFULL: "IN_DEBT",
    DEBTLESS: "DEBTLESS",
    FORCED_DEBTLESS: "PERMITTED_IN_DEBT",
  },
  ITEM_TYPE: {
    PAYMENT: "PAYMENT",
    RETURN: "RETURN",
    CANCELLED: "CANCELLED",
  },
  RESERVATION_STATUS_TYPE: {
    RESERVED: "RESERVED",
    ON_HOLD: "ON_HOLD",
    CANCELED: "CANCELED",
    LATE: "LATE",
    TIMEEXPIRED: "TIMEEXPIRED",
    ENDED: "ENDED",
  },
  TICKET_ACTION_TYPE: {
    MESSAGE: "MESSAGE",
    FILE: "FILE",
    STATUS: "STATUS",
    ASSIGN: "ASSIGN",
    PRIORITY: "PRIORITY",
    CHANGEASSIGNED: "CHANGEASSIGNED",
    CATEGORY: "CATEGORY",
  },
  BUCKET: {
    TICKET: "ticket",
  },
  BILL_ACTION_BUTTON_STATE: {
    REFUND: "REFUND",
    CHARGE: "CHARGE",
    CANCELLED: "CANCELLED",
    NOACTION: "NOACTION",
  },
  DRIVER_STATUS: {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    FINDEXINVALID: "FINDEXINVALID",
    PASSIVE: "PASSIVE",
    SUSPENDED: "SUSPENDED",
    FINDEXPENDING: "FINDEXPENDING"
  },
  ACCOUNT_TYPE: {
    PERSONAL: "PERSONAL",
    FAMILY: "FAMILY",
    BUSINESS: "BUSINESS",
  },
  SERVICE_STATUS: {
    SERVICE: "SERVICE",
    WASHING: "WASHING",
    TAKE_FROM_SERVICE: "TAKE_FROM_SERVICE",
    TAKE_TO_SERVICE: "TAKE_TO_SERVICE"
  },
  INVOICE_ITEM_PAYMENT_STATUS: {
    PAID: "PAID",
    UNPAID: "UNPAID",
    CANCELLED: "CANCELLED",
  },
  APPLICATION_STATUS: {
    APPLICATION: "APPLICATION",
    PENDINGAPPLICATION: "PENDINGAPPLICATION",
  },
  CAR_MODES: {
    ROUNDTRIP: "ROUNDTRIP",
    FREEFLOATING: "FREEFLOATING",
    ALL: "ALL",
    ONEWAY: "ONEWAY"
  },
  VEHICLE_MAIN_STATUS: {
    ACTIVE: "ACTIVE",
    PASSIVE: "PASSIVE",
    OUT_OF_HAND: "OUT_OF_HAND",
    STOLEN: "STOLEN",
  },
  VEHICLE_STATUS: {
    INFLEET: "INFLEET",
    DEFLEET: "DEFLEET",
    ACTIVE: "ACTIVE",
    SERVICE: "SERVICE",
    WASHING: "WASHING",
    IN_TRANSFER: "IN_TRANSFER",
    AGING: "AGING",
    OUT_OF_HAND: "OUT_OF_HAND",
    CORPORATE: "CORPORATE",
  },
  VEHICLE_SUB_STATUS: {
    DAMAGE: "DAMAGE",
    MECHANIC: "MECHANIC",
    MALFUNCTION: "MALFUNCTION",
    BATTERY: "BATTERY",
    TIRE: "TIRE",
    OTHER_SERVICE: "OTHER_SERVICE",
    VALET_WASH: "VALET_WASH",
    VALET_TRANSFER: "VALET_TRANSFER",
    ON_SITE: "ON_SITE",
    SERTECH: "SERTECH",
    OTOVINN: "OTOVINN",
    TOWING: "TOWING",
    UD: "UD",
    KD: "KD",
    SECOND_HAND: "SECOND_HAND",
  },
  HGB_STATUS: {
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
  },
  TEMPLATE_CONTENT_TITLE: {
    NAME: "{NAME}",
    SURNAME: "{SURNAME}",
  },
  NOTIFICATION_TYPES: {
    SMS:"SMS",
    MAIL:"EMAIL",
    NOTIFICATION: "NOTIFICATION"
  },
  VALET_ROLES: {
    STANDARD: "STANDARD",
    SUPER: "SUPER"
  },
  VALET_STATUSES: {
    ACTIVE: "ACTIVE",
    PASSIVE: "PASSIVE",
    ON_DUTY: "ON_DUTY",
    OFF_DUTY: "OFF_DUTY",
    ON_BREAK: "ON_BREAK"
  },
  TASK_CATEGORIES: {
    SERVICE: "SERVICE",
    WASHING: "WASHING",
    TRANSPORT: "TRANSPORT",
    ADHOC: "ADHOC",
    TO_SERVICE: "TO_SERVICE",
    FROM_SERVICE: "FROM_SERVICE",
    TIRE_REPAIR: "TIRE_REPAIR",
    EQUIPMENT_CONTROL: "EQUIPMENT_CONTROL",
    DAMAGE_CONTROL: "DAMAGE_CONTROL",
    TEST_DRIVE: "TEST_DRIVE",
    DEVICE_CONTROL: "DEVICE_CONTROL",
    FAILURE_CONTROL: "FAILURE_CONTROL",
  },
  TASK_STATUSES: {
    PENDING: "PENDING",
    PLANNED: "PLANNED",
    STARTED: "STARTED",
    ENDED: "ENDED",
    CANCELED: "CANCELED"
  },
  TASK_PRIORITIES: {
    MODERATE: "MODERATE",
    URGENT: "URGENT"
  },
  RECEIVER_GROUP: {
    ACTIVE_ACCOUNT: "ACTIVE_ACCOUNT",
    NO_ACTION: "NO_ACTION",
    NO_LICENSE: "NO_LICENSE",
    NO_PAYMENT: "NO_PAYMENT",
    LAST_RESERVATION: "LAST_RESERVATION",
    INVALID_KKB: "INVALID_KKB",
    ALL_PENDING: "ALL_PENDING",
    SELECTED_PEOPLE: "SELECTED_PEOPLE"
  },
  COLORS_RANDOM: [
		'#FF6633',
		'#FF33FF',
		'#80B300',
		'#00B3E6',
		'#E6B333',
		'#3366E6',
		'#999966',
		'#99FF99',
		'#B34D4D',
		'#809900',
		'#6680B3',
		'#66991A',
		'#FF99E6',
		'#CCFF1A',
		'#FF1A66',
		'#E6331A',
		'#33FFCC',
		'#66994D',
		'#B366CC',
		'#4D8000',
		'#B33300',
		'#CC80CC',
		'#66664D',
		'#991AFF',
		'#E666FF',
		'#4DB3FF',
		'#1AB399',
		'#E666B3',
		'#33991A',
		'#CC9999',
		'#B3B31A',
		'#00E680',
		'#4D8066',
		'#809980',
		'#E6FF80',
		'#1AFF33',
		'#999933',
		'#FF3380',
		'#CCCC00',
		'#66E64D',
		'#4D80CC',
		'#9900B3',
		'#E64D66',
		'#4DB380',
		'#FF4D4D',
		'#E6B3B3',
		'#99E6E6',
		'#6666FF',
		'#FFB399',
		'#FFFF99'
	],
  VEHICLE_GEO_STATUSES: {
    IN_ZONE: "IN_ZONE",
    OUT_ZONE: "OUT_ZONE",
    IN_PROHIBITED: "IN_PROHIBITED"
  },
  WASHING_SUB_TASKS: {
    WASHING: "WASHING",
    WASHING_AT_PLACE: "WASHING_AT_PLACE",
    FIELD_CREW: "FIELD_CREW",
    OTOVINN: "OTOVINN",
    VALET: "VALET"
  },
  SERVICE_SUB_TASKS: {
    FAULT: "FAULT",
    DAMAGE: "DAMAGE",
    MAINTENANCE: "MAINTENANCE",
    MOBILIZ: "MOBILIZ",
    TIRE: "TIRE",
    BATTERY: "BATTERY",
    TRANSPORT: "TRANSPORT",
    TIRE_CHANGE: "TIRE_CHANGE",
    OTHER: "OTHER"
  },
  ALARM_TYPES: {
    TOO_MUCH_FUEL: "TOO_MUCH_FUEL",
    TOO_MUCH_KM: "TOO_MUCH_KM",
    TOWING: "TOWING",
    LOW_BATTERY: "LOW_BATTERY",
    LOW_FUEL: "LOW_FUEL",
    NO_DATA_FROM_DEVICE: "NO_DATA_FROM_DEVICE",
    COMMAND_NOT_RECEIVED: "COMMAND_NOT_RECEIVED",
    STEALING_ALARM: "STEALING_ALARM",
    LONG_IDLE_DURATION: "LONG_IDLE_DURATION",
    GRAY_BLACK_LIST_UPDATE: "GRAY_BLACK_LIST_UPDATE",
  },
  LOCATION_TYPES: {
    MALL: 1,
    UNIVERSITY: 2,
  },
  ALARM_STATUS: {
    ACTIVE: "ACTIVE",
    INPROGRESS: "INPROGRESS",
    CLOSED: "CLOSED",
  },
  GRAY_BLACK_LIST: {
    GRAY_LIST: "GRAY_LIST",
    BLACK_LIST: "BLACK_LIST",
  },
  PERMISSION_STATUS: {
    FREE: "FREE",
    GRAY_LIST: "GRAY_LIST",
    BLACK_LIST: "BLACK_LIST",
  },
  REPORT_DURATION_TYPE: {
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    QUARTERLY: "QUARTERLY",
    YEARLY: "YEARLY",
  },
  VIOLATION_TYPES: {
		OVER_SPEEDING: 0,
		HARD_BRAKING: 1,
		HARD_ACCELERATION: 2,
		SHARP_CORNERING: 3,
		ENGINE_ABUSE: 4,
		LANE_CHANGE: 5,
		TURN_SIGNAL: 6,
		SEAT_BELT: 7,
		FATIGUE_DRIVING: 8,
		HEAD_LIGHT: 9,
		PHONE_USAGE_LOW_SEVERITY: 200,
		PHONE_USAGE_MID_SEVERITY: 201,
		PHONE_USAGE_HIGH_SEVERITY: 202,
		FAST_INTERSECTION_PASSING: 210,
		ECO_BRAKING: 301,
		ECO_IDLING: 302,
		ECO_HIGHRPM: 303,
		ECO_ACCELERATION: 304,
		ECO_CLIMB_UP: 305,
		ECO_CLIMB_DOWN: 306,
		ECO_SPEEDING: 307,
	},
};
