/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import i18n from "app/i18n";

// Usage Example

// eslint-disable-next-line no-lone-blocks
{
  /* <AutoCompleteDropDown
        value={value && value.name}
        onSelect={e => console.log(e)}
        inputValue={inputValue}
        setInputValue={e => setInputValue(e)}
        data={options}
        type="default"
        showKey="name"
      /> */
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const AutoCompleteDropDown = ({
  width = 300,
  onSelect,
  value,
  setInputValue,
  inputValue,
  data,
  type,
  showKey,
  placeholder,
  helperText,
  error,
}) => {
  if (type === "default") {
    return (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          onSelect(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={data}
        getOptionLabel={option =>
          showKey ? option[showKey] : option
        }
        style={{ width }}
        renderInput={params => (
          <TextField
            {...params}
            label={placeholder || i18n.t("common.select")}
            variant="outlined"
            helperText={helperText}
            error={error}
          />
        )}
      />
    );
  }
  if (type === "checkbox") {
    return (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={data}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          onSelect(newValue);
        }}
        getOptionLabel={option => option[showKey]}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[showKey]}
          </>
        )}
        style={{ width: 500 }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Checkboxes"
            placeholder="Favorites"
          />
        )}
      />
    );
  }
  if (type === "tag") {
    return (
      <Autocomplete
        multiple
        onChange={(event, newValue) => {
          onSelect(newValue);
        }}
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={option => option[showKey]}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="limitTags"
            placeholder="Favorites"
          />
        )}
      />
    );
  }
  return null;
};

export default AutoCompleteDropDown;
