import React, { useState, useRef, useEffect } from "react";

import {
  TextField,
  makeStyles,
  FormControl,
  InputAdornment,
  Box,
  Button,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useTranslation } from "react-i18next";

// import Button from "app/ui/Atoms/Button";
import Text from "app/ui/Atoms/Text";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import variables from "styles/_variables.scss";
import ReCAPTCHA from "react-google-recaptcha";
import config from "common/config";
import Image from "app/ui/Atoms/Image";
import Spinner from "app/ui/Atoms/Spinner";
import { useSnackbar } from "notistack";
import i18n from "app/i18n";

const mslogin = require("app/images/ms.png");

const showRecaptchaAfterNumOfError = 0;

const passwordInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <LockIcon htmlColor={variables.zipcar_primary} />
    </InputAdornment>
  )
};

const emailInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <EmailIcon htmlColor={variables.zipcar_primary} />
    </InputAdornment>
  )
};

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
  },
  forms: {
    backgroundColor: "transparent",
    overflow: "scroll",
    width: "50%",
    marginLeft: "25%",
    marginTop: "2rem",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "auto",
    },
  },
  elements: {
    textAlign: "left",
    marginBottom: "1rem",
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 0,
    padding: "148px 0 148px 0",
    height: "100vh",
    backgroundColor: variables.zipcar_primary,
    [theme.breakpoints.down("md")]: {
      height: "15vh",
      justifyContent: "center",
      padding: 0,
    },
  },
  rightSide: {
    textAlign: "center",
    height: "100vh",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      height: "85vh",
    },
  },
  logo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  singUp: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 48,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: 0,
    },
  },
  form: {
    padding: 48,
  },
  loginBtn: {
    marginTop: "3rem",
    height: 100,
  },
  btn: {
    backgroundColor: variables.zipcar_primary,
    color: variables.zipcar_white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 6px -6px",
    width: 300,
    height: 54,
    padding: "14px 28px",
    borderRadius: "25px",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_primary,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 35px",
    },
  },
  externalProviderButton: {
    backgroundColor: variables.zipcar_background,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderWidth: "1px",
    borderStyle: 'solid',
    // transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_background,
      boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 35px",
      borderColor: 'rgba(0, 0, 0, 0.6)',
    },
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 6px -6px",
    width: '100%',
    height: 54,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'none'    

  }
}));

const schema = yup.object().shape({    
  isLogin: yup.boolean(),
  email: yup.string().required('Lütfen geçerli bir email adresi giriniz.').email('Lütfen geçerli bir email adresi giriniz.'),
  password: yup.string().when('isLogin', {
    is: 'transfer',
    then: yup.string().required('Lütfen geçerli bir şifre giriniz.').min(8, 'Lütfen geçerli bir şifre giriniz.')
  }), 
});


const Login = ({ onSubmit, attemptCount, onForgotPassword, triggerMicrosoftLogin, loading, forgotPassword, setForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState();
  const [showEmailError, setShowEmailError] = useState(false);
  // const [token, setToken] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const { recaptchaKey, authURI } = config;

  const { enqueueSnackbar } = useSnackbar();

  // const { recaptchaKey } = config;
  // console.log("conf", recaptchaKey, authURI);

  // console.log("Attempt Count:", attemptCount)

  const submitRef = useRef();

  const { handleSubmit, register, unregister,setValue, trigger, errors, control, watch, formState: { isValid },} = useForm({
    mode: "onSubmit", // replace "all"
    resolver: yupResolver(schema),
    defaultValues: {}
  });

  register("isLogin");

  // useEffect(()=>{
  //   if(loading && forgotPassword){
  //     setTimeout(()=>{
  //       enqueueSnackbar(i18n.t("login.sendResetPassLinkSuccess"), {
  //         variant: "success",
  //       });
  //       setForgotPassword(false);
  //     }, 1000)
  //   }
  // },[loading])
  // const formFields = ["email", "password", "isLogin"];

  // const watchFields = watch(formFields);
  // useEffect(() => {
  //   console.log("WATCH FIELDS:", watchFields)
  // }, [watchFields]);


  function onChange(value) {
    setToken(value);

    if(value){
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
    // console.log("value:", value);
    // console.log(`${authURI}/api/recaptcha`);
    // fetch(`${authURI}/api/recaptcha`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     token: value,
    //   }),
    // })
    //   .then(res => res.json())
    //   .then(res => {
    //     console.log("response", res);
    //     if (res.success) {
    //       setIsVerified(true);
    //     }
    //   })
    //   .catch(err => console.log("err", err));
  }

  const submit = (input) => {
    console.log("INPUT:", input)
    if(!forgotPassword){
      input.token = token;
      onSubmit(input)
    } else {
      onForgotPassword(input.email, token);
      setTimeout(()=>{
        enqueueSnackbar(i18n.t("login.sendResetPassLinkSuccess"), {
          variant: "success",
        });
        setForgotPassword(false);
      }, 1000)
    }
  }


  return (

    <form onSubmit={handleSubmit(submit)}>
      <Box className={classes.forms}>
        <Box className={classes.elements}>
          <Text weight="bold" size={18}>
            {t("login.email")}
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextField
              type="text"
              variant="outlined"
              //value={email}
              //onChange={e => setEmail(e.target.value)}
              placeholder={t("corporateSign.enterEmail")}
              color="primary"
              InputProps={emailInputProps}
              // error={showEmailError && email === ""}
              // helperText={
              //   showEmailError &&
              //   email === "" &&
              //   t("validation.required")
              // }              
              inputRef={register} 
              name={"email"}
              error={errors.email}
              helperText={errors.email?.message}
            />
          </FormControl>
        </Box>

        {!forgotPassword &&      
          <Box className={classes.elements}>
            <Text weight="bold" size={18}>
              {t("login.password")}
            </Text>
            <FormControl margin="dense" fullWidth>
              <TextField
                type="password"
                variant="outlined"
                //value={password}
                //onChange={e => setPassword(e.target.value)}
                placeholder={t("corporateSign.enterPassword")}
                InputProps={passwordInputProps}
                color="primary"
                inputRef={register} 
                name={"password"}
                error={errors.password}
                helperText={errors.password?.message}
              />
            </FormControl>
          </Box>
        }
        {console.log("attemptCount:", attemptCount)}
        {attemptCount >= showRecaptchaAfterNumOfError &&(
          <ReCAPTCHA
            sitekey={recaptchaKey}
            onChange={onChange}
            hl="tr"
          />
        )}
        <Box className={classes.loginBtn}>
          <Button
            className={classes.btn}
            onClick={() => {
              submitRef.current.click();
            }}
            variant="contained"
            size="large"
            disabled={ (!isVerified && attemptCount >= showRecaptchaAfterNumOfError) || loading}
          >
            {!forgotPassword ? t("login.login") : t("login.sendResetPassLink")}            
          </Button>
          <Box mt={2}>
            <Button
              onClick={() => {
                // if (email === "") {
                //   setShowEmailError(true);
                // } else {
                //   onForgotPassword(email);
                // }
                if(!forgotPassword){
                  setForgotPassword(true);
                  register("password")
                  setValue("isLogin", false)
                } else {
                  setForgotPassword(false);
                  unregister("password")
                  setValue("isLogin", true)
                }
              }}
            >
              {!forgotPassword ? t("login.forgotPassword") : t("login.loginPage")}
            </Button>
          </Box>
        </Box>
        {/* <Box mt={5}>
          <Text weight="regular" size={16}>
            {"Or Login with"}
          </Text>
        </Box>
        <Box className={classes.elements} mt={2}>
          <Button 
              className={classes.externalProviderButton}
              onClick={() => {triggerMicrosoftLogin()}}
              variant="contained"
              size="large"              
            >
              <Box mr={2} style={{display: 'flex', justifyContent: 'center', backgroundColor: 'red'}}>
                <Image
                  height={25}
                  width={25}
                  imgAddress={mslogin}
                />
              </Box>
              <Text weight="regular" size={16}>
                {"Login with Microsoft"}
              </Text>
              
          </Button>
        </Box> */}
      </Box>

      <input type="submit" ref={submitRef} style={{ display: "none" }} />

    </form>
  );
};

export default Login;
