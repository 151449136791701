import { useMemo } from "react";

import { sub as dateSub, startOfMonth, endOfMonth } from "date-fns";

import { gql, useQuery, useMutation } from "@apollo/client";
import { useFetchTable } from "services/utils";
import constants from "common/constants";
import { getCurrentUserId } from "app/utils/functions";

export const ticketSelectFragment = `
  id
  status
  createdAt: created_at
  updatedAt: updated_at
  assignee
  assigned_id
  category_id
  assignedUser: assigned_gotrue_user {
    id
    name
    surname
  }
  event {
    id
    description
    ticket_event_sub_category{
      id
      name
    }
  }
  category {
    id
    name
    entity
  }
  entity
  actions (where: { status: { _eq: "ACTIVE" } }) {
    id
    sender {
      id
      name
      surname
    }
    content
    createdAt: created_at
  }
  priority
  driver {
    id
    name
    surname
  }
  vehicle {
    id
    name
    plate
    usage_type
  }
  location {
    id
    name
  }
  account {
    id
    name
  }
  valet {
    id
    name
  }
`;

const ticketFragments = {
  ticketListFields: gql`
    fragment TicketListFields on ticket {
      id
      status
      created_at
      updated_at
      category {
        id
        name
      }
      actions(where: { status: { _eq: "ACTIVE" } }) {
        id
        sender {
          id
          name
          surname
        }
        content
        createdAt: created_at
      }
      priority
      driver {
        id
        name
        surname
      }
      vehicle {
        id
        name
        plate
      }
      location {
        id
        name
      }
      account {
        id
        name
      }
    }
  `,
};

const LIST_TICKETS = gql`
  query LIST_TICKETS(
    $limit: Int!
    $offset: Int!
    $search: String!
    $orderBy: ticket_order_by!
    $where: ticket_bool_exp!
  ) {
    total: ticket_aggregate(
      where: {
        _and: [{ category: { name: { _ilike: $search } } }, $where]
      }
    ) {
      aggregate {
        count
      }
    }
    ticket(
      limit: $limit
      offset: $offset
      where: {
        _and: [{ category: { name: { _ilike: $search } } }, $where]
      }
      order_by: [$orderBy]
    ) {
      ...TicketListFields
    }
  }
  ${ticketFragments.ticketListFields}
`;

const parseTicketListItem = ({
  id,
  subject,
  status,
  priority,
  category,
  driver,
  vehicle,
  location,
  account,
  actions,
  created_at: createdAt,
  updated_at: updatedAt,
  event,
}) => ({
  id,
  subject,
  status,
  priority,
  actions,
  category: category?.name,
  driver,
  vehicle,
  location,
  account,
  createdAt,
  updatedAt,
  eventId: event?.id,
});

const parseTicketListResponse = data => {
  if (!data) {
    return { data: [], totalCount: 0 };
  }

  return {
    data: data.ticket.map(parseTicketListItem),
    totalCount: data.total.aggregate.count,
  };
};

export const useFetchTableTickets = ({
  eventId,
  driverId,
  accountId,
  vehicleId,
}) => {
  const parseQuery = useMemo(
    () => q => {
      if (eventId) {
        return {
          ...q,
          where: {
            ...q.where,
            event_id: {
              _eq: eventId,
            },
          },
        };
      }
      if (driverId) {
        return {
          ...q,
          where: {
            ...q.where,
            driver_id: {
              _eq: driverId,
            },
          },
        };
      }
      if (accountId) {
        return {
          ...q,
          where: {
            ...q.where,
            account_id: {
              _eq: accountId,
            },
          },
        };
      }
      if (vehicleId) {
        return {
          ...q,
          where: {
            ...q.where,
            account_id: {
              _eq: vehicleId,
            },
          },
        };
      }
      return q;
    },
    [eventId, driverId, accountId, vehicleId]
  );

  return useFetchTable(
    LIST_TICKETS,
    parseTicketListResponse,
    parseQuery
  );
};

const LIST_EVENTS = gql`
  query LIST_EVENTS(
    $limit: Int!
    $offset: Int!
    $search: String!
    $orderBy: ticket_event_order_by!
    $where: ticket_event_bool_exp!
  ) {
    total: ticket_event_aggregate(where: { _and: [{}, $where] }) {
      aggregate {
        count
      }
    }
    ticket_event(
      limit: $limit
      offset: $offset
      where: { _and: [{}, $where] }
      order_by: [$orderBy]
    ) {
      id
      tickets {
        ...TicketListFields
      }
      category {
        id
        name
      }
      created_at
      updated_at
    }
  }
  ${ticketFragments.ticketListFields}
`;

const getEventMetaFromTickets = tickets => {
  const priorities = [];
  const drivers = [];
  const vehicles = [];
  const locations = [];
  const accounts = [];
  let fileActionsCount = 0;
  const fileActions = {};
  const assignActions = {};
  let status = null;
  // console.log("tickets", tickets);

  tickets.forEach(ticket => {
    if (ticket.driver) {
      drivers.push(ticket.driver);
    }
    if (ticket.vehicle) {
      vehicles.push(ticket.vehicle);
    }
    if (ticket.location) {
      locations.push(ticket.location);
    }
    if (ticket.account) {
      accounts.push(ticket.account);
    }

    if (ticket.status === constants.STATUS.ACTIVE) {
      status = ticket.status;
    }

    const { actions = [] } = ticket;
    let assigns = actions.filter(
      ({ content: { type } }) =>
        type === constants.TICKET_ACTION_TYPE.ASSIGN
    );

    assigns = assigns.map(el => ({
      ...el,
      name:
        ticket.account?.name ||
        ticket.location?.name ||
        ticket.vehicle?.name ||
        `${ticket.driver?.name} ${ticket.driver?.surname}`,
    }));
    assignActions[ticket.id] = assigns;

    let files = actions.filter(
      ({ content: { type } }) =>
        type === constants.TICKET_ACTION_TYPE.FILE
    );
    files = files.map(el => ({
      ...el,
      name:
        ticket.account?.name ||
        ticket.location?.name ||
        ticket.vehicle?.name ||
        `${ticket.driver?.name} ${ticket.driver?.surname}`,
    }));
    // console.log("FLES", files);
    fileActions[ticket.id] = files;
    fileActionsCount += files.length;

    priorities.push(ticket.priority);
  });
  // await axios
  //   .get(
  //     `${config.mediaURI}/api/listMedia?bucket=event&path=${tickets[0]?.eventId}`
  //   )
  //   .then(res => {
  //     console.log("resss", res);
  //     if (res.data?.data) {
  //       console.log("resss add", res.data.data.length);
  //       fileActionsCount += res.data.data.length;
  //     }
  //   });
  return {
    status: status || (tickets[0] && tickets[0].status),
    priority:
      priorities.length > 0
        ? Math.min(...priorities)
        : constants.PRIORITY.INVALID,
    drivers,
    vehicles,
    locations,
    accounts,
    fileActions,
    fileActionsCount,
    assignActions,
  };
};

export const parseEventItem = ({
  id,
  ticket_source,
  category,
  subcategory,
  description,
  driver,
  gotrue_user,
  created_at: createdAt,
  updated_at: updatedAt,
  tickets,
  reservation_id,
  fleet_type,
  solver_user,
  is_archived,
}) => {
  // console.log("parsedTickets", tickets);
  const parsedTickets = tickets.map(parseTicketListItem);
  return {
    id,
    ticket_source,
    description,
    tickets,
    ...getEventMetaFromTickets(parsedTickets),
    category,
    subcategory,
    createdAt,
    updatedAt,
    driver,
    gotrue_user,
    reservation_id,
    fleet_type,
    solver_user,
    is_archived,
  };
};

const parseEventListResponse = data => {
  if (!data) {
    return { data: [], totalCount: 0 };
  }
  return {
    data: data.ticket_event.map(parseEventItem),
    totalCount: data.total.aggregate.count,
  };
};

export const useFetchTableEvent = () => {
  return useFetchTable(LIST_EVENTS, parseEventListResponse);
};

const TICKET_CATEGORIES = gql`
  query TICKET_CATEGORIES($where: ticket_category_bool_exp!) {
    ticket_category(where: $where) {
      id
      name
      entity
    }
  }
`;

const FETCH_EVENT_BY_ID = gql`
  query FETCH_EVENT_BY_ID($where: fetchTicketEvents_bool_exp!) {
    fetchTicketEvents(limit: 1, offset: 0, where: $where) {
      data {
        id
        created_at
        updated_at
        fleet_type
        category_id
        driver_id
        description
        reservation_id
        source_id
        sub_category_id
        is_archived
        ticket_source {
          id
          name
        }
        category: ticket_event_category {
          name
        }
        driver {
          name
          surname
        }
        gotrue_user {
          email
        }
        ticket_source {
          name
        }
        subcategory: ticket_event_sub_category {
          name
        }
        tickets {
          id
          status
          createdAt: created_at
          updatedAt: updated_at
          assignee
          entity
          priority
          assigned_id
          category_id
          vehicle_id
          location_id
          account_id
          valet_id
          is_archived
          actions {
            id
            content
            created_at
            driver {
              id
              name
            }
            driver_id
            id
            sender {
              id
              name
            }
            status
            user_id
          }
          event {
            id
          }
          assignedUser: assigned_gotrue_user {
            name
            surname
          }
          ticket_category {
            name
            entity
          }
          vehicle {
            id
            name
            plate
            usage_type
          }
          location {
            id
            name
          }
          account {
            id
            name
          }
          valet {
            id
            name
          }
          driver {
            id
            name
            surname
          }
        }
      }
    }
  }
`;
export const useFetchEventById = id => {
  const { data, loading, error, refetch } = useQuery(
    FETCH_EVENT_BY_ID,
    {
      variables: {
        where: {
          _and: [
            { id: { _eq: parseInt(id, 10) } },
            {
              _or: [
                {
                  tickets: { actions: { status: { _eq: "ACTIVE" } } },
                },
                {
                  tickets: {
                    actions: { status: { _is_null: true } },
                  },
                },
              ],
            },
          ],
        },
      },
    }
  );
  return {
    data:
      data?.fetchTicketEvents?.data[0] &&
      parseEventItem(data?.fetchTicketEvents?.data[0]),
    loading,
    error,
    refetch,
  };
};

export const useTicketCategories = entity => {
  const variables = useMemo(() => {
    const where = {};
    if (entity) {
      where.entity = { _eq: entity };
    }
    return { where };
  }, [entity]);
  const { error, loading, data } = useQuery(TICKET_CATEGORIES, {
    variables,
  });
  return {
    data: data?.ticket_category,
    error,
    loading,
  };
};

const CREATE_TICKET_CATEGORY = gql`
  mutation CREATE_TICKET_CATEGORY(
    $data: ticket_category_insert_input!
  ) {
    insert_ticket_category_one(object: $data) {
      id
    }
  }
`;
export const useCreateTicketCategory = () => {
  const [method, state] = useMutation(CREATE_TICKET_CATEGORY);
  return [data => method({ variables: { data } }), state];
};

const CREATE_EVENT_CATEGORY = gql`
  mutation CREATE_EVENT_CATEGORY(
    $data: ticket_event_category_insert_input!
  ) {
    insert_ticket_event_category_one(object: $data) {
      id
    }
  }
`;
export const useCreateEventCategory = () => {
  const [method, state] = useMutation(CREATE_EVENT_CATEGORY);
  return [data => method({ variables: { data } }), state];
};

const CREATE_EVENT_SUB_CATEGORY = gql`
  mutation CREATE_EVENT_SUB_CATEGORY(
    $data: ticket_event_sub_category_insert_input!
  ) {
    insert_ticket_event_sub_category_one(object: $data) {
      id
    }
  }
`;
export const useCreateEventSubCategory = () => {
  const [method, state] = useMutation(CREATE_EVENT_SUB_CATEGORY);
  return [data => method({ variables: { data } }), state];
};

const ADD_EVENT_WITH_TICKETS = gql`
  mutation ADD_EVENT_WITH_TICKETS($data: ticket_event_insert_input!) {
    insert_ticket_event(objects: [$data]) {
      returning {
        id
      }
    }
  }
`;
export const useAddTicketEvent = () => {
  const [method, state] = useMutation(ADD_EVENT_WITH_TICKETS);
  return [
    ({
      source,
      category,
      subcategory,
      description,
      reservationId,
      fleetType,
      tickets = [],
    }) => {
      return method({
        variables: {
          data: {
            source_id: source?.id,
            gotrue_user_id: getCurrentUserId(),
            category_id: category?.id,
            sub_category_id: subcategory?.id,
            description,
            reservation_id: reservationId,
            fleet_type: fleetType,
            tickets: {
              data: tickets.map(ticket => ({
                category_id: ticket.category.id,
                entity: ticket.entity,
                vehicle_id: ticket.vehicle?.id,
                location_id: ticket.location?.id,
                driver_id: ticket.driver?.id,
                account_id: ticket.account?.id,
                valet_id: ticket.valet?.id,
                status: ticket.status,
                assignee: ticket.assignee,
                assigned_id: ticket.assignedUser?.id,
                priority: ticket.priority,
              })),
            },
          },
        },
      });
    },
    state,
  ];
};

const TICKET_DETAIL = gql`
  query TICKET_DETAIL($id: Int!) {
    ticket: ticket_by_pk(id: $id) {
      id
      status
      assignee
      assigned_id
      assignedUser: assigned_gotrue_user {
        id
        name
        surname
      }
      priority
      entity
      createdAt: created_at
      updatedAt: updated_at
      category {
        id
        name
      }
      event {
        id
        description
        category {
          id
          name
        }
        tickets(where: { id: { _neq: $id } }) {
          id
          entity
          account {
            id
            name
          }
          driver {
            id
            name
            surname
          }
          location {
            id
            name
          }
          vehicle {
            id
            name
          }
          category {
            id
            name
          }
        }
        actions: tickets {
          actions(where: { status: { _eq: "ACTIVE" } }) {
            id
            content
            createdAt: created_at
            sender {
              id
              name
              surname
            }
          }
        }
      }
      driver {
        id
        name
        surname
        phone
      }
      vehicle {
        id
        name
        plate
        image
        type {
          name
        }
        model {
          name
          brand {
            name
          }
        }
        location {
          name
        }
      }
      location {
        id
        name
      }
      account {
        id
        name
      }
      #####
      actions(
        order_by: { created_at: asc }
        where: { status: { _eq: "ACTIVE" } }
      ) {
        id
        content
        createdAt: created_at
        sender {
          id
          name
          surname
        }
      }
    }
  }
`;

export const useTicketDetailSub = id => {
  return useQuery(TICKET_DETAIL, { variables: { id } });
};

const TICKET_ARCHIVED_DETAIL = gql`
  query TICKET_ARCHIVED_DETAIL($id: Int!) {
    fetchTickets(
      where: {
        _and: [
          { id: { _eq: $id } }
          {
            _or: [
              { actions: { status: { _eq: "ACTIVE" } } }
              { actions: { status: { _is_null: true } } }
            ]
          }
        ]
      }
      limit: 1
      offset: 0
    ) {
      ticket: data {
        id
        status
        assignee
        assigned_id
        assignedUser: assigned_gotrue_user {
          id
          name
          surname
        }
        priority
        entity
        createdAt: created_at
        updatedAt: updated_at
        category: ticket_category {
          id
          name
        }
        event {
          id
          description
          category: ticket_event_category {
            id
            name
          }
        }
        driver {
          id
          name
          surname
          phone
        }
        vehicle {
          id
          name
          plate
          image
        }
        location {
          id
          name
        }
        account {
          id
          name
        }
        actions {
          id
          content
          createdAt: created_at
          sender {
            id
            name
            surname
          }
        }
      }
    }
  }
`;

const parseArchivedTicketData = data => {
  if (data?.fetchTickets?.ticket?.[0]) {
    let ret = { ...data };
    ret.ticket = data?.fetchTickets?.ticket?.[0];
    return ret;
  }
  return [];
};

export const useArchivedTicketDetailSub = id => {
  const { data, loading, error } = useQuery(TICKET_ARCHIVED_DETAIL, {
    variables: { id },
  });
  return {
    data: parseArchivedTicketData(data),
    error,
    loading,
  };
};

const MAKE_TICKET_ACTION = gql`
  mutation MAKE_TICKET_ACTION(
    $ticketId: Int!
    $content: jsonb!
    $ticketUpdate: ticket_set_input!
  ) {
    insert_ticket_action_one(
      object: { ticket_id: $ticketId, content: $content }
    ) {
      id
    }

    update_ticket_by_pk(
      pk_columns: { id: $ticketId }
      _set: $ticketUpdate
    ) {
      id
    }
  }
`;

export const useMakeTicketActionAndUpdate = ticketId => {
  const [call, state] = useMutation(MAKE_TICKET_ACTION);
  return {
    change: (type, from, to, assignedTo, categoryId) => {
      const ticketUpdate = { id: ticketId };
      switch (type) {
        case constants.TICKET_ACTION_TYPE.STATUS:
          ticketUpdate.status = to;
          break;
        case constants.TICKET_ACTION_TYPE.PRIORITY:
          ticketUpdate.priority = to;
          break;
        case constants.TICKET_ACTION_TYPE.ASSIGN:
          ticketUpdate.assignee = to;
          break;
        case constants.TICKET_ACTION_TYPE.CHANGEASSIGNED:
          ticketUpdate.assigned_id = assignedTo;
          break;
        case constants.TICKET_ACTION_TYPE.CATEGORY:
          ticketUpdate.category_id = categoryId;
          break;
        default:
          throw new Error("invalid change action on ticket");
      }
      return call({
        variables: {
          ticketId,
          content: { type, from, to },
          ticketUpdate,
        },
      });
    },
    message: message =>
      call({
        variables: {
          ticketId,
          content: {
            type: constants.TICKET_ACTION_TYPE.MESSAGE,
            message,
          },
          ticketUpdate: { id: ticketId },
        },
      }),
    file: file =>
      call({
        variables: {
          ticketId,
          content: {
            type: constants.TICKET_ACTION_TYPE.FILE,
            file,
          },
          ticketUpdate: { id: ticketId },
        },
      }),
    loading: state.loading,
  };
};

const LIST_RECENT_TICKETS = gql`
  query LIST_RECENT_TICKETS($department: String!) {
    ticket(
      where: { assignee: { _eq: $department } }
      order_by: { id: desc }
      limit: 50
    ) {
      id
      category {
        name
      }
      event {
        id
        category {
          name
        }
      }
      createdAt: created_at
      updatedAt: updated_at
      assignee
      priority
    }
  }
`;
export const useListRecentTickets = department => {
  const { data, loading, error, refetch } = useQuery(
    LIST_RECENT_TICKETS,
    {
      variables: { department },
    }
  );

  // Add the refetch function to the returned hook
  return { data, loading, error, refetch };
};

const TICKET_EVENT_STATS = gql`
  query TICKET_EVENT_STATS(
    $openFor: timestamptz!
    $intervalStart: timestamptz!
    $intervalEnd: timestamptz!
  ) {
    openOperationTicketCount: ticket_aggregate(
      where: {
        status: { _eq: "ACTIVE" }
        assignee: { _eq: "OPERATION_SERVICES" }
      }
    ) {
      aggregate {
        count
      }
    }

    openMemberTicketCount: ticket_aggregate(
      where: {
        status: { _eq: "ACTIVE" }
        assignee: { _eq: "MEMBER_SERVICES" }
      }
    ) {
      aggregate {
        count
      }
    }

    openForNDays: ticket_aggregate(
      where: {
        status: { _eq: "ACTIVE" }
        updated_at: { _gte: $openFor }
      }
    ) {
      aggregate {
        count
      }
    }

    lastIntervalCount: ticket_aggregate(
      where: {
        created_at: { _gte: $intervalStart, _lte: $intervalEnd }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const useTicketEventStats = () => {
  const variables = useMemo(() => {
    const now = Date.now();
    const lastMonth = dateSub(now, { months: 1 });
    const daysBefore = dateSub(now, { days: 15 });
    const lastMonthStart = startOfMonth(lastMonth);
    const lastMonthEnd = endOfMonth(lastMonth);
    return {
      openFor: daysBefore,
      intervalStart: lastMonthStart,
      intervalEnd: lastMonthEnd,
    };
  }, []);
  return useQuery(TICKET_EVENT_STATS, {
    variables,
  });
};

const FETCH_NAME_ENTITY = (tableName, id) => {
  if (tableName === "reservation") {
    return gql`
      query{
        reservation(where: {_or: [{id: {_eq: ${id}}}, {parent_id: {_eq: ${id}}}]}, order_by: {id: desc}, limit: 1) {
          id
          account {
            id
            name
          }
          driver {
            id
            name
            surname
          }
          vehicle {
            id
            name
            location {
              id
              name
            }
            usage_type
          }
        }
        }
    `;
  }
  if (tableName === "driver") {
    return gql`
      query{
        driver (where: {id:{_eq: ${id} }}){
          id
          name
          surname
        }
        account(
          where: {
            _or: [
              { owner_id:{_eq: ${id} }},
              { 
                account_members: {
                  member_id: {_eq: ${id} }
                }
              }
            ]
          }
        ){
          id
          name
        }
      }`;
  }
  if (tableName === "account") {
    return gql`
      query {
        account(where: { id: { _eq: ${id} } }) {
          id
          name
        }
        driver (
          where:{
            _or:[
              {owned_accounts:{ id:{_eq: ${id} } }}
              {joined_accounts:{ account_id:{_eq: ${id} } }}
            ]
          }
        ){
          id
          name
          surname
        }
      }`;
  }
  return gql`
    query {
      ${tableName}(where: {id:{_eq:${id}}}){
        id
        name
        ${tableName === "driver" ? "surname" : ""}
        ${tableName === "vehicle" ? "usage_type" : ""}
      }
    }
    `;
};

const parseEntityData = (data, tableName) => {
  if (data) {
    if (tableName === "reservation") {
      const res = data.reservation && data.reservation[0];
      return [
        {
          id: res?.id,
          account: { id: res?.account?.id, name: res?.account?.name },
          driver: {
            id: res?.driver?.id,
            name: res?.driver?.name,
            surname: res?.driver?.surname,
          },
          vehicle: {
            id: res?.vehicle?.id,
            name: res?.vehicle?.name,
            usageType: res?.vehicle?.usage_type,
          },
          location: {
            id: res?.vehicle?.location?.id,
            name: res?.vehicle?.location?.name,
          },
        },
      ];
    }
    if (tableName === "driver" || tableName === "account")
      return [data];

    return data[tableName];
  }
  return data;
};

export const useFetchEntityInfo = (tableName, id) => {
  const { data, loading, error } = useQuery(
    FETCH_NAME_ENTITY(tableName, id)
  );
  return {
    data: parseEntityData(data, tableName),
    error,
    loading,
  };
};

const UPDATE_EVENT_STATUS = gql`
  mutation MM($eventId: Int!, $status: String!) {
    update_ticket(
      where: { event_id: { _eq: $eventId } }
      _set: { status: $status }
    ) {
      returning {
        status
        event_id
      }
    }
  }
`;

export const useUpdateTicketEventStatus = () => {
  const [update, { data, loading, error }] = useMutation(
    UPDATE_EVENT_STATUS
  );
  return {
    updateStatus: ({ eventId, status }) =>
      update({ variables: { eventId, status } }),
    data,
    loading,
    error,
  };
};

const ADD_TICKET = gql`
  mutation M($objects: [ticket_insert_input!]!) {
    insert_ticket(objects: $objects) {
      affected_rows
    }
  }
`;

export const useAddTicket = () => {
  const [add, { data, loading, error }] = useMutation(ADD_TICKET);
  return {
    addTicket: ({
      account_id,
      assignee,
      category_id,
      driver_id,
      entity,
      event_id,
      location_id,
      priority,
      status,
      vehicle_id,
      valet_id,
    }) => {
      const obj = {
        account_id: undefined,
        driver_id: undefined,
        location_id: undefined,
        vehicle_id: undefined,
        valet_id: undefined,
      };
      if (account_id) obj.account_id = account_id;
      if (assignee) obj.assignee = assignee;
      if (category_id) obj.category_id = category_id;
      if (driver_id) obj.driver_id = driver_id;
      if (valet_id) obj.valet_id = valet_id;
      if (entity) obj.entity = entity;
      if (event_id) obj.event_id = event_id;
      if (location_id) obj.location_id = location_id;
      if (priority) obj.priority = priority;
      if (status) obj.status = status;
      if (vehicle_id) obj.vehicle_id = vehicle_id;
      return add({ variables: { objects: [obj] } });
    },
    data,
    loading,
    error,
  };
};

const DELETE_TICKET = gql`
  mutation M($id: Int!) {
    delete_ticket_by_pk(id: $id) {
      id
    }
  }
`;

export const useDeleteTicket = () => {
  const [deleteT, { data, loading, error }] = useMutation(
    DELETE_TICKET
  );
  return {
    deleteTicket: ticketId => {
      return deleteT({ variables: { id: ticketId } });
    },
    data,
    loading,
    error,
  };
};
const UPDATE_TICKET_ACTION_STATUS = gql`
  mutation M($actionId: Int!, $status: String!) {
    update_ticket_action(
      where: { id: { _eq: $actionId } }
      _set: { status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

export const useUpdateTicketActionStatus = () => {
  const [update, { data, loading, error }] = useMutation(
    UPDATE_TICKET_ACTION_STATUS,
    {
      fetchPolicy: "no-cache",
    }
  );

  return {
    updateTicketActionStatus: ({ actionId, status }) =>
      update({ variables: { actionId, status } }),
    data,
    loading,
    error,
  };
};

const ADD_RES_TO_TICKET = gql`
  mutation M($event_id: Int!, $reservation_id: Int!) {
    update_ticket_event_by_pk(
      pk_columns: { id: $event_id }
      _set: { reservation_id: $reservation_id }
    ) {
      id
    }
  }
`;

export const useAddReservationToTicket = () => {
  const [
    addReservationToTicket,
    { data, loading, error },
  ] = useMutation(ADD_RES_TO_TICKET);
  return {
    addReservationToTicket: ({ event_id, reservation_id }) => {
      return addReservationToTicket({
        variables: {
          event_id,
          reservation_id,
        },
      });
    },
    data,
    loading,
    error,
  };
};
