/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const Corporate = MatxLoadable({
  loader: () => import("app/views/corporate/index"),
});

const CorporateDetail = MatxLoadable({
  loader: () => import("../views/corporate/corporateDetail"),
});

const CorporateApplications = MatxLoadable({
  loader: () => import("../views/corporate/pendingAccounts"),
});

const corporateRoutes = [
  {
    path: "/corporate/list/:id",
    component: CorporateDetail,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/corporate/list",
    component: Corporate,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/corporateAccounts/list",
    component: CorporateApplications,
    permission: ["manager"]
  },
];

export default corporateRoutes;
