import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Container,
  Box,
  Paper,
  Grid,
} from "@material-ui/core";

import Text from "app/ui/Atoms/Text";
import i18n from "app/i18n";
import ReactCodeInput from "react-code-input";
import Button from "app/ui/Atoms/Button";
import variables from "styles/_variables.scss";

const useStyles = makeStyles(theme => ({    
    verificationContainer:{
      marginTop: '50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '700px'
    },
    verificationNotReceivedContainer:{
      marginTop: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  
    },
    verificationNotReceivedLeftTextBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }));

const CodeVerification = ({verifyCode}) => {

    const classes = useStyles();

    const [disabled, setDisabled] = useState(true)
    const [enteredCode, setEnteredCode] = useState("")

    useEffect(() => {
      setTimeout(()=>{
        setDisabled(false)
      },45000)
    }, [])

    const resetDisabledButton = () => {
      setDisabled(true)
      setTimeout(()=>{
        setDisabled(false)
      },45000)
    }

    const handleCode = (a) => {
      setEnteredCode(a);
      if(a && a.length === 6){
        setTimeout(()=>{
          handleSubmit(a)
        }, 100)
      }
    }

    const handleSubmit = (a) => {
      
      if(verifyCode){
        verifyCode(a)
      }
    }

    return(
        <Container className={classes.verificationContainer}>
          <img
            width={'100px'}
            height={'100px'}            
            src={require("app/images/verification.png")}
          />
          <Box>
            <Text weight={700} size={30}>
              {i18n.t("corporateSign.authenticateAccount")}
            </Text>
          </Box>
          <Box marginTop={'50px'}>
            <Text size={20}>
              {i18n.t("corporateSign.verificationTitle")}
            </Text>
          </Box>
          <Box marginTop={'50px'}>
            <ReactCodeInput type='text' fields={6} onChange={(a)=> {handleCode(a)}} onSubmit={(a)=> {}}/>
          </Box>
          <Grid container className={classes.verificationNotReceivedContainer}>
            <Grid item xs={9}>
              <Box className={classes.verificationNotReceivedLeftTextBox}>
                <Text size={16}>
                  {i18n.t("corporateSign.codereceived")}
                </Text>
                <Text size={16}>
                  {i18n.t("corporateSign.isCodeReceived")}
                  <Button 
                    type={'text'}
                    height={'30px'}
                    color={'transparent'}
                    hoverColor={'transparent'}
                    disabled={disabled}
                    onClick={()=>{ resetDisabledButton()}}
                  >
                    <Text size={16} color={disabled ?  variables.zipcar_background_selected : variables.zipcar_primary}>{"   " + i18n.t("corporateSign.resendCode")}</Text>
                  </Button>
                </Text>
              </Box>

            </Grid>
            <Grid item xs={3}>
                <Box p={1}>
                    <Button       
                        fontSize={14}
                        onClick={() => {
                          handleSubmit(enteredCode)
                        }}
                        color={ variables.zipcar_primary}
                        textColor={variables.zipcar_white}
                        hoverColor={variables.zipcar_background_selected}
                        width={'100px'}
                        height= {'40px'}
                    >
                        {"Submit"}
                    </Button>
                </Box>
            </Grid>
          </Grid>
        </Container>
    )
}

export default CodeVerification;