import React from "react";

import ArrayInput from "app/ui/Organism/ArrayInput";
import TicketInput from "app/ui/Organism/TicketInput";

const TicketArrayInput = ({ value = [], onChange, errors }) => {
  return (
    <ArrayInput
      InputItem={TicketInput}
      onChange={onChange}
      value={value}
      errors={errors}
    />
  );
};

export default TicketArrayInput;
