import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";


const ROLE_AUTHORIZATION = gql`
  query ROLE_AUTHORIZATION($role: String!) {
    role_authorization(where: {role: {_eq: $role}}){
        role_entity{
            id
            name
            parent_id
        }
        c
        r
        u
        d
    }
  }
`;

const parseData = data => {
    
    let roleAuth = {}

    if(data && data.role_authorization){
        let n = data.role_authorization.length
        for(let i=0; i < n; i++){    
            let name = findRecursiveName(data.role_authorization, data.role_authorization[i].role_entity.parent_id, data.role_authorization[i].role_entity.name, "")
            roleAuth[name] = data.role_authorization[i];            
        }
    }


    return roleAuth

};

const findRecursiveName = (data, parent_id, name, suffix) => {


    if (parent_id == 0){
        return name + suffix
    }

    let n = data.length
    for(let i=0; i < n; i++){  
        if(parent_id === data[i].role_entity.id){
            return findRecursiveName(data, data[i].role_entity.parent_id, data[i].role_entity.name, "." + name)
        }
    }

}


  
export const useFetchRoleAuthorization = (role) => {

    // console.log("ROLE STRING:", role)
    const options = useMemo(
        () => ({
          variables: {  
              role,          
          },
        }),
        [role]
      );


    const { data, loading, error } = useQuery(ROLE_AUTHORIZATION, options);
    return {
        data: parseData(data),
        loading,
        error,
    };
};
  