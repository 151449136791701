import {Grid, makeStyles } from "@material-ui/core";
import Button from "app/ui/Atoms/Button";

import React from "react";
import Text from "../Atoms/Text";
import { useTranslation } from "react-i18next";
import variables from "styles/_variables.scss";
import auth from "services/auth";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
  },
  svgContainer: {
    height: "25%",
    width: "25%",
  },
  buttonContainer: {
    marginTop: 40,
    width: '200px'
  }
}));
const UnknownUser = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
        <img className={classes.svgContainer} src="/assets/images/illustrations/unknownUser.svg" alt="" />
      <Text
        size={20}
        weight="bold"
        color={variables.zipcar_text_normal}
      >
        {t("common.unknownUser")}
      </Text>


      <Button className={classes.buttonContainer}  onClick={()=>{auth.logoutAndNotify()}} color={variables.zipcar_primary} textColor={variables.zipcar_white} iconButtonStyle={{marginTop: 20}}>
        {t("common.logout")}
      </Button>

    </Grid>
    );
}

export default UnknownUser;
