import React, { useEffect } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useListRecentTickets } from "services/gql/ticket";
import { Alert } from "@material-ui/lab";
import LoadContainer from "app/ui/Molecule/LoadContainer";

const TicketChatList = ({ onTicketClick, department, open }) => {
  const { data, loading, error, refetch } = useListRecentTickets(department);

  useEffect(() => {
    if(open === true){
      refetch(department)
    }
  }, [open,department])

  // const ticketList = useListRecentTickets(department);
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (loading) {
    return <LoadContainer />;
  }

  if (!data) {
    return <Alert severity="warning">No Data...</Alert>;
  }



  // const tickets = data.ticket;

  return (
    <List>
      {data && data.ticket && data.ticket.map(ticket => (
        <ListItem
          key={`t#${ticket?.id}`}
          button
          onClick={() => onTicketClick(ticket)}
        >
          <ListItemText
            primary={`${ticket?.category?.name}#${ticket?.id}`}
            secondary={`${ticket?.event?.category?.name}#${ticket?.event?.id}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default TicketChatList;
