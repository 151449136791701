/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListHGB = MatxLoadable({
  loader: () => import("../views/hgb"),
});

const hgbRoutes = [
  {
    path: "/hgb/list",
    component: ListHGB,
    permission: ["manager"]
  },
];

export default hgbRoutes;
