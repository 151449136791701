import { MatxLoadable } from "matx";

const CreateCustomer = MatxLoadable({
  loader: () => import("../views/customer/CreateCustomer"),
});

const customerRoutes = [
  // {
  //   path: "/customer/create",
  //   component: CreateCustomer,
  //   permission: ["manager"]
  // },
];

export default customerRoutes;
