import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  AccountBox,
  CreditCard,
  DirectionsCar,
  ExpandLess,
  ExpandMore,
  Help,
  Info,
  LocationOn,
} from "@material-ui/icons";

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import constants from "common/constants";
import { getMediaURL, ParseToDateAndHour } from "app/utils/functions";
import Image from "app/ui/Atoms/Image";
import LicensePlate from "app/ui/Atoms/LicensePlate/index";
import variables from "styles/_variables.scss";
import {
  TicketAssigneeChipSelect,
  TicketPriorityChipSelect,
  TicketStatusChipSelect,
} from "app/ui/Molecule/ChipSelect";
import { useTranslation } from "react-i18next";
import ListView from "./ListView";
import Text from "app/ui/Atoms/Text";
import Button from "app/ui/Atoms/Button";
import ImageList from "app/ui/Molecule/ImageList";

const EntityIcon = ({ entity }) => {
  switch (entity) {
    case constants.ENTITY.ACCOUNT:
      return <CreditCard />;
    case constants.ENTITY.VEHICLE:
      return <DirectionsCar />;
    case constants.ENTITY.LOCATION:
      return <LocationOn />;
    case constants.ENTITY.DRIVER:
      return <AccountBox />;
    default:
      return <Help />;
  }
};

const VehicleDetailCard = ({ vehicle }) => {
  const { t } = useTranslation();
  const imgSrc = getMediaURL("vehicle", `models/${vehicle.image}`);
  return (
    <Box display="flex" alignItems="center">
      <Tooltip
        arrow
        title={
          <>
            <ListView
              color={variables.zipcar_white}
              data={vehicle?.type?.name ? [
                {
                  left: t("vehicleDetails.name"),
                  right: vehicle.name,
                },
                {
                  left: t("vehicleDetails.basicInformation.type"),
                  right: vehicle.type?.name,
                },
                {
                  left: t("vehicleTable.model"),
                  right:
                    vehicle.model?.brand.name +
                    " " +
                    vehicle.model?.name,
                },
                {
                  left: t("serviceTable.location"),
                  right: vehicle.location?.name,
                },
              ] : 
              [
                {
                  left: t("vehicleDetails.name"),
                  right: vehicle.name,
                }
              ]
            }
            />
            <Image width={100} height={"100%"} imgAddress={imgSrc} />
          </>
        }
      >
        <Link to={`/vehicles/list/${vehicle.id}`}>
          <LicensePlate plate={vehicle.plate} />
        </Link>
      </Tooltip>
    </Box>
  );
};

const TicketDetailCard = ({
  ticket,
  eventId,
  onStatusChange,
  onAssigneeChange,
  onPriorityChange,
  onEventTicketClick,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const createdAt = ParseToDateAndHour(ticket?.createdAt);
  // console.log("TicketDetailCard:ticket:", ticket);
  let ticketsListViewData = [
    {
      left: t("chatBox.mainTitle"),
      right: (
        <Link to={`/tickets/list/${ticket?.event.id}`}>
          <Text weight={700} size={14}>
            {`${ticket?.event.category.name} #${ticket?.event.id}`}
          </Text>
        </Link>
      ),
    },
  ];

  ticket?.event?.tickets && ticket.event.tickets.forEach(eventTicket =>
    ticketsListViewData.push({
      left: t("ticketEventDetailPage.relatedTicket"),
      right: (
        // <Box mb={1}>

        <>
          <Button
            // key={`t#${eventTicket.id}`}
            height={18}
            type="text"
            color={"rgb(0,0,0,0)"}
            textWeight={700}
            onClick={() =>
              onEventTicketClick({
                ...eventTicket,
                event: { id: ticket?.event.id },
              })
            }
          >
            {`${eventTicket?.category?.name} #${eventTicket?.id}`}
          </Button>
          <Tooltip
            arrow
            title={
              <Text
                weight={700}
                size={14}
                color={variables.zipcar_white}
              >
                {`${t(
                  "entity." + eventTicket.entity?.toLowerCase()
                )} ${
                  eventTicket[eventTicket.entity?.toLowerCase()]?.name
                } ${
                  eventTicket.entity === constants.ENTITY.DRIVER
                    ? eventTicket[eventTicket.entity?.toLowerCase()]
                        ?.surname
                    : ""
                }`}
              </Text>
            }
          >
            <Info />
          </Tooltip>
        </>
        // </Box>
      ),
    })
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title={`${ticket?.category?.name} #${ticket?.id}`}
        subheader={createdAt}
        avatar={
          <Avatar>
            <EntityIcon entity={ticket?.entity} />
          </Avatar>
        }
        action={
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={open}>
        <CardContent>
          <Text weight={700} size={14}>
          {t(`entity.${ticket?.entity.toLowerCase()}`)}

          </Text>
          {ticket?.vehicle && (
            <VehicleDetailCard vehicle={ticket?.vehicle} />
          )}

          {ticket?.location && (
            <Link to={`/locations/${ticket?.location.id}`}>
              <Typography>{ticket?.location.name}</Typography>
            </Link>
          )}

          {ticket?.account && (
            <Link to={`/account/list/${ticket?.account.id}`}>
              <Typography>{ticket?.account.name}</Typography>
            </Link>
          )}

          {ticket?.driver && (
            <Link to={`/driver/list/${ticket?.driver.id}`}>
              <Typography>
                {ticket?.driver.name} {ticket?.driver.surname}
              </Typography>
            </Link>
          )}

          <Box margin={1} />

          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t("ticketEventDetailPage.eventDescription")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              {ticket.event.description}
              </Typography>
            </AccordionDetails>
          </Accordion> */}

          <Box mt={2} mb={1}>
            <Box mt={1} mb={1}>
              <Text weight={700} size={14}>
                {t("ticketEventDetailPage.eventDescription")}
              </Text>
            </Box>
            <Box mt={1} mb={2}>
              <Typography>{ticket?.event.description}</Typography>
            </Box>
          </Box>

          {ticket?.assignee === constants.ASSIGNEE.SPECIFIC && (
            <Box mt={2} mb={1}>
              <Box mt={1} mb={1}>
                <Text weight={700} size={14}>
                  {t("ticketEventDetailPage.assignedUser")}
                </Text>
              </Box>
              <Box mt={1} mb={2}>
                <Typography>
                  {ticket?.assignedUser?.name
                    ? `${ticket?.assignedUser?.name} ${ticket?.assignedUser?.surname}`
                    : ""}
                </Typography>
              </Box>
            </Box>
          )}

          {/* <Link to={`/tickets/list/${ticket.event.id}`}>
            <Typography variant="subtitle1">
              <strong>{`${ticket.event.category.name}#${ticket.event.id}`}</strong>
            </Typography>
          </Link>
          <Typography>{ticket.event.description}</Typography> */}

          {/* {ticket.event.tickets.length > 0 && (
            <>
              <Typography variant="subtitle1">
                <strong>Iliskili biletler</strong>
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {ticket.event.tickets.map(eventTicket => (
                  <Box mb={1}>
                    <Button
                      key={`t#${eventTicket.id}`}
                      type="button"
                      onClick={() =>
                        onEventTicketClick({
                          ...eventTicket,
                          event: { id: ticket.event.id },
                        })
                      }
                    >
                      {`${eventTicket.category.name}#${eventTicket.id}`}
                    </Button>
                  </Box>
                ))}
              </Box>
            </>
          )} */}

          <ListView data={ticketsListViewData} width={20} />
          <ImageList
            bucket="event"
            pathPrefix={eventId}
            imageWidth={100}
            imageHeight={100}
            maxThumbCount={5}
            hideLaunchIcon
            lightboxSupport
          />
        </CardContent>
      </Collapse>
      <CardActions>
        <Grid container spacing={1}>
          <Grid item>
            <TicketStatusChipSelect
              value={ticket?.status}
              onChange={newValue =>
                onStatusChange(newValue, ticket?.status)
              }
            />
          </Grid>
          <Grid item>
            <TicketAssigneeChipSelect
              value={ticket?.assignee}
              onChange={newValue =>
                onAssigneeChange(newValue, ticket?.assignee)
              }
            />
          </Grid>
          <Grid item>
            <TicketPriorityChipSelect
              value={ticket?.priority}
              onChange={newValue =>
                onPriorityChange(newValue, ticket?.priority)
              }
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default TicketDetailCard;
