import { MatxLoadable } from "matx";

const PendingAccounts = MatxLoadable({
  loader: () => import("app/views/pendingApp/pendingAccounts"),
});

const PendingAffiliates = MatxLoadable({
  loader: () => import("app/views/pendingApp/pendingAffiliates"),
});

const PendingAffiliateApplications = MatxLoadable({
  loader: () => import("app/views/pendingApp/pendingAffiliateApplications"),
});

const PendingDrivers = MatxLoadable({
  loader: () => import("app/views/pendingApp/pendingDrivers"),
});

const pendingRoutes = [
  {
    path: "/pendingAccounts/list",
    component: PendingAccounts,
    permission: ["manager"]
  },
  {
    path: "/pendingDrivers/list",
    component: PendingDrivers,
    permission: ["manager"]
  },
  {
    path: "/pendingAffiliates/list",
    component: PendingAffiliates,
    permission: ["manager"]
  },
  {
    path: "/pendingAffiliateApplications/list",
    component: PendingAffiliateApplications,
    permission: ["manager"]
  },
];

export default pendingRoutes;
