import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import BasicTable from "app/ui/Atoms/BasicTable";
import variables from "styles/_variables.scss";

const StyledInput = withStyles(() => ({
  root: {
    flex: 1,
  },
  input: {
    color: variables.zipcar_text_light,
    fontSize: 12,
  },
}))(InputBase);

const StyledPaper = withStyles(() => ({
  root: {
    height: 44,
    borderRadius: 8,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: variables.zipcar_opacity,
  },
}))(props => (
  <Paper {...props} component="form" elevation={0}></Paper>
));

const SearchContent = withStyles(() => ({
  root: {
    borderRadius: 8,
    marginBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "rgba(235, 235, 235, 0.25)",
  },
}))(props => (
  <Paper {...props} component="form" elevation={0}></Paper>
));

const TextInput = ({
  width,
  height,
  placeholder,
  type,
  searchResult,
  handleChange,
  multiline,
  rowsMax,
  rowsMin,
  rows,
  value,
  disabled = false,
  onEnter,
  dataType,
  endAdornment,
  required,
  helperText,
  error
}) => {
  const [val, setVal] = useState();
  const [showSearchContent, setShowSearchContent] = useState(false);

  const useStyles = makeStyles({
    root: {
      width: width - 32,
      height,
    },
    iconButton: {
      padding: 10,
    },
    searchContent: {
      height: showSearchContent ? 369 : 0,
      overflow: "hidden",
      transition: "all 0.4s",
    },
  });

  const classes = useStyles();

  const onTextChange = v => {
    if (handleChange) {
      handleChange(v);
    }
    setVal(v);
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  const keyPress = e => {
    if (e.keyCode === 13) {
      onEnter(val);
      setVal("");
    }
  };

  return (
    <>
      <StyledPaper
        classes={{
          root: classes.root,
        }}
      >
        <StyledInput
          value={val}
          disabled={disabled}
          placeholder={placeholder}
          onChange={e => {
            onTextChange(e.target.value);
          }}
          multiline={multiline || false}
          rows={rows || 1}
          rowsMax={rowsMax || 1}
          rowsMin={rowsMin || 1}
          onKeyDown={onEnter && keyPress}
          type={dataType}
          endAdornment={endAdornment}
          required={required}
          helperText={helperText}
          error={error}
        />
        {type && type === "search" && (
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => {
              setShowSearchContent(!showSearchContent);
            }}
          >
            <SearchIcon
              style={{ color: variables.zipcar_text_light }}
              fontSize="small"
            />
          </IconButton>
        )}
      </StyledPaper>
      {type && type === "search" && (
        <SearchContent className={classes.searchContent}>
          <BasicTable data={searchResult} />
        </SearchContent>
      )}
    </>
  );
};

export default TextInput;
