import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Text from "app/ui/Atoms/Text";
import ReCAPTCHA from "react-google-recaptcha";
import { Box } from "@material-ui/core";
import i18n from "app/i18n";
import config from "common/config";

// import variables from "styles/_variables.scss";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  success: {
    marginTop: "6rem",
    marginBottom: "6rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  successMessage: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function Step4({setCaptchaVerified, setCaptchaToken}) {
  const classes = useStyles();

  const { recaptchaKey } = config;

  function onChange(value) {
    setCaptchaToken(value);

    if(value){
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  }

  return (
    <Box className={classes.success}>
      <Box className={classes.successMessage}>
        <Text weight="bold" size={24}>
          {i18n.t("corporateSign.successMessage")}
        </Text>
        <Text weight="bold" size={24}>
          {i18n.t("corporateSign.confirmMessage")}
        </Text>
        <Box marginTop={5} width={'100%'}>
          <ReCAPTCHA
            sitekey={recaptchaKey}
            onChange={onChange}
            hl="tr"
          />
        </Box>
      </Box>
    </Box>
  );
}
