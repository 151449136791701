import * as yup from "yup";

import i18n from "app/i18n";
import constants from "common/constants";

const entityValues = Object.values(constants.ENTITY);
export const entityField = yup
  .mixed()
  .oneOf(entityValues, i18n.t("validation.invalidEntity"));

const priorityValues = Object.values(constants.PRIORITY);
export const priorityField = yup
  .mixed()
  .oneOf(priorityValues, i18n.t("validation.invalidPriority"));

const assigneeValues = Object.values(constants.ASSIGNEE);
export const assigneeField = yup
  .mixed()
  .oneOf(assigneeValues, i18n.t("validation.invalidAssignee"));

export const ticketCategoryField = yup
  .object()
  .nullable()
  .shape({
    id: yup
      .number()
      .positive()
      .integer()
      .required(),
    name: yup.string().required(),
  });

export const ticketEventCategoryField = yup
  .object()
  .nullable()
  .shape({
    id: yup
      .number()
      .positive()
      .integer()
      .required(),
    name: yup.string().required(),
  });

export const ticketField = yup
  .object()
  .nullable()
  .shape({
    priority: priorityField.required(i18n.t("validation.required")),
    entity: entityField.required(i18n.t("validation.required")),
    status: yup.string().required(i18n.t("validation.required")),
    assignee: assigneeField.required(i18n.t("validation.required")),
    category: ticketCategoryField.required(
      i18n.t("validation.required")
    ),
  });

const fleetTypeValues = Object.values(constants.CAR_MODES);
export const fleetTypeField = yup
  .mixed()
  .oneOf(fleetTypeValues, i18n.t("validation.invalidFleetType"));
