/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListTrafficPenaltiesMatx = MatxLoadable({
  loader: () => import("../views/trafficpenalty"),
});

const trafficPenaltyRoutes = [
  {
    path: "/trafficpenalty/list",
    component: ListTrafficPenaltiesMatx,
    permission: ["manager"]
  },
];

export default trafficPenaltyRoutes;
