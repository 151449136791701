import { MatxLoadable } from "matx";

const CreateAccount = MatxLoadable({
  loader: () => import("app/views/accounts/index"),
});

const AccountDetail = MatxLoadable({
  loader: () => import("app/views/accounts/accountDetail"),
});

const accountRoutes = [
  {
    path: "/account/list/:id",
    component: AccountDetail,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/account/list",
    component: CreateAccount,
    permission: ["manager", "account_holder"]
  },
];

export default accountRoutes;
