/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListSalesRepresentativesMatx = MatxLoadable({
  loader: () => import("../views/salesRepresentative"),
});

const salesRepresentativeRoutes = [
  {
    path: "/salesRepresentative/list",
    component: ListSalesRepresentativesMatx,
    permission: ["manager"]
  },
];

export default salesRepresentativeRoutes;
