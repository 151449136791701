import { MatxLoadable } from "matx";

const Lists = MatxLoadable({
  loader: () => import("../views/lists/lists"),
});

const listsRoutes = [
  {
    path: "/lists",
    component: Lists,
    permission: ["manager"]
  },
];

export default listsRoutes;
