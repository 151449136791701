import React from "react";
import AutoCompleteDropDown from "app/ui/Atoms/AutoCompleteDropDown";
import TextInput from "app/ui/Atoms/TextInput";
import { useFetchTaxOffices } from "services/gql/taxOffices";
import i18n from "app/i18n";

const AutoCompleteTaxOffices = ({
  setInputValue,
  onSelect,
  value,
  error,
  helperText,
}) => {
  // return <div style={{}}>Bekleyin..</div>;
  const { data, loading } = useFetchTaxOffices();

  if (loading) {
    return (
      <TextInput
        label={i18n.t("common.loading")}
        variant="outlined"
        disabled
      />
    );
  }

  return (
    <AutoCompleteDropDown
      value={value && value}
      inputValue={value && value}
      width="100%"
      onSelect={e => onSelect(e)}
      setInputValue={e => setInputValue(e)}
      data={(data?.taxOffices && data.taxOffices?.offices) ? data.taxOffices?.offices : [] }
      type="default"
      error={error}
      helperText={helperText}
    />
  );
};

export default AutoCompleteTaxOffices;
