import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import format from "date-fns/format";
import { ParseToDateAndHour } from "app/utils/functions";

const getInitial = str => (str ? str.slice(0, 1).toUpperCase() : "");

const MessageBubble = ({ owner, children, date, sender }) => {
  const initials =
    getInitial(sender?.name) + getInitial(sender?.surname);
  const fullName = `${sender?.name.toUpperCase()} ${sender?.surname.toUpperCase()}`;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      alignSelf={owner ? "flex-end" : "flex-start"}
      maxWidth="75%"
      margin={1}
    >
      {!owner && sender && (
        <Tooltip title={fullName} arrow>
          {/* <Link to={`/manager/${sender?.id}`}> */}
          <Avatar
            alt={fullName}
            src={`http://some.image/${sender?.id}`}
          >
            {initials}
          </Avatar>
          {/* </Link> */}
        </Tooltip>
      )}
      {!sender && <Avatar></Avatar>}
      <Box
        display="flex"
        flexDirection="column"
        marginLeft={owner ? 0 : 1}
      >
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          align="right"
        >
          {ParseToDateAndHour(date)}
        </Typography>
        <Paper elevation={3}>
          <Box padding={1}>{children}</Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default MessageBubble;
