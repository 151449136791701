/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListFindexes = MatxLoadable({
  loader: () => import("../views/findexes"),
});

const findexRoutes = [
  {
    path: "/findexes/list",
    component: ListFindexes,
    permission: ["manager"]
  },
];

export default findexRoutes;
