import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const history = useHistory();

  const {t} = useTranslation()

  return (
    <div className="flex flex-center flex-middle w-100 h-100vh">
      <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "320px" }}>
        <img className="mb-32" src="/assets/images/illustrations/404.svg" alt="" />
        <Button
          className="capitalize"
          variant="contained"
          color="primary"
          onClick={() => {window.location = "/"}}
        >
          {t("common.backFromNotFoundPage")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
