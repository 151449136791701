import React from "react"
import Text from "../Text"
import variables from "../../../../styles/_variables.scss"

const FormItem = ({title, children, width}) => {
    return(
        <div className="mb-24" style={{display: 'flex', flexDirection: 'column', flex: 'none', width:width}}>
            <Text size={12} color={variables.zipcar_text_normal} weight={500} className="mb-8">{title}</Text>
            {children}
        </div>
    )
}

export default FormItem;