/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from "react";
// import clsx from "clsx";

import {
  makeStyles,
  Box,
  Stepper,
  Step,
  StepLabel,
  Container,
  Button,
} from "@material-ui/core";
import Icon from "app/ui/Atoms/Icon";
// import Check from "@material-ui/icons/Check";
// import { StepIconProps } from "@material-ui/core/StepIcon";

import i18n from "app/i18n";
import Text from "app/ui/Atoms/Text";
import Spinner from "app/ui/Atoms/Spinner";

import Step1 from "app/ui/Pages/Auth/Register/step1";
import Step2 from "app/ui/Pages/Auth/Register/step2";
import Step3 from "app/ui/Pages/Auth/Register/step3";
import Step4 from "app/ui/Pages/Auth/Register/step4";

import variables from "styles/_variables.scss";
import { generateRandomId } from "utils";
import { useCreateCorporateAccount } from "services/gql/account/createCorporateAccount";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  stepper: {
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  formsWrapper: {
    overflow: "auto",
    width: "100%",
    padding: 16,
    height: "70vh",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  forms: {
    backgroundColor: "transparent",
    overflow: "auto",
    width: "50%",
    marginLeft: "25%",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "auto",
    },
  },
  btnGroup: {
    backgroundColor: "white",
    width: "100%",
    height: 96,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  success: {
    marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  successMessage: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btn: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: variables.zipcar_primary,
    color: variables.zipcar_white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 6px -6px",
    width: 120,
    height: 40,
    padding: "14px 28px",
    borderRadius: "25px",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_primary,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 35px",
    },
  },
  stepCircle: {
    //  border: "solid 2px green",
  },
  // stepCircleIcon: {
  //   border: "solid 2px red",
  //   color: "red",
  // },
}));

/*
const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eee",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#55a646",
  },
  circle: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#332233",
    zIndex: 1,
    fontSize: 18,
  },
});

const QontoStepIcon = props => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};
*/

function getSteps() {
  return [
    `${i18n.t("corporateSign.steps.contactInfo")}`,
    `${i18n.t("corporateSign.steps.paymentInfo")}`,
    `${i18n.t("corporateSign.steps.driverInfo")}`,
    `${i18n.t("corporateSign.steps.apply")}`,
  ];
}

function getStepContent(
  currentAccount,
  stepIndex,
  allData,
  setAllData,
  currentStep,
  setFinishSteps,
  onSubmit,
  setCaptchaVerified,
  setCaptchaToken
) {
  switch (stepIndex) {
    case 0:
      return (
        <Container>
          <Box>
            <Step1
              ref={el => {
                currentStep.current[stepIndex] = el;
              }}
              allData={allData}
              setFinishSteps={setFinishSteps}
              setAllData={e => setAllData({ ...allData, step1: e })}
            />
          </Box>
        </Container>
      );
    case 1:
      return (
        <Container>
          <Box>
            <Step2
              ref={el => {
                currentStep.current[stepIndex] = el;
              }}
              allData={allData}
              setFinishSteps={setFinishSteps}
              onSubmit={onSubmit}
              setAllData={e => setAllData({ ...allData, step2: e })}
              currentAccount={currentAccount}
            />
          </Box>
        </Container>
      );
    case 2:
      return (
        <Container>
          <Box>
            <Step3
              ref={el => {
                currentStep.current[stepIndex] = el;
              }}
              allData={allData}
              setFinishSteps={setFinishSteps}
              setAllData={e => setAllData({ ...allData, step3: e })}
            />
          </Box>
        </Container>
      );
    case 3:
      return (
        <Container>
          <Box>
            <Step4 setCaptchaVerified={setCaptchaVerified} setCaptchaToken={setCaptchaToken}/>
          </Box>
        </Container>
      );
    default:
      return (
        <Container>
          <Box>Error steps</Box>
        </Container>
      );
  }
}

const Register = ({ currentAccount }) => {
  const account = useCreateCorporateAccount();
  const steps = getSteps();
  const classes = useStyles();
  const currentStep = useRef([]);
  const [isFormSending, setIsFormSending] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [progressedStep, setProgressedStep] = useState(0);
  const [formResult, setFormResult] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  // const allData = JSON.parse(
  //   window.localStorage.getItem("corpSignupData") === null
  //     ? {}
  //     : window.localStorage.getItem("corpSignupData")
  // );
  const [allData, setAllData] = useState({
    tempId: generateRandomId(),
  });

  // const setAllDataWithLocalStorage = d => {
  //   window.localStorage.setItem("corpSignupData", JSON.stringify(d));
  //   // setAllData(d);
  // };

  const sendForm = () => {
    setIsFormSending(true);
    const req = {
      tempId: allData.tempId,
      companyName: allData.step1?.step1?.companyName,
      taxNumber: allData.step1?.step1?.taxNumber,
      taxOffice: allData.step1?.step1?.taxOffice,
      salesRepresentativeId:
        allData.step1?.step1?.salesRepresentative?.id,
      holderName: allData.step1?.step3?.holderName,
      holderSurname: allData.step1?.step3?.holderSurname,
      holderEmail: allData.step1?.step3?.holderEmail,
      holderPhone: allData.step1?.step3?.holderPhone.replace(
        /([\s()+])/g,
        ""
      ),
      holderPassword: allData.step1?.step3?.holderPassword,
      billingAddress: allData.step1?.step1?.billingAddress,
      billingAddress2: allData.step1?.step1?.billingAddress2,
      numberOfEmployee: `${allData.step1?.step1?.numberOfEmployee}`,
      potentialDrivers: `${allData.step1?.step1?.potentialDrivers}`,
      drivers: [],
      captchaToken,
    };
    if (allData.step2?.number?.length > 0) {
      req.creditCard = {
        name: "Main Card",
        ownerName: allData.step2?.name,
        pan: allData.step2?.number.replace(/\s/g, ""),
        expireMonth: Number(allData.step2?.expiry.split("/")[0]),
        expireYear: Number(allData.step2?.expiry.split("/")[1]),
        cvv: allData.step2?.cvc,
      };
    }
    if (
      allData.step3?.step1?.driverEmails &&
      allData.step3?.step1?.driverEmails.length > 0
    ) {
      req.drivers = allData.step3?.step1?.driverEmails;
    }

    account.addAccount(req);
  };

  const finishStep = step => {
    if (step) {
      setProgressedStep(step);
    } else {
      setProgressedStep(
        prevStep => prevStep + (prevStep > 0 ? 1 : 2)
      );
    }
  };

  const handleNext = () => {
    if (
      activeStep === 1 &&
      !currentStep.current[activeStep]?.onCompleteForm()
    ) {
      return;
    }

    if (activeStep > 2) {
      sendForm();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () =>
    setActiveStep(prevActiveStep => prevActiveStep - 1);

  const onSubmit = () => {
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    console.log(allData);
  }, [allData]);

  useEffect(() => {
    if (account.loading === false && isFormSending) {
      setIsFormSending(false);
      if (account.error) {
        setFormResult({
          success: false,
          message: i18n.t("corporateSign.unexpectedServerError"),
        });
        setActiveStep(4);
      } else if (
        account.error === undefined &&
        account.data?.createCorporateAccount?.success
      ) {
        setFormResult({ success: true });
        setActiveStep(4);
        // setAllDataWithLocalStorage({ tempId: generateRandomId() });
      } else if (account.error === undefined) {
        let errorMsg = "";
        switch (account.data?.createCorporateAccount?.code) {
          case 402:
            errorMsg = i18n.t(
              "corporateSign.errors.emailTaken"
            );
            break;
          case 406:
            errorMsg = i18n.t(
              "corporateSign.errors.alreadyRegistered"
            );
            break;
          case 403:
            errorMsg = i18n.t("corporateSign.errors.wrongTaxID");
            break;
          case 407:
            errorMsg = i18n.t(
              "corporateSign.errors.accAssignmentFailed"
            );
            break;
          case 408:
            errorMsg = i18n.t(
              "corporateSign.errors.accOkButDriverNotInvited"
            );
            break;
          case 409:
            errorMsg = i18n.t(
              "corporateSign.errors.onlyCreditCards"
            );
            break;
          case 410:
            errorMsg = i18n.t(
              "corporateSign.errors.creditCardNotValid"
            );
            break;
          case 411:
            errorMsg = i18n.t(
              "corporateSign.errors.creditCardPaymentError"
            );
            break;
          default:
            errorMsg = account.data?.createCorporateAccount?.message;
        }
        setFormResult({ success: false, message: errorMsg });
        setActiveStep(4);
      }
    }

    if (progressedStep === 3) {
      setTimeout(() => {
        finishStep(4);
      }, 2000);
    }

    // console.log(window.location);
  }, [account, progressedStep, window.location, isFormSending]);

  return (
    <div className={classes.root}>
      {/* {`${activeStep} / ${progressedStep}`} */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel
              className={classes.stepCircle}
              // StepIconComponent={QontoStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* {JSON.stringify(allData)} */}
      <div className={classes.formsWrapper}>
        {activeStep === steps.length ? (
          <div>
            {formResult.success ? (
              <Box className={classes.success}>
                <Icon
                  size={72}
                  name="check_circle"
                  color={variables.zipcar_primary}
                />
                <Box className={classes.successMessage}>
                  <Text weight="bold" size={24}>
                    {i18n.t("corporateSign.congratulations")}
                  </Text>
                </Box>
                <Box className={classes.successMessage}>
                  <Text weight="medium" size={18}>
                    {i18n.t("corporateSign.returnMessage1")}
                  </Text>
                  <Text weight="medium" size={18}>
                    {i18n.t("corporateSign.returnMessage2")}
                  </Text>
                </Box>
                {/* <Box className={classes.successMessage}>
                  <Button onClick={handleReset}>Reset</Button>
                </Box> */}
              </Box>
            ) : (
              <Box className={classes.success}>
                <Icon
                  size={72}
                  name="cancel"
                  color={variables.zipcar_salmon}
                />
                <Box className={classes.successMessage}>
                  <Text weight="bold" size={24}>
                    {![402, 403, 406, 409].includes(account?.data?.createCorporateAccount?.code) ? i18n.t("corporateSign.failing") : ""}
                  </Text>
                </Box>
                <Box className={classes.successMessage}>
                  <Text weight="medium" size={18}>
                    {i18n.t("corporateSign.failMessage")}
                  </Text>
                  <Text
                    weight="medium"
                    size={18}
                    color={variables.zipcar_salmon}
                  >
                    {formResult.message}
                  </Text>
                </Box>
                <Box className={classes.successMessage}>
                  <Button onClick={handleReset}>
                    {i18n.t("corporateSign.checkIt")}
                  </Button>
                </Box>
              </Box>
            )}
          </div>
        ) : (
          <div>
            <Box className={classes.forms}>
              {getStepContent(
                currentAccount,
                activeStep,
                allData,
                setAllData,
                currentStep,
                finishStep,
                onSubmit,
                setCaptchaVerified,
                setCaptchaToken
              )}
            </Box>
            <Box className={classes.btnGroup}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                {i18n.t("common.back")}
              </Button>
              {!isFormSending ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={activeStep >= progressedStep || (activeStep == 3 && !captchaVerified) }
                  className={classes.btn}
                >
                  {/* {` ${activeStep}/${progressedStep} `} */}
                  {activeStep === steps.length - 1
                    ? `${i18n.t("common.apply")}`
                    : `${i18n.t("common.forward")}`}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled
                  className={classes.btn}
                >
                  <Spinner size={16} />
                </Button>
              )}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
