import React from "react";

import { Box } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";

import constants from "common/constants";

import {
  TicketChangeActionView,
  TicketFileActionView,
  TicketMessageActionView,
} from "app/ui/Molecule/TicketActionViews";
import MessageBubble from "app/ui/Molecule/MessageBubble";
import { useTranslation } from "react-i18next";

const TicketActionContentView = ({ ticketId, content }) => {
  if (!content) {
    return "invalid content";
  }

  if (content.type === constants.TICKET_ACTION_TYPE.MESSAGE) {
    return <TicketMessageActionView message={content.message} />;
  }

  if (content.type === constants.TICKET_ACTION_TYPE.FILE) {
    return (
      <TicketFileActionView
        file={content.file}
        ticketId={ticketId}
        preview
      />
    );
  }

  return (
    <TicketChangeActionView
      type={content.type}
      from={content.from}
      to={content.to}
    />
  );
};

const TicketActionMessages = React.memo(
  ({ actions, userId, ticketId, ticketDescription }) => {
    const { t } = useTranslation();
    let newActions = []
    if(actions){
      newActions = [...actions]
    }

    // console.log("New Actions: ", newActions);

    if (newActions.length === 0) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={1}
          >
            <MailOutline /> {t("ticketActions.noActionYet")}
          </Box>
        </>
      );
    }

    return newActions.map(({ id, content, createdAt, sender }) => (
      <MessageBubble
        key={`msg-${id}`}
        owner={userId === sender?.id}
        date={new Date(createdAt)}
        sender={sender}
      >
        <TicketActionContentView
          ticketId={ticketId}
          content={content}
        />
      </MessageBubble>
    ));
  }
);

export default TicketActionMessages;
