import React, { Children, useEffect } from "react";
import {useFetchRoleAuthorization} from "services/gql/role/role";
import { getUserRole } from "../app/utils/functions";
import { Redirect } from "react-router-dom";
import { matchRoutes } from "react-router-config";
import routes from "./navigation/RootRoutes";
import NotFound from "app/views/sessions/NotFound";

const RoleAuthProvider = ({ children }) => {

    const { loading: rolesLoading, data: roles, error, rolesError } = useFetchRoleAuthorization(getUserRole())

    const lastChildIndex = Children.count(children) - 1;

    const startPath = {
        manager: "/vehicles/list",
        account_holder: "/corporate/list",
        settings: "/settings/list"
      }

    const renderChild = () => {

        if(!rolesLoading && roles){

            // return

            return(
                Children.map(children, (child, idx) => {
                    if (!child) return;
        
                    let values = {...child?.props?.value, roles}
                
                    console.log("Values:", values, child)
                    
                    // values?.routes?.map((r) => {                        
                    //     if ((r.path === '/' || r.path === '/index.html') && getUserRole() !== 'manager'){
                    //         r.component = () => <Redirect to={'/account'}/>
                    //     }
                    // })

                    // eslint-disable-next-line consistent-return
                    return React.cloneElement(child, {
                    ...child?.props, value: values
                    });
                })
            )

        } else {
            return null
        }

    }


    return (
    //   <div
    //     style={{
    //       display: "flex",
    //       flex: 1,
    //     }}
    //   >
    //   {/* {renderChild()} */}
    //   {
        Children.map(children, (child, idx) => {
                    if (!child) return;
        
                    let values = {...child?.props?.value, roles}
                
                    // values?.routes?.map((r) => {                        
                    //     if ((r.path === '/' || r.path === '/index.html') && getUserRole() !== 'manager'){
                    //         r.component = () => <Redirect to={'/account'}/>
                    //     }
                    // })

                    if(values?.routes && values?.routes.length > 0){
                        for(let i=0; i < values?.routes.length; i=i+1){
                            if (values?.routes[i].path === '/'){
                                
                                values.routes[i]= {
                                    path: "/",
                                    exact: true,
                                    permission: ["manager", "account_holder", "settings"],
                                    component: () => <Redirect to={startPath[getUserRole()]}
                                    />,
                                }
                            }

                            if (values?.routes[i].path === '/index.html'){
                                values.routes[i]= {
                                    path: "/index.html",
                                    permission: ["manager", "account_holder", "settings"],
                                    component: () =>  <Redirect to={startPath[getUserRole()]}/>,
                                }
                            }
                        }
                    }

                    const matched = matchRoutes(routes, window.location.pathname)[0];
                    console.log("Values:", values, matched, child)

                    if(matched?.route?.permission && matched?.route?.permission && matched.route.permission.length > 0 && matched.route.permission.includes(getUserRole())){
                        // eslint-disable-next-line consistent-return
                        return React.cloneElement(child, {
                        ...child?.props, value: values
                        });
                    }
                    else{
                        return <NotFound />
                    }
                })
    //   }
    //   </div>
    );
  };
  
  export default RoleAuthProvider;
