import "../styles/_app.scss";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
// FIXME: IDE and ESLINT complains but if it is changed does not compiles
// eslint-disable-next-line import/no-unresolved
import history from "history.js";
import { Router } from "react-router-dom";
import hot from "react-hot-reload.macro";
import MatxTheme from "app/layout/Theme/MatxTheme";
import AppContext from "app/appContext";
import RoleAuthProvider from "app/rolesProvider";
import { CssBaseline, makeStyles } from "@material-ui/core";
import Auth from "app/ui/Pages/Auth";
import "./i18n";
import routes from "app/navigation/RootRoutes";
import MatxLayout from "app/layout/Layout";
import "app/i18n";
import LoadScreen from "app/ui/Pages/LoadScreen";
import UnknownUser from "app/ui/Pages/UnknownUser";
import AppChatBoxes from "app/ui/Pages/AppChatBoxes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";
import { useAuth } from "services/auth";
import Apollo from "services/gql/apollo";
import AnonymApollo from "services/gql/anonymApollo";
import queryString from "query-string";
import { usePrevious } from "app/utils/usePrevious";
import { Store } from "./redux/Store";
import ForgotPassword from "./ui/Molecule/ForgotPassword";

import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "app/microsoftProvider";
import config from "common/config";
import ApproveDriverDeviceChangeRequestAnonymously from "./ui/Pages/ApproveDriverDeviceChangeRequestAnonymously";
// import useWebSocketHook from 'app/utils/useWebsocket'

const useClasses = makeStyles({
  error: { color: "white!important" },
  success: { color: "white!important" },
  info: { color: "white!important" },
  warning: { color: "white!important" },
});

let recoverInitiated = false;

const App = () => {
  const classes = useClasses();

  // const { socketMessage } = useWebSocketHook();
  const [
    azureGotrueLoginChecked,
    setAzureGotrueLoginChecked,
  ] = useState(false);

  const {
    loading,
    authorized,
    attemptCount,
    // isRecovering,
    forceMicrosoftLogin,
    login,
    errorMessage,
    initialLoad,
    initialAuthCheck,
    userVerified,
    forgotPassword,
    recover,
    // isRecovered,
    // isRecoverRequested,
    // triggerRecover,
    triggerMicrosoftLogin,
    resetPasswordOnRecovery,
    authorizeAzure,
    verifyUserSMSCode,
  } = useAuth();

  const obj = queryString.parse(window.location.hash);
  // console.log("params", obj);

  // if (obj.recovery_token && !recoverInitiated) {
  //   recoverInitiated = true;
  //   triggerRecover();
  // }

  useEffect(() => {
    //console.log("App:window.location.origin:", window.location.origin)
    //console.log("App:config.adOrigin:", config.adOrigin)
    if (window.location.origin === config.adOrigin) {
      // console.log("App: origin azure")
      triggerMicrosoftLogin();
    } else {
      // console.log("App: not origin azure")
    }
  }, []);

  // useEffect(() => {
  //   if(!authorized && userVerified){
  //     setUserVerified(false);
  //   }
  // }, [authorized])

  console.log("User verified", userVerified);
  // useEffect(() => {
  //   console.log("INITAL Load Check", initialAuthCheck, authorized, userVerified)
  //   if(!initialAuthCheck && authorized && !userVerified){
  //     auth.logoutAndNotify();
  //   }
  // }, [initialAuthCheck])

  // useEffect(() => {
  //   if (!prevIsRecovering && isRecovering) {
  //     console.log("ENTERED HERE::::::", prevIsRecovering, obj)
  //     if (obj.recovery_token) {
  //       recover(obj.recovery_token).catch(err => {
  //         console.log("RECOVERY ERROR:", err)
  //       });
  //     }
  //   }
  // }, [isRecovering])

  // useEffect(() => {
  //     console.log("SOCKET MESSAGE:", socketMessage)

  // }, [socketMessage])

  // console.log("isRecovered", isRecovered, initialLoad);

  // const prevIsRecovering = usePrevious(isRecovering);

  if (obj.adc_token) {
    return <ApproveDriverDeviceChangeRequestAnonymously />;
  }

  if (
    (loading && forceMicrosoftLogin) ||
    (loading && authorized) ||
    initialLoad
  ) {
    return <LoadScreen />;
  }

  if (obj.recovery_token) {
    return (
      <AnonymApollo>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          classes={{
            variantError: classes.error,
            variantSuccess: classes.success,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
        >
          <ForgotPassword recoveryToken={obj.recovery_token} />
        </SnackbarProvider>
      </AnonymApollo>
    );
  }

  const test = "Bu bir testtir";

  const appRender = () => (
    <Apollo>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        classes={{
          variantError: classes.error,
          variantSuccess: classes.success,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <RoleAuthProvider>
            <AppContext.Provider value={{ routes, test }}>
              <Provider store={Store}>
                <MatxTheme>
                  <CssBaseline />
                  <Router history={history}>
                    <AppChatBoxes>
                      <MatxLayout />
                    </AppChatBoxes>
                  </Router>
                </MatxTheme>
              </Provider>
            </AppContext.Provider>
          </RoleAuthProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </Apollo>
  );
  if ((!authorized || !userVerified) && forceMicrosoftLogin) {
    return (
      <AzureAD
        provider={authProvider}
        forceLogin={true}
        accountInfoCallback={accountInfo => {
          console.log(
            "accountInfoCallback:accountInfo:",
            accountInfo
          );
          if (accountInfo) {
            console.log("email:", accountInfo.account.userName);
            console.log("token:", accountInfo.jwtIdToken);
            if (
              accountInfo.jwtIdToken &&
              accountInfo.jwtIdToken.length > 0
            ) {
              console.log("LOADING:", loading, authorized);
              if (!loading && !azureGotrueLoginChecked) {
                setAzureGotrueLoginChecked(true);
                authorizeAzure(
                  accountInfo.account.userName,
                  accountInfo.jwtIdToken
                );
                //authorizeAzure("hahah@hahah.com", accountInfo.jwtIdToken)
              }
              // authorizeAzure("hahah@hahah.com", accountInfo.jwtIdToken)
              // .then(console.log)
              // .catch(console.error);
            }
          }
        }}
      >
        {/* {({login, logout, authenticationState, error, accountInfo}) => {
            console.log("login:",login)
            console.log("logout:",logout)
            console.log("authenticationState:",authenticationState)
            console.log("error:",error)
            console.log("accountInfo:", accountInfo)
            console.log("accountInfo:",JSON.stringify(accountInfo))
            if(accountInfo && authenticationState === "Authenticated"){
              console.log("email:", accountInfo.account.userName)
              console.log("token:", accountInfo.jwtIdToken)
              authorizeAzure("test@otokoc.com", accountInfo.jwtIdToken)
              .then(console.log)
              .catch(console.error);
            }
            return <h1>{authenticationState}</h1>
          }} */}
        {authorized && userVerified ? appRender() : <UnknownUser />}
      </AzureAD>
    );
  }

  if (!authorized || !userVerified) {
    return (
      <AnonymApollo>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          classes={{
            variantError: classes.error,
            variantSuccess: classes.success,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
        >
          <Auth
            onForgotPassword={(email, captcha_token) => {
              forgotPassword(email, captcha_token).catch(
                console.error
              );
            }}
            onSubmit={({ email, password, token }) => {
              console.log("logging in.....");
              login(email, password, token).catch(err =>
                console.log("Login ERROR:", err)
              );
            }}
            attemptCount={attemptCount}
            recover={token => {
              recover(token).catch(console.error);
            }}
            triggerMicrosoftLogin={triggerMicrosoftLogin}
            // loading={isRecoverRequested}
            errorMessage={errorMessage}
            verified={userVerified}
            authorized={authorized}
            verifyCode={code => {
              verifyUserSMSCode(code);
            }}
          />
        </SnackbarProvider>
      </AnonymApollo>
    );
  }

  return appRender();
};

export default hot(App);
