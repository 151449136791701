import React from "react";
import { Icon as IIcon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import variables from "styles/_variables.scss";

const Icon = ({ name, color, size, onClick, style }) => {
  const StyledIcon = withStyles(() => ({
    root: {
      color: color || variables.zipcar_text_normal,
      fontSize: size || 16,
      style,
    },
  }))(IIcon);

  return <StyledIcon onClick={onClick}>{name}</StyledIcon>;
};
export default Icon;
