import { combineReducers } from "redux";
import LayoutReducer from "./LayoutReducer";
import NotificationReducer from "./NotificationReducer";

const RootReducer = combineReducers({
  layout: LayoutReducer,
  notification: NotificationReducer,
});

export default RootReducer;
