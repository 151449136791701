/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListTicket = MatxLoadable({
  loader: () => import("app/views/tickets").catch(console.error),
});

const TicketDetailPage = MatxLoadable({
  loader: () =>
    import("app/ui/Pages/Ticket/TicketEventDetail").catch(
      console.error
    ),
});

const ticketRoutes = [
  {
    path: "/tickets/dashboard",
    component: ListTicket,
    permission: ["manager"]
  },
  {
    path: "/tickets/list/:eventId",
    component: TicketDetailPage,
    permission: ["manager"]
  },
  {
    path: "/tickets/list",
    component: ListTicket,
    permission: ["manager"]
  },
];

export default ticketRoutes;
