const {
  REACT_APP_HASURA_URI: hasuraURI,
  REACT_APP_HASURA_WS_URI: hasuraWsURI,
  REACT_APP_REMOTE_SCHEMA: remoteSchemaURI,
  REACT_APP_AUTH_URI: authURI,
  REACT_APP_MEDIA_URI: mediaURI,
  REACT_APP_GOTRUE_URI: gotrueURI,
  REACT_APP_REGION_URI: regionURI,
  REACT_APP_RECAPTCHA_CLIENT_KEY: recaptchaKey,
  REACT_APP_B2C_CLIENT_ID:b2cClientID,
  REACT_APP_B2C_TENANT_ID:b2cTenantID,
  REACT_APP_AD_ORIGIN,
} = process.env;

const AD_ORIGIN =
  REACT_APP_AD_ORIGIN === "" || !REACT_APP_AD_ORIGIN ? "http://localhost:44316" : REACT_APP_AD_ORIGIN;

const config = {
  hasura: {
    ws: hasuraWsURI,
    http: hasuraURI,
    rs: remoteSchemaURI,
  },
  mediaURI,
  authURI,
  gotrueURI,
  regionURI,
  recaptchaKey,
  b2cClientID,
  b2cTenantID,
  adOrigin: AD_ORIGIN
};

export default config;
