import React from "react";
import { Box, Icon, makeStyles } from "@material-ui/core";
import variables from "styles/_variables.scss";

const ArrowHistory = ({ width, color }) => {
  const useStyles = makeStyles({
    root: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
    },
    line: {
      minWidth: 16,
      width: width || "100%",
      height: 2,
      borderRadius: 8,
      backgroundColor: color || variables.zipcar_text_normal,
    },
    arrow: {
      marginLeft: "-13px",
      color: color || variables.zipcar_text_normal,
    },
  });

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.line} />
      <Icon className={classes.arrow}>navigate_next</Icon>
    </Box>
  );
};
export default ArrowHistory;
