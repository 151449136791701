import React from "react";
import {
  Icon,
  Button as DefButton,
  IconButton
} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import variables from "../../../../styles/_variables.scss";
import Spinner from "../Spinner";

const Button = ({
  onClick,
  children,
  type,
  width,
  height,
  icon,
  iconPosition,
  color,
  textColor,
  hoverColor,
  textWeight,
  disabled,
  fontSize,
  iconSize,
  className,
  disableRipple,
  iconButtonStyle,
  loading,
  selected,
  toolTipTitle,
  style
}) => {
  // console.log(onClick,children, type, width, height, icon, iconPosition, color, disabled)

  const StyledButton = withStyles(() => ({
    root: {
      width,
      height,
      backgroundColor: color || variables.zipcar_background,
      borderRadius: 8,
      fontSize: fontSize || 14,
      flexDirection: "column",
      "&:hover": {
        backgroundColor: hoverColor || variables.zipcar_text_hover,
        // color: 'pink'
      },
    },
  }))(DefButton);

  const StyledIcon = withStyles(() => ({
    root: {
      color: textColor || variables.zipcar_text_normal,
      fontSize: iconSize || 16,
    },
  }))(Icon);

  const StyledIconButton = withStyles(() => ({
    root: {
      color: color || variables.zipcar_text_normal,
      fontSize: iconSize || 16,
    },
  }))(Icon);

  if (type !== "iconButton" && type !== "toggleButton") {
    return (
      <StyledButton
        variant={type || "contained"}
        disabled={disabled || loading}
        onClick={onClick}
        className={className}
        disableRipple={disableRipple}
        style={style}
        startIcon={
          iconPosition === "left" && icon ? (
            <StyledIcon>{icon}</StyledIcon>
          ) : (
            undefined
          )
        }
        endIcon={
          iconPosition === "right" && icon ? (
            <StyledIcon>{icon}</StyledIcon>
          ) : (
            undefined
          )
        }
      >
        <p
          style={{
            color: textColor || variables.zipcar_text_normal,
            fontWeight: textWeight,
          }}
        >
          {loading ? <Spinner size={24}/> : children}
        </p>
      </StyledButton>
    );
  }

  if(type === "toggleButton"){
    return (
      <Tooltip title={toolTipTitle}>
        <ToggleButton
          value="deneme"
          className={className}
          selected={selected}
          onChange={() => {
            onClick()
          }}
        >
          <StyledIconButton>{icon}</StyledIconButton>
        </ToggleButton>  
      </Tooltip>      
    );
  }

  return (
    <IconButton
      component="span"
      disabled={disabled}
      onClick={onClick}
      disableRipple={disableRipple}
      style={iconButtonStyle}
    >
      <StyledIconButton>{icon}</StyledIconButton>
    </IconButton>
  );
};
export default Button;
