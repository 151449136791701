import { gql, useQuery, useMutation } from "@apollo/client";

const GET_USER_DATA = gql`
  query GET_USER_DATA($userId: String!) {
    user: gotrue_user_by_pk(id: $userId) {
      id
      role
      department
      name
      surname
      phone
    }
  }
`;

const UPDATE_USER_DATA = gql`
  mutation UPDATE_USER_DATA(
    $userId: String!
    $data: gotrue_user_set_input!
  ) {
    update_gotrue_user_by_pk(
      pk_columns: { id: $userId }
      _set: $data
    ) {
      id
    }
  }
`;

export const useGetGotrueUserById = userId => {
  return useQuery(GET_USER_DATA, { variables: { userId } });
};

export const useUpdateUserById = () => {
  const [updateUser, { error, loading, data }] = useMutation(
    UPDATE_USER_DATA
  );
  return [
    ({ userId, updateData }) =>
      updateUser({
        variables: {
          userId,
          data: updateData,
        },
      }),
    { error, loading, data },
  ];
};
