import { MatxLoadable } from "matx";

const CreateServiceListView = MatxLoadable({
  loader: () => import("../views/service"),
});

const CreateNewServiceView = MatxLoadable({
  loader: () => import("../views/service/CreateService"),
});
const serviceRoutes = [
  {
    path: "/service/new_service",
    component: CreateNewServiceView,
    permission: ["manager"]
  },
  {
    path: "/service/list",
    component: CreateServiceListView,
    permission: ["manager"]
  },
];

export default serviceRoutes;
