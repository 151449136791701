import GoTrue from "gotrue-js";
import { useEffect, useState } from "react";
import config from "common/config";
import { authProvider } from "app/microsoftProvider";

const auth = new GoTrue({
  APIUrl: config.gotrueURI,
  AUTHUrl: config.authURI,
  audience: "",
  setCookie: false,
});

const listeners = {};
let listenerIndex = 0;
auth.subscribe = cb => {
  listenerIndex += 1;
  listeners[listenerIndex] = cb;
  return listenerIndex;
};
auth.unsubscribe = id => {
  delete listeners[id];
};
auth.notify = () => {

  console.log("Notifying listeners...", listeners)

  Object.values(listeners).forEach(cb => cb(auth.currentUser()));
};
auth.logoutAndNotify = async () => {
  console.log("Logging out...")
  const user = auth.currentUser();

  // console.log("Current user:", user);

  if (user) {
    user
    .logout(user.id)
    .then(() => {
      auth.notify()
    })
    .catch( e => {
      console.error(e)
      localStorage.removeItem('gotrue.user')
    });
  }

  // if(window.location.origin === config.adOrigin) {
  //   authProvider.logout();
  // }


};

export const useAuth = () => {
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [initialAuthCheck, setInitialAuthCheck] = useState(true)
  const [authorized, setAuthorized] = useState(false);
  const [userVerified, setUserVerified] = useState(true);
  const [attemptCount, setAttemptCount] = useState(0);
  // const [isRecovering, setIsRecovering] = useState(false);
  // const [isRecovered, setIsRecovered] = useState(false);
  // const [isRecoverRequested, setIsRecoverRequested] = useState(false);
  const [forceMicrosoftLogin, setForceMicrosoftLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hashLogin, setHashLogin] = useState('');
  const [hashSms, setHashSms] = useState('');

  const getToken = async () => {
    const user = auth.currentUser();

    console.log("Entered - 2");
    try {
      await user.jwt(false);
      setAuthorized(true);
      setInitialAuthCheck(false);
      console.log("user authorized");

    } catch (e) {
      setAuthorized(false);
      setInitialAuthCheck(false);
      console.log("user not authorized");
    }

    setLoading(false);
  };

  const getClientInfo = async () => {
    try {
      let res = await fetch(`${config.authURI}/api/clientInfo`, {method: "GET"})
      let json = await res.json()

      console.log("CLIENT INFO:", json)
      if (json.success) {
        setAttemptCount(json.data.attempt_count)
      } else {
        setAttemptCount(3)
      }
    } catch (e){
      console.log("err", e)
      setAttemptCount(3)
    }    
  }

  const sendSMSVerificationCode = async (hashLogin) => {
    try {
      let res = await fetch(`${config.authURI}/api/smsAuthAdmin`, {
        method: "POST",
        body: JSON.stringify({
          hash: hashLogin,
        }),
      })
      let json = await res.json()

      console.log("SMS Verification INFO:", json)
      if (json.success) {
        setHashSms(json.data?.hash)
      } else {
      }
    } catch (e){
      console.log("err", e)
    }
  }

  useEffect(() => {
    getClientInfo();

    //console.log("Entered - 1");

    const user = auth.currentUser();

    //console.log("Current user:", user)

    setInitialLoad(false);

    if (!user) {
      setLoading(false);
      return () => {};
    }

    getToken().catch(console.error);

    const subID = auth.subscribe(() => {
      //console.log("Entered - 3");
      getToken().catch(console.error);
    });
    return () => {
      console.log("ENTEREDDDDD")
      if( authorized && !userVerified){
        auth.logoutAndNotify()
      }
      auth.unsubscribe(subID);
    };

  }, []);

  return {
    loading,
    authorized,
    attemptCount,
    // isRecovering,
    // isRecovered,
    // isRecoverRequested,
    forceMicrosoftLogin,
    errorMessage,
    initialLoad,
    initialAuthCheck,
    userVerified,
    login: async (email, password, token) => {
      setErrorMessage();
      setLoading(true);
      await auth
        .loginWithCaptchaWithVerification(email, password, token, true)
        .then(res => {
          console.log("Login Result:", res);
          if(res?.hash){
            setHashLogin(res?.hash)
            sendSMSVerificationCode(res?.hash);
          }
          setAuthorized(true);          
          
          setUserVerified(false);
          setAttemptCount(0);

          setLoading(false);        

        })
        .catch(err => {
          console.log("auth login err:", err)
          if(err.message === 'captcha not verified'){
            setAttemptCount(3);
          } else {
            setAttemptCount((attemptCount ? attemptCount : 0) + 1);
          }

          setErrorMessage("User Not Authorized")
          setLoading(false);

        });
    },
    verifyUserSMSCode: async (code) => {
      try {
        let res = await fetch(`${config.authURI}/api/validateSmsCodeAdmin`, {
          method: "POST",
          body: JSON.stringify({
            code: code,
            hashSms: hashSms,
            hashLogin: hashLogin
          }),
        })
        let json = await res.json()
  
        console.log("Verify SMS Response:", json)
        if (json.success && json.data) {

          await auth.saveUserWithData(json.data);
  
          auth.subscribe(() => {
            console.log("Entered - 4");
            getToken().catch(console.error);
          });
          setTimeout(()=>{
            setUserVerified(true);
          },300)
          
        } else {
          setUserVerified(false);
        }
      } catch (e){
        console.log("err", e)
      }
    },
    authorizeAzure: (email, token) => {
      setLoading(true);

        auth
        .authorizeAzure(email, token, true)
        .then(res => {
          // console.log("authorizeAzure Result:", res);
          setAuthorized(true);
          setAttemptCount(0);
          setLoading(false);

          const subID = auth.subscribe(() => {
            console.log("Entered - 5");
            getToken().catch(console.error);
          });
          // return () => {
          //   auth.unsubscribe(subID);
          // };

        })
        .catch(err => {
          // console.log("authorizeAzure:err:", err)
          setLoading(false);
        });
      
      
      // setLoading(false);
    },
    forgotPassword: async (email, captchaToken) => {
      // setLoading(true);
      // console.log("Setting is recover requested to TRUE....")
      // setIsRecoverRequested(true);
      await auth
        .requestPasswordRecoveryWithCaptche(email, captchaToken)
        .then(res => {
          // console.log("requestPasswordRecovery Result:", res);
          setAttemptCount(0);          

          // setTimeout(()=>{
          //   setIsRecoverRequested(false);
          // }, 1000)
        })
        .catch(err => {
          console.log("requestPasswordRecovery", err);
          setAttemptCount(attemptCount + 1);          
          // setTimeout(()=>{
          //   setIsRecoverRequested(false);
          // }, 1000)
        });
      // setLoading(false);
    },
    recover: async token => {
      // setIsRecovering(true);
      setLoading(true);
      // await auth
      //   .recover(token, true)
      //   .then(res => {
      //     console.log("recover Result:", res);
      //     setIsRecovered(true);
      //   })
      //   .catch(err => {
      //     console.log("recover", err);
      //   });
      await auth.recover(token, true)
      setLoading(false);
    },
    updatePassword: async ({password, captchaToken}) => {
      const user = auth.currentUser();

    fetch(`${config.authURI}/api/recaptcha`, {
      method: "POST",
      body: JSON.stringify({
        token: captchaToken,
      }),
    })
      .then(res => res.json())
      .then(res => {
        console.log("response", res);
        if (res.success) {
          user
            .update({ password })
            .then(u => {
              console.log("Updated user %s", u);
              user
                .logout()
                .then(response =>
                  console.log("User logged out", response)
                )
                .catch(error => {
                  console.log("Failed to logout user: %o", error);
                  throw error;
                });
              // setIsRecovered(false);
            })
            .catch(error => {
              console.log("Failed to update user: %o", error);
              throw error;
            });
        }
      })
      .catch(err => console.log("err", err));

      
    },
    resetPasswordOnRecovery: async (recoveryToken, captchaToken, password) => {
      return auth.resetPasswordOnRecovery(recoveryToken, captchaToken, password)
    },
    triggerMicrosoftLogin: async () => {
      setForceMicrosoftLogin(true);
    },
    // triggerRecover: () => {
    //   setIsRecovering(true);
    // }   
  };
};

export default auth;
