// authProvider.js
import "regenerator-runtime/runtime";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import config from "common/config";

// Msal Configurations

// const config = {
//     auth: {
//       authority: 'https://login.microsoftonline.com/48fc386b-ba1e-4913-b8bf-1adaa13b2d2c',
//       clientId: '12bac51c-9e99-4fe0-8f54-a99eb856c3bf',
//       redirectUri: window.location.origin,
//       validateAuthority: true,
//       navigateToLoginRequestUrl: true
//     },
//     cache: {
//       cacheLocation: "localStorage",
//       storeAuthStateInCookie: true
//     }
//   };

const authenticationParameters = {
  scopes: ["openid"],
};

// Options
const options = {
  // loginType: LoginType.Popup,
  // tokenRefreshUri: window.location.origin
};

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      // authority: 'https://login.microsoftonline.com/48fc386b-ba1e-4913-b8bf-1adaa13b2d2c',
      // clientId: "12bac51c-9e99-4fe0-8f54-a99eb856c3bf",
      authority:
        "https://login.microsoftonline.com/" + config.b2cTenantID,
      // authority: 'https://appsotokoc.b2clogin.com/appsotokoc.onmicrosoft.com/B2C_1_zipcartest',
      // knownAuthorities: ["appsotokoc.b2clogin.com"],
      clientId: config.b2cClientID,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: config.adOrigin, // "https://localhost:44316",
      validateAuthority: true,
      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: ["openid"],
  },
  {
    //loginType: LoginType.Redirect,
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin,
  }
);

// export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
