/* eslint-disable react/no-array-index-key */
import React from "react";

import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  withStyles,
} from "@material-ui/core";

import variables from "styles/_variables.scss";

// data example
/*
{
    "headers": ["Location", "Vehicle", "Rate", "Date"],
    "rows": [
        {
            "location": "Küçükyalı Otokoç Otomotiv Genel Müdürlük",
            "vehicle": "Aaden",
            "rate": "31 TL - 34 TL Hour 300 TL - 330 TL Day",
            "date": "08 Jan 12:00pm +",
            "index": 0
        },
    ]
}
*/
const TableCellBorderless = withStyles({
  root: {
    borderBottom: "none",
    alignItems: "center",
    padding: "20px 15px 0px 0px",
  },
  body: {
    fontSize: 12,
    color: variables.zipcar_text_normal,
  },
  head: {
    fontSize: 14,
    fontWeight: "bold",
    color: variables.zipcar_text_normal,
  },
})(TableCell);

const BasicTable = ({
  data,
  alignContent = "bottom",
  alignHeader = "left",
}) => {
  return (
    <div className="overflow-auto">
      <Table style={{ whiteSpace: "pre" }}>
        <TableHead>
          <TableRow>
            {data.headers &&
              data.headers.map((el, indx) => (
                <TableCellBorderless key={"header_" + indx } align={alignHeader}>
                  {el}
                </TableCellBorderless>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows &&
            data.rows.map((row, index) => (
              <TableRow key={`basicTable_${index}`}>
                {Object.keys(row).map(key => (
                  <TableCellBorderless align={alignContent}>
                    {row[key]}
                  </TableCellBorderless>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default BasicTable;
