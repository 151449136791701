import i18n from "app/i18n";
import React, {useEffect, useRef, useState} from "react";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import {
  Box,
  Button,
  FormControl,
  TextField,
  Container,
  Paper,
  Grid,
  makeStyles
} from "@material-ui/core";
import Text from "app/ui/Atoms/Text";
import { useForm, Controller } from "react-hook-form";
import variables from "styles/_variables.scss";
import config from "common/config";
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from "services/auth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useCheckPasswordStrengthWithoutAuth } from "../../../../services/gql/resetPasswordSendEmail";
import useDebounce from "app/utils/useDebounce";

const schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
      i18n.t("validation.passwordStrength")
    )
    .min(8, i18n.t("validation.minLength"))
    .max(32, i18n.t("validation.maxLength"))
    .required(i18n.t("validation.required")),
  rePassword: yup
    .string()
    .min(8, i18n.t("validation.minLength"))
    .max(32, i18n.t("validation.maxLength"))
    .oneOf(
      [yup.ref("password"), null],
      i18n.t("validation.passwordsMustMatch")
    )
    .required(i18n.t("validation.required")),
  captchaToken: yup.string()  
});

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
  },
  btn: {
    marginLeft: theme.spacing(1),
    backgroundColor: variables.zipcar_primary,
    color: variables.zipcar_white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 6px -6px",
    height: 44,
    padding: "14px 28px",
    borderRadius: "25px",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_primary,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 35px",
    },
  },

  leftSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 0,
    padding: "148px 0 148px 0",
    height: "100vh",
    backgroundColor: variables.zipcar_primary,
    [theme.breakpoints.down("md")]: {
      height: "10vh",
      justifyContent: "center",
      padding: 0,
    },
  },
  rightSide: {
    textAlign: "center",
    height: "100vh",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      minHeight: "90vh",
    },
  },
  logo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  singUp: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 48,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: 0,
    },
  },
  form: {
    padding: 48,
  },
  no: {
    display: "none",
  },
}));

const ForgotPassword = ({ recoveryToken }) => {
  const { handleSubmit, register, watch, errors, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const classes = useStyles();

  const recaptchaRef = useRef()

  const { recaptchaKey } = config;

  const formFields = [
    "password"   
  ];
  const watchFields = watch(formFields);

	const debouncedPassword = useDebounce((watchFields.password || ""), 500)

  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState()

  const { checkPasswordStrengthWithoutAuth } = useCheckPasswordStrengthWithoutAuth()
  const { resetPasswordOnRecovery } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation()
  
  const passwordBlackList = ["zipcar", "password", "otokoc", "iugo", "avis", "budget", "otokocotomotiv", "#", "|"]

  function containsSubstringFromArray(inputString, substringsArray) {
    // if(inputString === ""){
    //   return false
    // }
    for (const substring of substringsArray) {
      if (inputString.toLowerCase().includes(substring)) {
        return substring.toUpperCase();
      }
    }
    return false;
  }

  useEffect(() => {
    const word = containsSubstringFromArray(debouncedPassword, passwordBlackList)
    if(word){
      setPasswordStrength({
        is_valid: false,
        strength: -1,
        threshold: -1,
        word: word
      })
    }
    else if( debouncedPassword && debouncedPassword.length > 7 && !errors.password && !errors.rePassword){
      checkPasswordStrengthWithoutAuth(debouncedPassword).then(({ data }) => {
        setPasswordStrength(data?.checkPasswordStrengthWithoutAuth?.response || null)
      }).catch(() => {})
    }
  }, [errors.password, errors.rePassword, debouncedPassword])

  function onChange(value) {
    console.log("OnChange Called")

    if(value){
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  }

  function onSubmit(value) {
    try {
      resetPasswordOnRecovery(recoveryToken, value.captchaToken, value.password).then((u) => {
        if (u?.success) {
            enqueueSnackbar(t('passwordChange.passwordChangedLoginWithNew'), {
              variant: 'success',
            })
            setTimeout(function() {
              //Go to login page 
              const currentURL = window.location.href;
              const newURL = currentURL.split('#')[0];
              window.location.href = newURL;
            }, 1000);
        } else {
            enqueueSnackbar(u?.message || t('passwordChange.passwordnotChanged'), {
              variant: 'error',
            })
        }
      })
      .catch((e) => {
          enqueueSnackbar(e, {
            variant: 'error',
          })
          throw e
      })
    } catch (e) {
        console.error(e)
        enqueueSnackbar(e, {
          variant: 'error',
        })
    }

    recaptchaRef.current.reset()

  }

  return (

    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Paper className={classes.leftSide}>
            <Box className={classes.logo}>
              <img
                src="/assets/images/logo-zipcar-login.png"
                alt="company-logo"
                width="150"
                height="100%"
              />
            </Box>
            <Box className={classes.content}>
              {/* <Text>Slider comes here !</Text> */}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Paper className={classes.rightSide}>
            
            <Box className={classes.form}>
              <Text weight={700} size={30}>
                {i18n.t("corporateSign.changePassword")}
              </Text>
            </Box>
            <Container>
              <Box pl="25%" pr="25%">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box>
                    <Text weight="bold">
                      {i18n.t("corporateSign.forms.step1.holderPassword")}
                    </Text>
                    <FormControl margin="normal" fullWidth variant="outlined">
                      <TextField
                        inputProps={{
                          ref: register,
                          name: "password",
                        }}
                        type="password"
                        helperText={errors?.password?.message ?? (passwordStrength?.threshold === -1 ? t('passwordChange.passwordBlackList', {
                          word: passwordStrength?.word || ""
                        }) : (passwordStrength?.strength < passwordStrength?.threshold) ? t('passwordChange.strongPasswordWithInfo', {
                          requiredPoint: passwordStrength?.threshold ?? '??',
                          currentPoint: passwordStrength?.strength?.toFixed(2) ?? '??',
                        }) : "")}
                        error={!!errors.password || passwordStrength?.threshold === -1 || !passwordStrength?.is_valid}
                        placeholder={i18n.t(
                          "corporateSign.placeholders.holderPassword"
                        )}
                        variant="outlined"
                        // defaultValue={allData.step1?.step3?.holderPassword}
                      />
                    </FormControl>
                  </Box>

                  <Box>
                    <Text weight="bold">
                      {i18n.t("corporateSign.forms.step1.holderRepassword")}
                    </Text>
                    <FormControl margin="normal" fullWidth variant="outlined">
                      <TextField
                        inputProps={{
                          ref: register,
                          name: "rePassword",
                        }}
                        type="password"
                        helperText={errors.rePassword?.message}
                        error={!!errors.rePassword}
                        placeholder={i18n.t(
                          "corporateSign.placeholders.holderPasswordKind"
                        )}
                        variant="outlined"
                        // defaultValue={allData.step1?.step3?.holderRePassword}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                    <Controller
                      name="captchaToken"
                      onChange={(token) => {
                        onChange(token)     
                                               
                      }}
                      render={props => (                        
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={recaptchaKey}
                          onChange={(token) => {
                            onChange(token) 
                            props.onChange(token)                                                      
                          }}
                          hl="tr"
                        />
                      )}
                      control={control}
                      defaultValue={null}
                    />
                      
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <Button type="submit" variant={'contained'} classes={{root: classes.btn}} disabled={!captchaVerified || !passwordStrength?.is_valid}>
                        {i18n.t("common.accept")}
                      </Button>
                    </FormControl>
                  </Box>
                </form>
              </Box>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>

    
  );
};
export default ForgotPassword;
