import { Box, Grid, Typography } from "@material-ui/core";
import Button from "app/ui/Atoms/Button";
import AppContext from "app/appContext";
import React, { useEffect, useState, useContext } from "react";
import Text from "../Atoms/Text";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/styles";
import variables from "styles/_variables.scss";
import auth from "services/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { merge } from "lodash";
import { matchRoutes } from "react-router-config";
import { setLayoutSettings } from "app/redux/actions/LayoutActions";
import FormItem from "app/ui/Atoms/FormItem";
import BasicTable from "app/ui/Atoms/BasicTable";
import ModalActions from "app/ui/Molecule/ModalActions";
import Spinner from "app/ui/Atoms/Spinner";
import queryString from "query-string";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import i18n from "app/i18n";
import { useFetchDriverDeviceChangeData } from "../../../services/gql/changeDriverDeviceData";
import Axios from "axios";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    marginTop: 100,
  },
  container: {
    width: "1000px",
    marginTop: 100,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 50,
  },
  itemContainer: {
    width: "100%",
  },
  svgContainer: {
    height: "25%",
    width: "25%",
  },
  buttonContainer: {
    marginTop: 40,
    width: "200px",
  },
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: variables.zipcar_primary,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    height: "10%",
    width: "100%",
    backgroundColor: variables.zipcar_primary,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  text: {
    height: "30%",
    width: "90%",
    backgroundColor: variables.zipcar_primary,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  textComponent: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    color: variables.zipcar_white,
  },
}));
const ApproveDriverDeviceChangeRequestAnonymously = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const hash = queryString.parse(window.location.hash).adc_token;
  useEffect(async () => {
    let x = await Axios.get(
      `https://kurumsal.zipcarturkiye.com/services/web/approveDeviceChange/${hash}`
    )
      .then(res => {})
      .catch(e => {});
    window.location.href = "zipcartr://home";
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.logo}>
        <img
          src="/assets/images/logo-zipcar-login.png"
          alt="company-logo"
          //   width="150"
          height="100%"
        />
      </Box>
      <Box className={classes.text}>
        <Text size={18} color={variables.zipcar_white}>
          {t("driverDeviceChange.requestInfo")}
        </Text>
      </Box>
    </Box>
  );
};

export default ApproveDriverDeviceChangeRequestAnonymously;
