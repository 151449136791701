/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const CreateDriver = MatxLoadable({
  loader: () => import("../views/drivers/index"),
});

const DriverDetail = MatxLoadable({
  loader: () => import("../views/drivers/driverDetail"),
});

const CorporateDrivers = MatxLoadable({
  loader: () => import("../views/corporate/pendingDrivers"),
});

const GrayBlackView = MatxLoadable({
  loader: () => import("../views/drivers/grayBlackList"),
});

const driverLeagueView = MatxLoadable({
  loader: () => import("../views/drivers/driverLeagueList"),
})

const driverRoutes = [
  {
    path: "/driver/list/:id",
    component: DriverDetail,
    permission: ["manager"]
  },
  {
    path: "/driver/list",
    component: CreateDriver,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/grayBlackList/list",
    component: GrayBlackView,
    permission: ["manager"]
  },
  {
    path: "/driverLeague/list",
    component: driverLeagueView,
    permission: ["manager"]
  },
  {
    path: "/corporateDrivers/list",
    component: CorporateDrivers,
    permission: ["manager"]
  },
];

export default driverRoutes;
