/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const CreateVehicles = MatxLoadable({
  loader: () => import("../views/vehicles"),
});

const VehicleDetail = MatxLoadable({
  loader: () => import("../views/vehicles/vehicleDetails"),
});

const AlarmVehicles = MatxLoadable({
  loader: () => import("../views/vehicles/alarmVehicles"),
})

const vehicleRoutes = [
  {
    path: "/vehicles/list/:id",
    component: VehicleDetail,
    permission: ["manager"]
    
  },
  {
    path: "/vehicles/dashboard",
    component: CreateVehicles,
    permission: ["manager"]
  },
  {
    path: "/vehicles/list",
    component: CreateVehicles,
    permission: ["manager"]
  },
  {
    path: "/vehicles/alarm",
    component: AlarmVehicles,
    permission: ["manager"]
  },
];

export default vehicleRoutes;
