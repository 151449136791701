import { MatxLoadable } from "matx";


const Dashboard = MatxLoadable({
    loader: () => import("../views/dashboard"),
  });

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    permission: ["manager"]
  }
];

export default dashboardRoutes;