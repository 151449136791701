/* eslint-disable prefer-destructuring */
import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect
} from "react";
import { IconButton, Box, makeStyles } from "@material-ui/core";
import { Cancel, AspectRatio } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";

import BottomFixContainer from "app/ui/Molecule/BottomFixContainer";
import BasicChatBox from "app/ui/Molecule/BasicChatBox";

import MainChatBox from "app/ui/Organism/MainChatBox";
import TicketActionBox from "app/ui/Organism/TicketActionBox";
import TicketEventAddForm from "app/ui/Organism/TicketEventAddForm";
import GqlFormController from "app/ui/Organism/GqlFormController";
import { useHistory } from "react-router-dom";
import {
  getCurrentUserId,
  getUserRole,
  removeArrayItem,
  updateArrayIndex,
} from "app/utils/functions";
import {
  useAddTicketEvent,
  useFetchEntityInfo,
} from "services/gql/ticket";
import { useGetGotrueUserById } from "services/gql/gotrueUser";
import auth from "services/auth";
import SlidingPane from "react-sliding-pane";
import "styles/react-sliding-pane.css";
import PressableIcon from "app/ui/Atoms/IconButton";

import constants from "common/constants";

const useStyles = makeStyles(({ spacing, palette }) => ({
  iconButton: { padding: spacing(1), color: palette.common.white },
  cancel: {
    position: "absolute",
    top: 5,
    right: 10,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  addEventSlider: {
    width: "80%",
    margin: "0 auto",
  },
}));

const chatBoxSizeMap = {
  extraLarge: { width: 600, height: 800 },
  midLarge: { width: 450, height: 500 },
  large: { width: 400, height: 500 },
  medium: { width: 300, height: 400 },
  small: { width: 100, height: 200 },
};

const ticketBoxSize =
  window.innerHeight < 1000 ? "midLarge" : "extraLarge";

const AddEventContent = ({ closeChatBox, entityName, idProp }) => {
  const createEventRef = useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const path = history.location.pathname;
  // console.log("AddEventContent:path:", path);
  // console.log("AddEventContent:entityName,id:", entityName, idProp);
  const splitted = path.split("/");
  let entity = entityName || "account";
  let id = idProp || 0;
  // console.log("AddEventContent:splitted:", splitted);
  if (!entityName && splitted.length >= 2) {
    switch (splitted[1]) {
      case "vehicles":
        entity = constants.ENTITY.VEHICLE;
        id = splitted.length === 4 ? splitted[3] : 0;
        break;
      case "locations":
        entity = constants.ENTITY.LOCATION;
        id = splitted.length === 3 ? splitted[2] : 0;
        break;
      case "driver":
        entity = constants.ENTITY.DRIVER;
        id = splitted.length === 4 ? splitted[3] : 0;
        break;
      case "valet":
        entity = constants.ENTITY.VALET;
        id = splitted.length === 4 ? splitted[3] : 0;
        break;
      case "account":
      case "corporate":
        entity = constants.ENTITY.ACCOUNT;
        id = splitted.length === 4 ? splitted[3] : 0;
        break;
      case "reservations":
        entity = constants.ENTITY.RESERVATION;
        id = splitted.length === 4 ? splitted[3] : 0;
        break;
      default:
        break;
    }
  }
  // console.log("AddEventContent:entity, id:", entity, id);
  id = parseInt(id, 10);
  // console.log("AddEventContent:entity, id:", entity, id);
  const { data, loading, error } = useFetchEntityInfo(
    entity.toLowerCase(),
    id
  );
  // console.log("entityinfo:", data, loading, error);
  // console.log("defaultData:",[{entity: entity, [entity.toLowerCase()]:data && data[0]}])
  const defaultEntityValue = {};
  if (data && !error && entity === constants.ENTITY.RESERVATION) {
    defaultEntityValue[constants.ENTITY.VEHICLE] = data[0].vehicle;
    defaultEntityValue[constants.ENTITY.LOCATION] = data[0].location;
    defaultEntityValue[constants.ENTITY.DRIVER] = data[0].driver;
    defaultEntityValue[constants.ENTITY.ACCOUNT] = data[0].account;
  } else if (data && !error && entity === constants.ENTITY.DRIVER) {
    if (data[0].account.length === 1) {
      defaultEntityValue[constants.ENTITY.ACCOUNT] =
        data[0].account[0];
    }
    defaultEntityValue[constants.ENTITY.DRIVER] = data[0].driver[0];
  } else if (data && !error && entity === constants.ENTITY.ACCOUNT) {
    if (data[0].driver.length === 1) {
      defaultEntityValue[constants.ENTITY.DRIVER] = data[0].driver[0];
    }
    defaultEntityValue[constants.ENTITY.ACCOUNT] = data[0].account[0];
  } else if (data && data.length > 0 && !error) {
    defaultEntityValue[entity] = data[0];
  }

  if (!loading) {
    return (
      <GqlFormController
        useMutation={useAddTicketEvent}
        FormComponent={({ onSubmit, loading: formLoading }) => (
          <TicketEventAddForm
            ref={createEventRef}
            onSubmit={onSubmit}
            loading={formLoading}
            defaultEntityValue={
              !Number.isNaN(id) && defaultEntityValue
            }
            reservationId={entity === constants.ENTITY.RESERVATION ? id : undefined}
            usageType={data.length > 0 && (data[0].usage_type || data[0].vehicle?.usageType) || null}
          />
        )}
        onFail={() =>
          enqueueSnackbar(t("snackbar.addFail"), {
            variant: "error",
          })
        }
        onSuccess={(_result, formData) => {
          // console.log("OnTicketEventAddForm:", _result, formData);

          /* Upload media  */
          const r = _result?.data?.insert_ticket_event?.returning;
          if (r.length > 0) {
            // console.log("EVENT ID -1 :", r[0].id);
            createEventRef.current.uploadFiles(r[0].id);
          }
          enqueueSnackbar(t("snackbar.addSuccess"), {
            variant: "success",
          });

          closeChatBox();
        }}
      />
    );
  }

  return <></>;
};

const addEventChatBox = {
  size: "large",
  open: true,
  title: "chatBox.addEventTitle",
  Content: AddEventContent,
  props: {},
};

const addEventChatBoxContent = (entityName, id) => ({
  size: "large",
  open: true,
  title: "chatBox.addEventTitle",
  Content: AddEventContent,
  props: { entityName, idProp: id },
});

const ticketBoxContent = (
  { id: ticketId, event: { id: eventId }, is_archived},
  onEventTicketClick
) => ({
  size: ticketBoxSize,
  open: true,
  title: `T#${eventId}-${ticketId}`,
  Content: TicketActionBox,
  props: { ticketId, eventId, onEventTicketClick, isArchived: is_archived },
});

const maxChatBoxCount = 2;

const AppChatBoxContext = createContext({
  openTicketActionBox: () => {},
});

export const useAppChatBoxCtx = () => useContext(AppChatBoxContext);

const AppChatBoxes = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [chatBoxes, setChatBoxes] = useState([]);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [isTicketInfoOpen, setIsTicketInfoOpen] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [addEventEntityName, setAddEventEntityName] = useState(null);
  const [addEventId, setAddEventId] = useState(null);

  const { t } = useTranslation();

  const currUserId = getCurrentUserId();
  const gotrueUser = useGetGotrueUserById(currUserId);

  if (gotrueUser.loading) {
    return null;
    // eslint-disable-next-line no-else-return
  } else if (
    gotrueUser.error ||
    !gotrueUser.data ||
    !gotrueUser.data.user
  ) {
    auth.logoutAndNotify();
  }

  if (
    gotrueUser.loading ||
    gotrueUser.error ||
    !gotrueUser.data ||
    !gotrueUser.data.user
  ) {
    return null;
  }

  const openChatBox = content => {
    setChatBoxes(oldBoxes => {
      if (oldBoxes.length >= maxChatBoxCount) {
        return [...oldBoxes.slice(0, maxChatBoxCount - 1), content];
      }
      return [...oldBoxes, content];
    });
  };

  const openTicketActionBox = ticket => {
    setCurrentTicket(ticket);
    setIsTicketInfoOpen(true);
    const content = ticketBoxContent(ticket, openTicketActionBox);
    openChatBox(content);
  };

  const openAddEventChatBox = ({ entityName, id }) => {
    setAddEventEntityName(entityName);
    setAddEventId(id);
    const content = addEventChatBoxContent(entityName, id);
    openChatBox(content);
  };

  return (
    <>
      <AppChatBoxContext.Provider
        value={{ openTicketActionBox, openAddEventChatBox }}
      >
        {getUserRole() === "manager" && (
          <BottomFixContainer>
            <Box pr={4}>
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="flex-start"
                alignItems="flex-end"
                overflow="hidden"
              >
                <Box ml={1}>
                  <MainChatBox
                    open={open}
                    user={gotrueUser.data.user}
                    onAddClick={e => {
                      e.stopPropagation();
                      openChatBox(addEventChatBox);
                    }}
                    onMoreClick={e => {
                      e.stopPropagation();
                      // console.log("more");
                    }}
                    onHeaderClick={() => setOpen(!open)}
                    // onItemClick={openTicketActionBox}
                    onItemClick={(input) => {
                      openTicketActionBox(input);
                    }}
                  />
                </Box>

                {chatBoxes.map((chatBox, index) => (
                  <Box ml={1}>
                    <BasicChatBox
                      open={chatBox.open}
                      title={t(chatBox.title)}
                      width={chatBoxSizeMap[chatBox.size].width}
                      height={chatBoxSizeMap[chatBox.size].height}
                      onHeaderClick={() => {
                        setChatBoxes(
                          updateArrayIndex(chatBoxes, index, {
                            ...chatBox,
                            open: !chatBox.open,
                          })
                        );
                      }}
                      headerActions={
                        <div>
                          <IconButton
                            onClick={e => {
                              setIsPaneOpen(true);
                              e.stopPropagation();
                              setChatBoxes(
                                removeArrayItem(chatBoxes, index)
                              );
                            }}
                            className={classes.iconButton}
                          >
                            <AspectRatio />
                          </IconButton>
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              setChatBoxes(
                                removeArrayItem(chatBoxes, index)
                              );
                            }}
                            className={classes.iconButton}
                          >
                            <Cancel
                              onClick={() => {
                                setIsTicketInfoOpen(false);
                                // console.log(
                                //   "ticket info open: ",
                                //   isTicketInfoOpen
                                // );
                              }}
                            />
                          </IconButton>
                        </div>
                      }
                    >
                      {chatBox.open && (
                        <chatBox.Content
                          closeChatBox={() =>
                            setChatBoxes(
                              removeArrayItem(chatBoxes, index)
                            )
                          }
                          user={gotrueUser.data.user}
                          initialMessage={chatBox.message}
                          setTicketMessage={v =>
                            (chatBox.message = v)
                          }
                          {...chatBox.props}
                        />
                      )}
                    </BasicChatBox>
                  </Box>
                ))}
              </Box>
            </Box>
          </BottomFixContainer>
        )}
        {children}
      </AppChatBoxContext.Provider>
      <SlidingPane
        isOpen={isPaneOpen}
        onRequestClose={() => {
          setIsPaneOpen(false);
          setIsTicketInfoOpen(false);
        }}
        width="700px"
        hideHeader
      >
        {isTicketInfoOpen ? (
          <TicketActionBox
            ticketId={currentTicket.id}
            eventId={currentTicket.event.id}
            user={gotrueUser.data.user}
            onEventTicketClick={openTicketActionBox}
            isArchived={currentTicket?.is_archived}
          />
        ) : (
          <div className={classes.addEventSlider}>
            <AddEventContent
              entityName={addEventEntityName}
              idProp={addEventId}
            />
          </div>
        )}
        <div className={classes.cancel}>
          <PressableIcon
            iconName="cancel"
            onClick={() => {
              setIsPaneOpen(false);
              setIsTicketInfoOpen(false);
            }}
          />
        </div>
      </SlidingPane>
    </>
  );
};

export default AppChatBoxes;
