import {MatxLoadable} from "matx";

const Color = MatxLoadable({
  loader: () => import("./Color")
});
const Spacing = MatxLoadable({
  loader: () => import("./Spacing")
});
const Typography = MatxLoadable({
  loader: () => import("./Typography")
});
const Display = MatxLoadable({
  loader: () => import("./Display")
});

const utilitiesRoutes = [
  {
    path: '/utilities/color',
    component: Color,
    permission: ["manager"]
  },
  {
    path: "/utilities/spacing",
    component: Spacing,
    permission: ["manager"]
  },
  {
    path: "/utilities/typography",
    component: Typography,
    permission: ["manager"]
  },
  {
    path: "/utilities/display",
    component: Display,
    permission: ["manager"]
  },
]

export default utilitiesRoutes;