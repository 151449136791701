/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const CreateEditZone = MatxLoadable({
  loader: () => import("../views/zones/editZone"),
});

const zoneRoutes = [
  {
    path: "/editZone",
    component: CreateEditZone,
    permission: ["manager"]
  },
];

export default zoneRoutes;
