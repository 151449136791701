import React, { useState } from "react";
import { Box, Chip, Grid, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import constants from "common/constants";
import { useTranslation } from "react-i18next";

const field = (val, ...keys) => {
  if (keys.length === 0 || !val) {
    return val;
  }
  const [key, ...rest] = keys;
  return field(val[key], ...rest);
};

const useStyles = makeStyles(theme => ({
  root: ({ color }) => {
    // console.log("Theme:", theme)
    // console.log("Color:", color)

    if (!color) {
      return {};
    }

    const bgColor = field(theme.palette, ...color?.split("."));
    if (!bgColor) {
      return {};
    }
    return {
      background: bgColor,
      color: "white",
      fontWeight: 700,
      "&:hover": {
        background: `${bgColor}BB`,
      },
      "&:focus": {
        background: `${bgColor}BB`,
      },
    };
  },
}));
const StyledChip = ({ color, label, onClick }) => {
  const classes = useStyles({ color });
  return (
    <Chip className={classes.root} label={label} onClick={onClick} />
  );
};

const ChipSelect = ({
  options,
  onChange,
  value,
  placeholder = "-",
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const { t } = useTranslation();
  const optionValueMap = options.reduce(
    (acc, option) => ({ ...acc, [option.value]: option }),
    {}
  );
  const selectedOption = optionValueMap[value];
  return (
    <>
      <Popover
        open={editOpen}
        anchorEl={anchor}
        onClose={() => setEditOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box padding={1}>
          <Grid container spacing={1}>
            {options
              .filter(option => option.value !== value)
              .map(option => (
                <Grid item>
                  <StyledChip
                    clickable
                    onClick={() => {
                      onChange(option.value);
                      setEditOpen(false);
                    }}
                    label={t(option.label)}
                    color={option.color}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Popover>
      <StyledChip
        clickable={onChange}
        onClick={
          onChange &&
          (e => {
            setEditOpen(!editOpen);
            setAnchor(e.currentTarget);
          })
        }
        label={t(selectedOption?.label || placeholder)}
        color={selectedOption?.color}
      />
    </>
  );
};

export default ChipSelect;

const ticketStatusOptions = [
  {
    color: "status.open",
    value: constants.STATUS.ACTIVE,
    label: "statusTypes.open",
  },
  {
    color: "status.solved",
    value: constants.STATUS.SOLVED,
    label: "statusTypes.solved",
  },
  {
    color: "status.solvedHappy",
    value: constants.STATUS.SOLVEDHAPPY,
    label: "statusTypes.solvedHappy",
  },
];
export const TicketStatusChipSelect = ({ onChange, value }) => {
  return (
    <ChipSelect
      options={ticketStatusOptions}
      onChange={onChange}
      value={value}
    />
  );
};

const ticketAssigneeOptions = [
  {
    color: "assignee.member",
    value: constants.ASSIGNEE.MEMBER,
    label: "ticketAssignee.memberService",
  },
  {
    color: "assignee.operation",
    value: constants.ASSIGNEE.OPERATION,
    label: "ticketAssignee.operationService",
  },
  {
    color: "assignee.iugo",
    value: constants.ASSIGNEE.IUGO,
    label: "ticketAssignee.iugo",
  },
  {
    color: "assignee.specific",
    value: constants.ASSIGNEE.SPECIFIC,
    label: "ticketAssignee.specific",
  },
];
export const TicketAssigneeChipSelect = ({ onChange, value }) => {
  return (
    <ChipSelect
      options={ticketAssigneeOptions}
      onChange={onChange}
      value={value}
    />
  );
};

const ticketPriorityOptions = [
  {
    color: "priority.1",
    value: constants.PRIORITY.PRIMARY,
    label: "priority.1",
  },
  {
    color: "priority.2",
    value: constants.PRIORITY.SECONDARY,
    label: "priority.2",
  },
];
export const TicketPriorityChipSelect = ({ onChange, value }) => {
  return (
    <ChipSelect
      options={ticketPriorityOptions}
      onChange={onChange}
      value={value}
    />
  );
};
