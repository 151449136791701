import React from "react";
// size can be values or strings as "large" or "medium"
// weight can be "regular", "bold", "medium"
// also custom styles can be given
import variables from "styles/_variables.scss";

const Text = props => {
  const {
    children,
    size,
    weight,
    style,
    color,
    className,
    display,
  } = props;
  const styles = {
    color: variables.zipcar_text_normal,
    // fontFamily:
    fontSize: 12,
    fontWeight: "regular",
    margin: 0,
    display: display || "inline",
    // alignItems: "center",
  };

  if (typeof size === "string") {
    if (size === "large") {
      styles.fontSize = 16;
    }
    if (size === "medium") {
      styles.fontSize = 12;
    }
  } else {
    styles.fontSize = size;
  }

  if (weight) {
    styles.fontWeight = weight;
  }

  if (color) {
    styles.color = color;
  }

  return (
    <p className={className} style={{ ...styles, ...style }}>
      {children}
    </p>
  );
};

export default Text;
