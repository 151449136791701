import React from "react";
import PropTypes from "prop-types";

import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: ({ position }) => ({
    position: "fixed",
    bottom: "0px",
    [position]: "0px",
    zIndex: "12",
  }),
}));

const BottomFixContainer = ({ children, position }) => {
  const classes = useStyles({ position });
  return <Box className={classes.container}>{children}</Box>;
};

BottomFixContainer.propTypes = {
  position: PropTypes.oneOf(["right", "left"]),
};

BottomFixContainer.defaultProps = {
  position: "right",
};

export default BottomFixContainer;
