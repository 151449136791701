import React from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.zipcar.opacity,
    zIndex: 2,
  },
}));

const OverlayLoader = ({ loading, children }) => {
  const classes = useStyles();
  return (
    <>
      {loading && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loaderContainer}
        >
          <CircularProgress />
        </Grid>
      )}
      {children}
    </>
  );
};

export default OverlayLoader;
