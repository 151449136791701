import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const PressableIcon = ({ style, iconName, size, onClick, disableRipple, backgroundColor, color,fontSize }) => {
	let bgObj = {};

	if (backgroundColor) {
		bgObj = {
			backgroundColor,
			borderRadius: '100%',
		};
	}

	return (
		<IconButton size={size || 'small'} onClick={onClick} disableRipple={disableRipple}>
			<Icon style={{ ...style, color, ...bgObj, fontSize}}>{iconName}</Icon>
		</IconButton>
	);
};

export default PressableIcon;
