/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const CreateAffiliateDetails = MatxLoadable({
  loader: () => import("../views/affiliates/details"),
});

const CreateAffiliateList = MatxLoadable({
  loader: () => import("../views/affiliates/list"),
});

const affiliateRoutes = [
  {
    path: "/affiliate/dashboard",
    component: CreateAffiliateList,
    permission: ["manager"]
  },
  {
    path: "/affiliate/list/:id",
    component: CreateAffiliateDetails,
    permission: ["manager"]
  },
  {
    path: "/affiliate/list",
    component: CreateAffiliateList,
    permission: ["manager"]
  },
];

export default affiliateRoutes;
