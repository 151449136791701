/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import Text from "app/ui/Atoms/Text";
import variables from "styles/_variables.scss";

const useStyles = makeStyles({
  root: ({ width, height }) => ({
    display: "flex",
    justifyContent: "center",
    width: width || 300,
    height: height || 44,
    // backgroundColor: variables.zipcar_background,
    borderRadius: 8,
    // paddingLeft: 16,
    zIndex: 999999,
  }),
  input: {
    fontSize: 12,
    color: variables.zipcar_text_light,
  },
  toolbar: {
    backgroundColor: "red",
  },
});

const DateTimePickerInput = ({
  hourAndMinute,
  selectedDate,
  handleDateChange,
  width,
  height,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ width, height });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {!hourAndMinute &&(
          <KeyboardDatePicker
          classes={{ root: classes.root, input: classes.input }}
          margin="normal"
          id="date-picker-dialog"
          format="dd/MM/yyyy"
          initialFocusedDate={null}
          value={selectedDate || null}
          onChange={handleDateChange}
          placeholder={t("time.selectDate")}
          variant="filled"
          label={null}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input },
          }}
          DialogProps={{ classes: { paper: classes.toolbar } }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          showTodayButton
          // clearable={true}
          okLabel={
            <Text color={variables.zipcar_primary} weight={500}>
              {t("common.ok")}
            </Text>
          }
          cancelLabel={
            <Text color={variables.zipcar_primary} weight={500}>
              {t("common.cancel")}
            </Text>
          }
          todayLabel={
            <Text color={variables.zipcar_primary} weight={500}>
              {t("time.today")}
            </Text>
          }
        />)}
        {hourAndMinute &&(
          <KeyboardDateTimePicker
          ampm={false}
          classes={{ root: classes.root, input: classes.input }}
          margin="normal"
          id="date-picker-dialog"
          format="dd/MM/yyyy-HH:mm"
          initialFocusedDate={null}
          value={selectedDate || null}
          onChange={handleDateChange}
          placeholder={t("time.selectDate")}
          variant="filled"
          label={null}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input },
          }}
          DialogProps={{ classes: { paper: classes.toolbar } }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          showTodayButton
          // clearable={true}
          okLabel={
            <Text color={variables.zipcar_primary} weight={500}>
              {t("common.ok")}
            </Text>
          }
          cancelLabel={
            <Text color={variables.zipcar_primary} weight={500}>
              {t("common.cancel")}
            </Text>
          }
          todayLabel={
            <Text color={variables.zipcar_primary} weight={500}>
              {t("time.today")}
            </Text>
          }
        />)}
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePickerInput;
