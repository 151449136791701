import React from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import { TicketEntitySelector } from "app/ui/Organism/CustomSimpleSelector";
import { TicketCategorySelector } from "app/ui/Organism/CustomGqlSelector";

const TicketEntityAndCategorySelector = ({
  onChange,
  value = null,
  error,
  entityDisabled,
}) => {
  return (
    <>
      <FormControl fullWidth margin="normal" error={!!error?.entity}>
        <TicketEntitySelector
          disabled={entityDisabled}
          value={value?.entity}
          onChange={entity => onChange({ entity, category: null })}
        />
        {error?.entity && (
          <FormHelperText>{error?.entity?.message}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        fullWidth
        margin="normal"
        error={!!error?.category}
      >
        <TicketCategorySelector
          value={value?.category}
          disabled={!value?.entity}
          entity={value?.entity}
          onChange={category => onChange({ ...value, category })}
        />
        {error?.category && (
          <FormHelperText>{error?.category?.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default TicketEntityAndCategorySelector;
