/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListReservation = MatxLoadable({
  loader: () => import("../views/reservations"),
});

const DetailsReservation = MatxLoadable({
  loader: () => import("../views/reservations/reservationDetails"),
});

const DetailsReservationv2 = MatxLoadable({
  loader: () => import("../views/reservations/reservationDetailsv2"),
});

const NewReservation = MatxLoadable({
  loader: () => import("../views/reservations/newReservation"),
});

const reservationRoutes = [
  {
    path: "/reservations/list/:id",
    component: DetailsReservationv2,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/reservations/listv2/:id",
    component: DetailsReservation,
    permission: ["manager", "account_holder"]
  },
  {
    path:
      "/reservations/new_reservation/:accountId/:driverId/:reservationId",
    component: NewReservation,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/reservations/new_reservation",
    component: NewReservation,
    permission: ["manager", "account_holder"]
  },
  {
    path: "/reservations/list",
    component: ListReservation,
    permission: ["manager", "account_holder"]
  },
];

export default reservationRoutes;
