import { useMutation, gql } from "@apollo/client";

const INSERT_CORPORATE_ACCOUNT = gql`
  mutation CREATE_CORPORATE_ACCOUNT(
    $tempId: String!
    $companyName: String!
    $taxNumber: String!
    $taxOffice: String!
    $salesRepresentativeId: Int
    $holderName: String!
    $holderSurname: String!
    $holderEmail: String!
    $holderPhone: String!
    $holderPassword: String!
    $billingAddress: String!
    $billingAddress2: String!
    $numberOfEmployee: String!
    $potentialDrivers: String!
    $creditCard: CreditCard
    $drivers: [String!]!
    $captchaToken: String!
  ) {
    createCorporateAccount(
      tempId: $tempId
      companyName: $companyName
      taxNumber: $taxNumber
      taxOffice: $taxOffice
      salesRepresentativeId: $salesRepresentativeId
      holderName: $holderName
      holderSurname: $holderSurname
      holderEmail: $holderEmail
      holderPhone: $holderPhone
      holderPassword: $holderPassword
      billingAddress: $billingAddress
      billingAddress2: $billingAddress2
      numberOfEmployee: $numberOfEmployee
      potentialDrivers: $potentialDrivers
      creditCard: $creditCard
      drivers: $drivers
      captchaToken: $captchaToken
    ) {
      accountId
      success
      message
      code
    }
  }
`;

const parseData = data => {
  return data;
};

export function useCreateCorporateAccount() {
  const [account, { error, loading, data }] = useMutation(
    INSERT_CORPORATE_ACCOUNT
  );

  return {
    data: parseData(data),
    loading,
    error,
    addAccount: ({
      tempId,
      companyName,
      taxNumber,
      taxOffice,
      salesRepresentativeId,
      holderName,
      holderSurname,
      holderEmail,
      holderPhone,
      holderPassword,
      billingAddress,
      billingAddress2,
      numberOfEmployee,
      potentialDrivers,
      creditCard,
      drivers,
      captchaToken,
    }) => {
      account({
        variables: {
          tempId,
          companyName,
          taxNumber,
          taxOffice,
          salesRepresentativeId,
          holderName,
          holderSurname,
          holderEmail,
          holderPhone,
          holderPassword,
          billingAddress,
          billingAddress2,
          numberOfEmployee,
          potentialDrivers,
          creditCard,
          drivers,
          captchaToken,
        },
      });
    },
  };
}
