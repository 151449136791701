/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListValets = MatxLoadable({
  loader: () => import("../views/valet"),
});

const DetailsValets = MatxLoadable({
  loader: () => import("../views/valet/details"),
});

const RelocationValetsList = MatxLoadable({
  loader: () => import("../views/valet/valetRelocation"),
});

const valetRoutes = [
  {
    path: "/valet/list/:id",
    component: DetailsValets,
    permission: ["manager"]
  },
  {
    path: "/valet/list",
    component: ListValets,
    permission: ["manager"]
  },
  {
    path: "/valet/relocation/list",
    component: RelocationValetsList,
    permission: ["manager"]
  },
];

export default valetRoutes;
