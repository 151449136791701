/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListExpenses = MatxLoadable({
  loader: () => import("../views/expenses"),
});

const expenseRoutes = [
  {
    path: "/expenses/list",
    component: ListExpenses,
    permission: ["manager"]
  },
];

export default expenseRoutes;
