import variables from "../../../styles/_variables.scss";

const shadows = [
  "none",
  "0px 2px 1px -1px rgba(0, 0, 0, 0.06),0px 1px 1px 0px rgba(0, 0, 0, 0.042),0px 1px 3px 0px rgba(0, 0, 0, 0.036)",
  "0px 3px 1px -2px rgba(0, 0, 0, 0.06),0px 2px 2px 0px rgba(0, 0, 0, 0.042),0px 1px 5px 0px rgba(0, 0, 0, 0.036)",
  "0px 3px 3px -2px rgba(0, 0, 0, 0.06),0px 3px 4px 0px rgba(0, 0, 0, 0.042),0px 1px 8px 0px rgba(0, 0, 0, 0.036)",
  "0px 2px 4px -1px rgba(0, 0, 0, 0.06),0px 4px 5px 0px rgba(0, 0, 0, 0.042),0px 1px 10px 0px rgba(0, 0, 0, 0.036)",
  "0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 5px 8px 0px rgba(0, 0, 0, 0.042),0px 1px 14px 0px rgba(0, 0, 0, 0.036)",
  "0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 6px 10px 0px rgba(0, 0, 0, 0.042),0px 1px 18px 0px rgba(0, 0, 0, 0.036)",
  "0px 4px 5px -2px rgba(0, 0, 0, 0.06),0px 7px 10px 1px rgba(0, 0, 0, 0.042),0px 2px 16px 1px rgba(0, 0, 0, 0.036)",
  "0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)",
  "0px 5px 6px -3px rgba(0, 0, 0, 0.06),0px 9px 12px 1px rgba(0, 0, 0, 0.042),0px 3px 16px 2px rgba(0, 0, 0, 0.036)",
  "0px 6px 6px -3px rgba(0, 0, 0, 0.06),0px 10px 14px 1px rgba(0, 0, 0, 0.042),0px 4px 18px 3px rgba(0, 0, 0, 0.036)",
  "0px 6px 7px -4px rgba(0, 0, 0, 0.06),0px 11px 15px 1px rgba(0, 0, 0, 0.042),0px 4px 20px 3px rgba(0, 0, 0, 0.036)",
  "0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 12px 17px 2px rgba(0, 0, 0, 0.042),0px 5px 22px 4px rgba(0, 0, 0, 0.036)",
  "0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 13px 19px 2px rgba(0, 0, 0, 0.042),0px 5px 24px 4px rgba(0, 0, 0, 0.036)",
  "0px 7px 9px -4px rgba(0, 0, 0, 0.06),0px 14px 21px 2px rgba(0, 0, 0, 0.042),0px 5px 26px 4px rgba(0, 0, 0, 0.036)",
  "0px 8px 9px -5px rgba(0, 0, 0, 0.06),0px 15px 22px 2px rgba(0, 0, 0, 0.042),0px 6px 28px 5px rgba(0, 0, 0, 0.036)",
  "0px 8px 10px -5px rgba(0, 0, 0, 0.06),0px 16px 24px 2px rgba(0, 0, 0, 0.042),0px 6px 30px 5px rgba(0, 0, 0, 0.036)",
  "0px 8px 11px -5px rgba(0, 0, 0, 0.06),0px 17px 26px 2px rgba(0, 0, 0, 0.042),0px 6px 32px 5px rgba(0, 0, 0, 0.036)",
  "0px 9px 11px -5px rgba(0, 0, 0, 0.06),0px 18px 28px 2px rgba(0, 0, 0, 0.042),0px 7px 34px 6px rgba(0, 0, 0, 0.036)",
  "0px 9px 12px -6px rgba(0, 0, 0, 0.06),0px 19px 29px 2px rgba(0, 0, 0, 0.042),0px 7px 36px 6px rgba(0, 0, 0, 0.036)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
  "0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)",
];

const themeOptions = {
  typography: {
    fontSize: 14,
    body1: {
      fontSize: "14px",
    },
  },
  palette: {
    zipcar: {
      primary: "#55a646",
      secondary: "#9ac97a",
      danger: "#f26d62",
      warn: "#DA7269",
      background: "#f3f3f3",
      textNormal: "#55595c",
      textLight: "#9d9bb1",
      textHover: "#ebebeb",
      white: "#ffffff",
      black: "#000000",
      opacity: "rgba(235, 235, 235, 0.25)",
      yellow: "#ffdd00",
      orange: "#fbae43",
      salmon: "#f26d62",
      baseOne: "#005a4f",
      baseTwo: "#026473",
    },
    status: {
      active: variables.zipcar_primary,
      pending: variables.zipcar_yellow,
      service: variables.zipcar_orange,
      in_washing: variables.zipcar_base_one,
      out_of_hand: variables.zipcar_text_normal,
      passive: variables.zipcar_text_normal,
      public: variables.zipcar_primary,
      private: variables.zipcar_base_two,
      on_hold: variables.zipcar_yellow,
      reserved: variables.zipcar_primary,
      personal: variables.zipcar_primary,
      coporate: variables.zipcar_base_one,
      family: variables.zipcar_orange,
      yes: variables.zipcar_primary,
      no: variables.zipcar_salmon,
      canceled: variables.zipcar_salmon,
      failed: variables.zipcar_salmon,
      success: variables.zipcar_primary,
      incoming: variables.zipcar_orange,
      outgoing: variables.zipcar_primary,
      debtfull: variables.zipcar_salmon,
      debtless: variables.zipcar_primary,
      approved: variables.zipcar_primary,
      notapproved: variables.zipcar_salmon,
      paid: variables.zipcar_primary,
      ended: variables.zipcar_base_two,
      updated: variables.zipcar_base_two,
      timeexpired: variables.zipcar_yellow,
      timefuture: variables.zipcar_orange,
      valid: variables.zipcar_primary,
      invalid: variables.zipcar_salmon,
      used: variables.zipcar_salmon,
      late: variables.zipcar_black,
      open: variables.zipcar_orange,
      solved: variables.zipcar_base_one,
      solvedHappy: variables.zipcar_primary,
    },
    priority: {
      "1": variables.zipcar_primary,
      "2": variables.zipcar_secondary,
    },
    assignee: {
      member: variables.zipcar_base_one,
      operation: variables.zipcar_base_two,
      iugo: variables.zipcar_primary,
      specific: variables.zipcar_purple,
    },
  },
  shadows,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          "-ms-overflow-style": "none",
        },
        "::-webkit-scrollbar": {
          display: "block",
        },
      },
    },
    MuiTable: {
      root: {
        tableLayout: "fixed",
      },
    },

    MuiTableCell: {
      head: {
        fontSize: "13px",
        padding: "12px 0px",
      },
      root: {
        fontSize: "14px",
        whiteSpace: "pre-wrap",
        padding: "8px 0px",
      },
    },

    MuiExpansionPanelSummary: {
      content: {
        margin: "0px",
      },
    },

    MuiButton: {
      root: {
        fontSize: "16px",
        textTransform: "none",
        fontWeight: "400",
        backgroundColor: "rgba(235, 235, 235, 0.25)",
      },
    },
    MuiFab: {
      root: {
        boxShadow: shadows[12],
      },
    },
    MuiCard: {
      root: {
        borderRadius: "8px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "11px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInput: {
      root: {
        backgroundColor: "rgba(235, 235, 235, 0.25)",
        borderRadius: 8,
        padding: 16,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiInput-root"]': {
          paddingBottom: 16,
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiToggleButton: {
      root: {
        backgroundColor: variables.zipcar_background,
        "&:hover": {
          backgroundColor: variables.zipcar_background,
        },   
        
        "&.Mui-selected": {
            backgroundColor: variables.zipcar_primary,
            "&:hover": {
              backgroundColor: variables.zipcar_primary+'!important'
            }          
        }
      },      
    },

  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
};

export default themeOptions;
