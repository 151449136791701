import moment from "moment";
import variables from "styles/_variables.scss";
import i18n from "app/i18n";
import { addDays, addMonths } from 'date-fns'

export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  let y = elm.offsetTop;
  let node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  const elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  const startY = currentYPosition();
  const stopY = elmYPosition(elm);
  const distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  let speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  const step = Math.round(distance / 25);
  let leapY = stopY > startY ? startY + step : startY - step;
  let timer = 0;
  if (stopY > startY) {
    for (let i = startY; i < stopY; i += step) {
      setTimeout(
        (function(leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function(leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  const difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  if (difference < 3600)
    return `${Math.floor(difference / 60)} minutes`;
  if (difference < 86400)
    return `${Math.floor(difference / 3660)} hours`;
  if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function generateRandomId() {
  const tempId = Math.random().toString();
  const uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  const params = {};
  const search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  const definitions = search.split("&");
  definitions.forEach(function(val, key) {
    const parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(" ");
}

export function isCardDateValid(data) {
  const splittedDate = data.card_expiry?.split("/");
  if (splittedDate?.length !== 2) return false;
  let expDate = moment(`01/${data.card_expiry}`, "DD/MM/YYYY");
  expDate = expDate.valueOf();
  let now = moment();
  now = now.valueOf();
  return expDate - now >= 7884000000;
}
export function getNearestRoundDate(date = new Date()) {
  date.setSeconds(0);
  date.setMilliseconds(0);
  const mins = date.getMinutes();
  // console.log("mins", mins);
  if (mins < 30) {
    date.setMinutes(30);
  } else {
    date.setMinutes(0);
    date.setHours(date.getHours() + 1);
  }
  // console.log("return date", date);
  return date;
}

export const getOptionPathAndIcon = option => {
  let path = "";
  let icon = "";
  switch (option.type) {
    case "vehicle":
      path = "/vehicles/list/";
      icon = "drive_eta";
      break;
    case "valet":
      path = "/valet/list/";
      icon = "person";
      break;
    case "driver":
      path = "/driver/list/";
      icon = "person";
      break;
    case "account":
      path = "/account/list/";
      icon = "people";
      break;
    case "location":
      path = "/locations/";
      icon = "location_city";
      break;
    case "reservation":
      path = "/reservations/list/";
      icon = "book_online";
      break;
    default:
      break;
  }
  return { path, icon };
};

export const configReactHighchart = (score, size, color) => {
	return {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false,
			height: size === 'large' ? 200 : size === 'small' ? 100 : size,
		},
		tooltip: {
			enabled: false,
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
		},
		plotOptions: {
			pie: {
				cursor: 'pointer',
				dataLabels: {
					enabled: false,
					format: '<b>{point.name}</b>: {point.percentage:.1f} %',
					style: {
						color: 'green',
					},
				},
				showInLegend: false,
			},
		},
		series: [
			{
				type: 'pie',
				innerSize: '80%',
				name: 'Rating',
				colorByPoint: true,
				data: [
					{
						name: 'Overall',
						y: score,
						color: `${color}`,
					},
					{
						name: 'Empty area',
						y: 100 - score,
						color: 'silver',
					},
				],
			},
		],
		title: {
			text: '',
		},
		loading: false,
		useHighStocks: false,
		credits: {
			enabled: false,
		},
	}
}

export const handleDecimalColor = (value) => {
	if (value >= 9) {
		return variables.zipcar_primary
	} else if (value >= 8) {
		return variables.zipcar_secondary
	} else if (value >= 7) {
		return variables.zipcar_yellow
	} else if (value >= 4.5) {
		return variables.zipcar_orange
	} else {
		return variables.zipcar_danger
	}
}

export const handleHundredColor = (value) => {
	if (value >= 90) {
		return variables.zipcar_primary
	} else if (value >= 80) {
		return variables.zipcar_secondary
	} else if (value >= 70) {
		return variables.zipcar_yellow
	} else if (value >= 45) {
		return variables.zipcar_orange
	} else {
		return variables.zipcar_danger
	}
}

export const getBarChartConfig = (data, height, width) => {
	const config = {
		config: {
			chart: {
				type: 'column',
				height: height || 300,
			},
			tooltip: {
				style: {
					padding: 10,
					fontWeight: 'bold',
				},
				pointFormat: '{series.name}: <b>{point.y:.1f}</b>',
			},
			plotOptions: {
				series: {
					colorByPoint: true,
				},
				column: {
					showInLegend: false,
				},
			},
			colors: data?.colors || ['#7cb6ec'],
			//data?.color ? [data.color] : ['#7cb6ec'],
			series: [
				{
					name: data?.tooltipTitle || data?.yAxisTitle,
					data:
						data?.data?.map((item) => {
							if (typeof item === 'string') {
								item = parseFloat(item)
							}
							return Number(item?.toFixed(1))
						}) || [],
				},
			],
			title: {
				text: data?.data && data?.title ? `${data.title}` : '',
			},
			loading: false,
			xAxis: {
				categories: data?.categories || [],
				title: { text: data?.xAxisTitle || '', enabled: true },
			},
			yAxis: {
				currentMin: 0,
				currentMax: 10,
				title: { text: data?.yAxisTitle || '', enabled: true },
			},
			useHighStocks: false,
			credits: {
				enabled: false,
			},
		},
	}
	return config.config
}

export const getLineChartConfig = (data, height) => {
	const config = {
		config: {
			chart: {
				type: 'line',
				height: height || 300,
			},
			tooltip: {
				style: {
					padding: 10,
					fontWeight: 'bold',
				},
				pointFormat: '{series.name}: <b>{point.y:.1f}</b>',
			},
			series: data?.data,
			title: {
				text: data?.data && data?.title ? `${data.title}` : '',
			},
			loading: false,
			xAxis: {
				categories: data?.categories || [],
				title: { text: data?.xAxisTitle || '', enabled: true },
			},
			yAxis: {
				currentMin: 0,
				currentMax: 10,
				title: { text: data?.yAxisTitle || '', enabled: true },
			},
			useHighStocks: false,
			credits: {
				enabled: false,
			},
		},
	}
	return config.config
}

export const getBarChartStackedConfig = (data, height) => {
	const config = {
		config: {
			chart: {
				type: 'column',
				height: height || 300,
			},
			tooltip: {
				pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.1f}</b> ({point.percentage:.1f}%)<br/>',
				shared: true,
			},
			plotOptions: {
				column: {
					stacking: data?.stacking,
				},
			},
			series: data?.data,
			title: {
				text: data?.data && data?.title ? `${data.title}` : '',
			},
			loading: false,
			xAxis: {
				categories: data?.categories || [],
				title: { text: data?.xAxisTitle || '', enabled: true },
			},
			yAxis: {
				currentMin: 0,
				currentMax: 10,
				title: { text: data?.yAxisTitle || '', enabled: true },
			},
			useHighStocks: false,
			credits: {
				enabled: false,
			},
		},
	}
	return config.config
}

export const dateFormatter = (date, timeZone, startOfDay, year, month, day, endOfMonth) => {
	const dateYear = year ? '' : date.getFullYear()
	const dateMonth = year ? '' : date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
	const dateDay = year ? '' : date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
	const dateTime = startOfDay ? '00:00:00.000' : '23:59:59.999'

	if (!year) {
		return `${dateYear}-${dateMonth}-${dateDay}T${dateTime}${timeZone}`
	} else if (!endOfMonth) {
		return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T${dateTime}${timeZone}`
	} else {
		return `${year}-${month < 10 ? '0' + month : month}-${new Date(year, month, 0).getDate()}T${dateTime}${timeZone}`
	}
}

export const getDiffInDaysInclusive = (startDate, endDate) => {
	const diffInMs = endDate.getTime() - startDate.getTime()
	const diffInDaysInclusive = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
	return diffInDaysInclusive
}

export const getNextSixMonthNames = (date = new Date(), next = 6) => {
	const currentDate = date
	const currentLanguage = i18n.language === 'tr' ? 'tr-TR' : 'en-GB'

	const nextSixMonths = []

	for (let i = 0; i < next; i++) {
		nextSixMonths.push(new Date(addMonths(currentDate, i)).toLocaleString(currentLanguage, { month: 'long' }))
	}
	return nextSixMonths
}

export const getNextDays = (date = new Date(), next = 14) => {
	const currentDate = date
	const currentLanguage = i18n.language === 'tr' ? 'tr-TR' : 'en-GB'

	const nextDays = []

	for (let i = 0; i < next; i++) {
		nextDays.push(new Date(addDays(currentDate, i)).toLocaleString(currentLanguage, { day: 'numeric', month: 'short' }))
	}

	return nextDays
}

export const monthDiff = (d1, d2, inclusive = false) => {
	if (d1.getTime() > d2.getTime()) {
		return null
	}
	var months = 0
	months = (d2.getFullYear() - d1.getFullYear()) * 12
	months -= d1.getMonth()
	months += d2.getMonth()
	if ((d1.getMonth() !== d2.getMonth() && d2.getDate() > d1.getDate()) || inclusive) {
		months++
	}
	return months
}

export const secondToHour = (second) => {
	let minute = parseInt(second / 60)
	let hour = parseInt(minute / 60)
	let total = ('0' + hour).slice(-2) + ':' + ('0' + (minute % 60)).slice(-2) + ':' + ('0' + (second % 60)).slice(-2)
	return total
}

export const parseToDateAndHour = (date, disallowAncientDate = false) =>
	parseDateToString(date, disallowAncientDate, {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	})

const parseDateToString = (date, disallowAncientDate, options) => {
	const parsedDate = new Date(date)
	if (disallowAncientDate && parsedDate.getFullYear() < 1990) return ''

	return parsedDate.toLocaleDateString('en-GB', options)
}