import { useLazyQuery, gql } from "@apollo/client";

const CHANGE_DRIVER_DEVICE_DETAILS = gql`
  query Q($hash: String!) {
    driver_device_request_log(where: { hash: { _eq: $hash } }) {
      id
      driver_id
      requested_at
      driver {
        id
        name
        surname
        phone
      }
      status
      hash
    }
  }
`;

const parseData = data => {
  if (data?.driver_device_request_log?.length) {
    return data?.driver_device_request_log.map(item => {
      return {
        driver: item.driver,
        hash: item.hash,
        status: item.status,
        request_at: item.request_at,
      };
    });
  } else {
    return [];
  }
};

export const useFetchDriverDeviceChangeData = () => {
  const [fetch, { data, loading, error }] = useLazyQuery(
    CHANGE_DRIVER_DEVICE_DETAILS
  );
  return {
    data: parseData(data),
    loading,
    error,
    fetchDriverDeviceData: ({ hash }) => {
      fetch({ variables: { hash } });
    },
  };
};
