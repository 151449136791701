/* eslint-disable */
// export const makeid = (length) => {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     var charactersLength = characters.length;
//     for ( var i = 0; i < length; i++ ) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     return result;
// }

import React from "react";

import config from "common/config";
import constants from "common/constants";
import {
  differenceInDays,
  format,
  isAfter,
  isBefore,
} from "date-fns";
import auth from "services/auth";
import i18n from "app/i18n";
import { duration } from "moment";

export const getToken = async () => {
  const user = auth.currentUser();
  if (user) {
    const token = await user.jwt(false).catch(console.error);
    return token;
  }

  return null;
};

export const ParseToDate = date => {
  if (!date) {
    console.warn("empty data");
    return null;
  }
  if (isNaN(new Date(date))) {
    console.warn(" datenan");
    return null;
  }
  return format(Date.parse(date), "dd.MM.yyyy");
};

export const ParseToDateAndHour = date => {
  if (!date) {
    // console.warn("empty data");
    return null;
  }
  if (isNaN(new Date(date))) {
    // console.warn(" datenan");
    return null;
  }
  const f = format(new Date(date), "dd.MM.yyyy-HH:mm");

  return f;
};

export const ParseToFormatDate = (date, f) => {
  if (!date) return null;

  return format(Date.parse(date), f);
};

export const ParseHour = date => {
  if (!date) {
    console.warn("empty data");
    return null;
  }
  if (isNaN(new Date(date))) {
    console.warn(" datenan");
    return null;
  }
  const f = format(new Date(date), "HH:mm");
  return f;
};

export const parseDaysOld = date => {
  if (!date) {
    console.warn("empty data");
    return null;
  }
  if (isNaN(new Date(date))) {
    console.warn(" datenan");
    return null;
  }

  return differenceInDays(new Date(), Date.parse(date));
};

export const calculateDuration = (startDate, endDate) => {
  if (!startDate || !endDate) {
    console.warn("empty data");
    return null;
  }
  if (isNaN(new Date(startDate)) || isNaN(new Date(endDate))) {
    console.warn(" datenan");
    return null;
  }

  if (
    new Date(endDate).getFullYear() - new Date().getFullYear() >
    2
  ) {
    endDate = new Date();
  }

  const difference =
    new Date(endDate).getTime() - new Date(startDate).getTime();
  let minutes = Math.round(difference / 60000);

  if (minutes < 60) {
    return minutes + " " + i18n.t("time.minShort");
  }

  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;

  if (minutes === 0) {
    return hours + " " + i18n.t("time.hourShort");
  }

  return (
    hours +
    " " +
    i18n.t("time.hourShort") +
    " " +
    minutes +
    " " +
    i18n.t("time.minShort")
  );
};

export const NumberWithDots = x => {
  if (!x) return "0";
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  if (parts.length > 1) {
    if (parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
    }
  }

  return parts.join(",");
};

export const ConvertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const calculateDaysAgo = date => {
  const d = Date.parse(date);
  if (Number.isNaN(d)) {
    return -1;
  }
  return Math.round(
    (new Date().getTime() - new Date(date).getTime()) /
      (1000 * 3600 * 24)
  );
};

// 0 before, 1 between, 2 after
export const calculateDateInterval = (start, end) => {
  let s = new Date(start);
  let e = new Date(end);
  let now = new Date();
  if (isAfter(now, e)) {
    return 2;
  } else if (isBefore(now, s)) {
    return 0;
  } else {
    return 1;
  }
};

export const updateArrayIndex = (arr, index, newValue) => {
  return [
    ...arr.slice(0, index),
    newValue,
    ...arr.slice(index + 1, arr.length),
  ];
};

export const removeArrayItem = (arr, index) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1, arr.length),
];

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(
    /^(1|)?(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/
  );
  if (match) {
    const intlCode = match[1] ? "90 " : "";
    return [
      intlCode,
      "(",
      match[2],
      ") ",
      match[3],
      " ",
      match[4],
      " ",
      match[5],
      " ",
      match[6],
    ].join("");
  }
  return null;
};
export const getMediaURL = (bucket, name) =>
  `${config.mediaURI}/api/v1/getMedia?bucket=${bucket}&name=${name}`;

export const deleteMediaURL = (bucket, name) =>
  `${config.mediaURI}/api/v1/deleteMedia?bucket=${bucket}&name=${name}`;

export const getTicketMediaURL = (ticketId, file) =>
  getMediaURL(constants.BUCKET.TICKET, `${ticketId}/${file}`);

export const getTicketDeleteURL = (ticketId, file) =>
  deleteMediaURL(constants.BUCKET.TICKET, `${ticketId}/${file}`);

export const downloadMedia = async (name, url) => {
  const token = await getToken();
  return fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then(res => res.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.click();
    });
};

export const listMediaURL = (bucket, path) =>
  `${config.mediaURI}/api/v1/listMedia?bucket=${bucket}&path=${path}`;

export const getCurrentUserId = () => {
  let user = auth.currentUser();
  // console.log("CURRENT USER:", user)
  if (user) {
    return user.id;
  }
  return null;
};

export const getUserRole = () => {
  let user = auth.currentUser();
  if (user) {
    return user.role;
  }
  return null;
};

export const uploadFile = async (bucket, path, file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("bucket", bucket);
  formData.append("filePath", `${path}/${file.name}`);

  const token = await getToken();

  return fetch(`${config.mediaURI}/api/v1/saveMedia`, {
    method: "POST",
    body: formData,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then(r => r.json())
    .then(r => {
      if (r.success) {
        return r;
      }
      throw new Error(r.message);
    });
};

export const uploadTicketFile = (ticketId, file) =>
  uploadFile(constants.BUCKET.TICKET, ticketId, file);

export const useScrollTo = () => {
  const ref = React.useRef(null);
  const scrollToElement = React.useMemo(
    () => () => {
      if (ref.current && ref.current.scrollIntoView) {
        ref.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    []
  );
  return [ref, scrollToElement];
};

export const checkTCSyntax = tc => {
  var tek = 0,
    cift = 0,
    sonuc = 0,
    TCToplam = 0,
    i = 0,
    hatali = [
      11111111110,
      22222222220,
      33333333330,
      44444444440,
      55555555550,
      66666666660,
      7777777770,
      88888888880,
      99999999990,
    ];

  if (tc.length != 11) {
    return false;
  }
  if (isNaN(tc)) {
    return false;
  }
  if (tc[0] == 0) {
    return false;
  }

  tek =
    parseInt(tc[0]) +
    parseInt(tc[2]) +
    parseInt(tc[4]) +
    parseInt(tc[6]) +
    parseInt(tc[8]);
  cift =
    parseInt(tc[1]) +
    parseInt(tc[3]) +
    parseInt(tc[5]) +
    parseInt(tc[7]);

  tek = tek * 7;
  sonuc = Math.abs(tek - cift);
  if (sonuc % 10 != tc[9]) {
    return false;
  }

  for (var i = 0; i < 10; i++) {
    TCToplam += parseInt(tc[i]);
  }

  if (TCToplam % 10 != tc[10]) {
    return false;
  }

  if (hatali.toString().indexOf(tc) != -1) {
    return false;
  }

  return true;
};
export const billingButtonType = (
  invoiceId,
  refundId,
  type,
  isPaid,
  itemCount,
  amount,
  paidBack
) => {
  // console.log("aaaaaa", invoiceId, refundId, type, isPaid, itemCount)
  if (type === constants.ITEM_TYPE.CANCELLED) {
    return constants.BILL_ACTION_BUTTON_STATE.CANCELLED;
  }
  if (paidBack && (type !== constants.ITEM_TYPE.PAYMENT || isPaid)) {
    return constants.BILL_ACTION_BUTTON_STATE.NOACTION;
  }
  if (
    !invoiceId &&
    refundId &&
    type === constants.ITEM_TYPE.RETURN &&
    isPaid
  ) {
    return constants.BILL_ACTION_BUTTON_STATE.NOACTION;
  }
  if (
    !invoiceId &&
    refundId &&
    type === constants.ITEM_TYPE.PAYMENT &&
    isPaid &&
    itemCount >= 1 &&
    amount != 0
  ) {
    return constants.BILL_ACTION_BUTTON_STATE.REFUND;
  }
  if (
    !invoiceId &&
    refundId &&
    type === constants.ITEM_TYPE.RETURN &&
    !isPaid
  ) {
    return constants.BILL_ACTION_BUTTON_STATE.NOACTION; // FIXME
  }
  if (
    !invoiceId &&
    refundId &&
    type === constants.ITEM_TYPE.PAYMENT &&
    !isPaid
  ) {
    return constants.BILL_ACTION_BUTTON_STATE.CHARGE;
  }
  if (
    invoiceId &&
    !refundId &&
    type === constants.ITEM_TYPE.PAYMENT &&
    isPaid &&
    itemCount >= 1 &&
    amount != 0
  ) {
    return constants.BILL_ACTION_BUTTON_STATE.REFUND;
  }
  if (
    invoiceId &&
    !refundId &&
    type === constants.ITEM_TYPE.PAYMENT &&
    !isPaid
  ) {
    return constants.BILL_ACTION_BUTTON_STATE.CHARGE;
  }
  // if(invoiceId && refundId && type === constants.ITEM_TYPE.RETURN && !isPaid){
  //   return constants.BILL_ACTION_BUTTON_STATE.NOACTION// FIXME
  // }
  // if(invoiceId && refundId && type === constants.ITEM_TYPE.RETURN && !isPaid){
  //   return constants.BILL_ACTION_BUTTON_STATE.NOACTION
  // }
  return constants.BILL_ACTION_BUTTON_STATE.NOACTION;
};

export const getDistanceInMetersBetweenLocations = (
  lat1,
  lon1,
  lat2,
  lon2
) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    dist = dist * 1609; // to meters

    return dist;
  }
};

export const getRoundedDate = (minutes, d = new Date()) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

  return roundedDate;
};

export const vehicleActions = new Map([
  [
    "IMMOBILIZER_ON",
    {
      icon: "leak_add",
      name: i18n.t("historyModal.immobilizerOn"),
      color: "salmon",
    },
  ],
  [
    "IMMOBILIZER_OFF",
    {
      icon: "leak_remove",
      name: i18n.t("historyModal.immobilizerOff"),
      color: "salmon",
    },
  ],
  [
    "DOOR_ON",
    {
      icon: "lock_open",
      name: i18n.t("historyModal.doorUnlock"),
      color: "secondary",
    },
  ],
  [
    "DOOR_OFF",
    {
      icon: "lock",
      name: i18n.t("historyModal.doorLock"),
      color: "secondary",
    },
  ],
  [
    "HORN_ON",
    {
      icon: "volume_up",
      name: i18n.t("historyModal.hornOn"),
      color: "base_two",
    },
  ],
  [
    "HORN_OFF",
    {
      icon: "volume_off",
      name: i18n.t("historyModal.hornOff"),
      color: "base_two",
    },
  ],
  [
    "KEYLOCK_ON",
    {
      icon: "lock_open",
      name: i18n.t("historyModal.keyUnlock"),
      color: "secondary",
    },
  ],
  [
    "KEYLOCK_OFF",
    {
      icon: "lock",
      name: i18n.t("historyModal.keyLock"),
      color: "secondary",
    },
  ],
  [
    "IGNITION_ON",
    {
      icon: "whatshot",
      name: i18n.t("historyModal.ignitionOn"),
      color: "yellow",
    },
  ],
  [
    "IGNITION_OFF",
    {
      icon: "whatshot",
      name: i18n.t("historyModal.ignitionOff"),
      color: "yellow",
    },
  ],
  [
    "RESERVATION_START",
    {
      icon: "alarm_on",
      name: i18n.t("historyModal.reservationStart"),
      color: "orange",
    },
  ],
  [
    "RESERVATION_END",
    {
      icon: "alarm_off",
      name: i18n.t("historyModal.reservationEnd"),
      color: "orange",
    },
  ],
  [
    "RESERVATION_AUTOEND",
    {
      icon: "alarm_off",
      name: i18n.t("historyModal.reservationEnd"),
      color: "orange",
    },
  ],
  [
    "RESERVATION_MAKE",
    {
      icon: "alarm_add",
      name: i18n.t("historyModal.reservationMake"),
      color: "orange",
    },
  ],
  [
    "RESERVATION_UPDATE",
    {
      icon: "update",
      name: i18n.t("historyModal.reservationUpdate"),
      color: "orange",
    },
  ],
  [
    "RESERVATION_CANCEL",
    {
      icon: "block",
      name: i18n.t("historyModal.reservationCancel"),
      color: "orange",
    },
  ],
  [
    "RESERVATION_CREDIT",
    {
      icon: "money",
      name: i18n.t("historyModal.reservationCredit"),
      color: "orange",
    },
  ],
  [
    "BLUETOOTH_CONNECTED",
    {
      icon: "bluetooth_audio",
      name: i18n.t("historyModal.bluetoothConnected"),
      color: "gray",
    },
  ],
  [
    "BLUETOOTH_DISCONNECTED",
    {
      icon: "bluetooth_disabled",
      name: i18n.t("historyModal.bluetoothDisconnected"),
      color: "gray",
    },
  ],
  [
    "LOCATION_UPDATE",
    {
      icon: "edit_location",
      name: i18n.t("historyModal.locationUpdated"),
      color: "gray",
    },
  ],
]);

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidTaxNumber = vkn => {
  let tmp;
  let sum = 0;

  if (vkn && vkn.length === 10) {
    let lastDigit = parseInt(vkn.charAt(9));

    for (let i = 0; i < 9; i++) {
      let digit = parseInt(vkn.charAt(i));
      tmp = (digit + 10 - (i + 1)) % 10;
      sum = parseInt(
        tmp == 9
          ? sum + tmp
          : sum + ((tmp * Math.pow(2, 10 - (i + 1))) % 9)
      );
    }
    return lastDigit == (10 - (sum % 10)) % 10;
  }

  console.log("Checking is valid tax number", vkn);

  return false;
};

export const getRandomColor = index => {
  if (index || index === 0) {
    return constants.COLORS_RANDOM[
      index % constants.COLORS_RANDOM.length
    ];
  } else {
    return constants.COLORS_RANDOM[
      Math.floor(Math.random() * constants.COLORS_RANDOM.length)
    ];
  }
};

export const getGooglePolylineFromGeography = obj => {
  // console.log("getGooglePolylineFromGeography", obj);
  if (!obj) return undefined;
  if (obj?.features[0]?.geometry?.coordinates) {
    // console.log("getGooglePolylineFromGeography parsing");
    return obj.features[0].geometry.coordinates.map(el => ({
      lng: el[0],
      lat: el[1],
    }));
  }
  return undefined;
};

export const getObjectFromGeograpyPoint = obj => {
  if (!obj) return undefined;
  if (obj.coordinates && obj.coordinates.length === 2) {
    return {
      lat: obj.coordinates[1],
      lng: obj.coordinates[0],
    };
  }
};

export const getGeograpyPointFromObject = obj => {
  if (!obj || obj.lat === 0 || obj.lon === 0) return null;

  return {
    type: "Point",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:EPSG::4326",
      },
    },
    coordinates: [obj.lng, obj.lat],
  };
};

export const getPossibleTurkishWords = word => {
  let results = [];

  for (let c of word) {
    // console.log(c, results);
    if (c === "c" || c === "ç") {
      if (results.length === 0) {
        results.push("c");
        results.push("ç");
      } else {
        let temp1 = addCharToEndOfEachElement(results, "c");
        let temp2 = addCharToEndOfEachElement(results, "ç");
        results = [];
        results.push(...temp1);
        results.push(...temp2);
      }
    } else if (c === "ı" || c === "i" || c === "İ") {
      if (results.length === 0) {
        results.push("ı");
        results.push("i");
        results.push("İ");
      } else {
        let temp1 = addCharToEndOfEachElement(results, "ı");
        let temp2 = addCharToEndOfEachElement(results, "i");
        let temp3 = addCharToEndOfEachElement(results, "İ");
        results = [];
        results.push(...temp1);
        results.push(...temp2);
        results.push(...temp3);
      }
    } else if (c === "o" || c === "ö") {
      if (results.length === 0) {
        results.push("o");
        results.push("ö");
      } else {
        let temp1 = addCharToEndOfEachElement(results, "o");
        let temp2 = addCharToEndOfEachElement(results, "ö");
        results = [];
        results.push(...temp1);
        results.push(...temp2);
      }
    } else if (c === "g" || c === "ğ") {
      if (results.length === 0) {
        results.push("g");
        results.push("ğ");
      } else {
        let temp1 = addCharToEndOfEachElement(results, "g");
        let temp2 = addCharToEndOfEachElement(results, "ğ");
        results = [];
        results.push(...temp1);
        results.push(...temp2);
      }
    } else if (c === "s" || c === "ş") {
      if (results.length === 0) {
        results.push("s");
        results.push("ş");
      } else {
        let temp1 = addCharToEndOfEachElement(results, "s");
        let temp2 = addCharToEndOfEachElement(results, "ş");
        results = [];
        results.push(...temp1);
        results.push(...temp2);
      }
    } else if (c === "u" || c === "ü") {
      if (results.length === 0) {
        results.push("u");
        results.push("ü");
      } else {
        let temp1 = addCharToEndOfEachElement(results, "u");
        let temp2 = addCharToEndOfEachElement(results, "ü");
        results = [];
        results.push(...temp1);
        results.push(...temp2);
      }
    } else {
      results = addCharToEndOfEachElement(results, c);
    }
  }
  // console.log("POSS", results);
  return results;
};

const addCharToEndOfEachElement = (array, char) => {
  // console.log("addCharToEndOfEachElement start", array, char);
  if (array.length === 0) {
    array.push(char);
    return array;
  }
  let temp = [];
  for (let i = 0; i < array.length; i++) {
    temp.push(array[i] + char);
  }
  // console.log("addCharToEndOfEachElement end", temp);
  return temp;
};

export const calculateContractEndDate = (
  rentalStart,
  rentalDuration,
  periodInSeconds
) => {
  let d = new Date(rentalStart);
  d = new Date(
    d.setSeconds(d.getSeconds() + rentalDuration * periodInSeconds)
  );
  return ParseToDate(d);
};

export const calculateDueDate = (renewDate, frequency) => {
  const renewDateInMS = Date.parse(renewDate);
  const currentTimeInMs = new Date();
  const timeDiffInMs = currentTimeInMs - renewDateInMS;

  if (frequency === "MONTHLY") {
    const monthDiff = Math.ceil(
      timeDiffInMs / 1000 / 60 / 60 / 24 / 30
    );
    return ParseToDate(
      new Date(
        new Date(renewDate).setMonth(
          new Date(renewDate).getMonth() + monthDiff
        )
      )
    );
  } else if (frequency === "ANNUALLY") {
    const yearDiff = Math.ceil(
      timeDiffInMs / 1000 / 60 / 60 / 24 / 30 / 12
    );
    return ParseToDate(
      new Date(
        new Date(renewDate).setFullYear(
          new Date(renewDate).getFullYear() + yearDiff
        )
      )
    );
  } else {
    return "—";
  }
};

export const maskString = (
  str,
  unMaskedLength,
  direction,
  shouldSplit = true
) => {
  if (typeof str !== "string") {
    return null;
  }

  if (shouldSplit) {
    const words = str.split(" ");

    const maskedWords = words.map(word => {
      if (word.length < unMaskedLength) {
        return word; // If word length is less than the specified length, return the word unchanged
      }

      if (direction === "left") {
        return (
          word.substring(0, unMaskedLength) +
          "*".repeat(word.length - unMaskedLength)
        );
      } else if (direction === "right") {
        return (
          "*".repeat(word.length - unMaskedLength) +
          word.slice(-unMaskedLength)
        );
      } else {
        return null;
      }
    });

    return maskedWords.join(" ");
  } else {
    if (str.length < unMaskedLength) {
      return str; // If string length is less than the specified length, return the string unchanged
    }

    if (direction === "left") {
      return (
        str.substring(0, unMaskedLength) +
        "*".repeat(str.length - unMaskedLength)
      );
    } else if (direction === "right") {
      return (
        "*".repeat(str.length - unMaskedLength) +
        str.slice(-unMaskedLength)
      );
    } else {
      return null;
    }
  }
};
