import { useEffect, useState } from "react";
import { client } from "services/gql/apollo";
import { useQuery, gql } from "@apollo/client";
import {
  addDays,
  addMonths,
  endOfWeek,
  getDaysInMonth,
  startOfWeek,
} from "date-fns";
import i18n from "app/i18n";

// DEPRECATED PLS DO NOT USE THIS INSTEAD USE loadTableData
/**
 * Encapsulate generic table hook logic for MaterialTable interface
 * @param query - gql query object
 * @param parseQuery {function} - table query parse to your gql query variables
 * @param parseResponse {function} - parse response
 * @returns {{load: function, data: any, loading: boolean, error: Error}}
 *
 *
 *
 */

const regionURL = "https://kurumsal.zipcarturkiye.com/region/v1";

export function useFetchTable(
  query,
  parseResponse,
  parseQuery = q => q
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  return {
    load: async q => {
      const orderBy = {};
      if (q.orderBy && q.orderBy.field) {
        orderBy[q.orderBy.field] = q.orderDirection;
      }

      const where = q.filters.reduce(
        (acc, filter) => ({
          ...acc,
          ...filter.column.filterToGql(filter),
        }),
        {}
      );
      console.log("q.pageSize:", q.pageSize);
      const variables = parseQuery(
        {
          limit: q.pageSize,
          offset: q.page * q.pageSize,
          search: `%${q.search}%`,
          orderBy,
          where,
        },
        q
      );
      setLoading(true);
      const result = await client
        .query({ query, variables })
        .then(res => {
          setData(res.data);
          return res;
        })
        .catch(setError);
      setLoading(false);

      return { page: q.page, ...parseResponse(result.data, q) };
    },
    loading,
    error,
    data,
  };
}

const pathToJsonValue = ([key, ...rest], value) => {
  return rest.length === 0
    ? { [key]: value }
    : { [key]: pathToJsonValue(rest, value) };
};

export const loadTableData = (
  tableName,
  fields,
  searchFields,
  fetchPolicy
) => {
  const query = gql`
  query TABLE_QUERY_${tableName.toUpperCase()}(
    $limit: Int!
    $offset: Int!
    $search: ${tableName}_bool_exp!
    $orderBy: ${tableName}_order_by!
    $where: ${tableName}_bool_exp!
  ) {
    total: ${tableName}_aggregate(
      where: {
        _and: [$search, $where]
      }
    ) {
      aggregate {
        count
      }
    }
    ${tableName}(
      limit: $limit
      offset: $offset
      where: {
        _and: [$search, $where]
      }
      order_by: [$orderBy]
    ) {
      # fields to fetch
      ${fields}
    }
  }
  `;

  return async (tableQuery, where = {}) => {
    let orderByQueryArr;

    if (tableQuery.orderBy && tableQuery.orderBy.field) {
      let orderByQuery = tableQuery.orderBy.field;
      orderByQueryArr = orderByQuery.split(".");
      orderByQueryArr = orderByQueryArr.filter(
        name => name !== "aggregate"
      );
    }

    const orderBy =
      tableQuery.orderBy &&
      tableQuery.orderBy.field &&
      orderByQueryArr
        ? pathToJsonValue(orderByQueryArr, tableQuery.orderDirection)
        : {};

    const searchText = tableQuery.search || "";
    const searchWhere = {
      _ilike: `%${searchText}%`,
    };

    const searchNumber = Number.parseInt(searchText, 10);
    const numberSearchQuery = {
      _eq: searchNumber,
    };
    const search = {
      _or: searchFields.reduce((acc, field) => {
        if (field.includes("_id") || field === "id") {
          return Number.isNaN(searchNumber)
            ? acc
            : [
                ...acc,
                pathToJsonValue(field.split("."), numberSearchQuery),
              ];
        }

        return [
          ...acc,
          pathToJsonValue(field.split("."), searchWhere),
        ];
      }, []),
    };

    try {
      const { page, pageSize } = tableQuery;
      const result = await client.query({
        query,
        variables: {
          limit: pageSize,
          offset: page * pageSize,
          search,
          orderBy,
          where,
        },
        fetchPolicy: fetchPolicy || "no-cache",
      });

      return {
        page,
        data: result.data[tableName].map(i => ({
          ...i,
        })),
        totalCount: result.data.total.aggregate.count,
      };
    } catch (e) {
      console.error(e);
      return {
        page: 1,
        data: [],
        totalCount: 0,
      };
    }
  };
};

export const loadExcelData = (
  tableName,
  fields,
  columnFields,
  limit,
  parseArray,
  additionalFieldHeaders,
  additionalFields
) => {
  const query = gql`
    query TABLE_QUERY_${tableName.toUpperCase()}(
        $orderBy: ${tableName}_order_by!
        $where: ${tableName}_bool_exp! 
        $limit: Int!
        $offset: Int!       
        ${additionalFieldHeaders ? additionalFieldHeaders : ""}
    ) {       
        ${tableName}(
        where: $where
        order_by: $orderBy
        limit:  $limit 
        offset: $offset
        ${additionalFields ? additionalFields : ""}
        ) {
        # fields to fetch
        ${fields}
        count
        }
    }
    `;
  const columnLabels = [];
  const columnPaths =
    columnFields?.length > 0 && typeof columnFields[0] === "object"
      ? columnFields.map(item => {
          columnLabels.push(item.label);
          if (typeof item.path === "function") {
            return item.path();
          } else {
            return item.path.split(".");
          }
        })
      : columnFields.map(item => {
          const temp = item.split(".");
          columnLabels.push(
            temp?.length > 0
              ? temp?.length > 1
                ? temp.reduce(
                    (acc, curr) =>
                      acc === "" ? curr : acc + "_" + curr,
                    ""
                  )
                : temp[0]
              : "noname"
          );
          return temp;
        });

  return async (
    tableQuery,
    where = {},
    additionalFields = [],
    values
  ) => {
    const orderBy =
      tableQuery.orderBy && tableQuery.orderBy.field
        ? pathToJsonValue(
            tableQuery.orderBy.field.split("."),
            tableQuery.orderDirection
          )
        : {};

    try {
      let result;
      const variables = {
        orderBy,
        where,
        limit,
        offset: 0,
      };
      additionalFields.forEach((field, index) => {
        variables[field] = values[index];
      });
      result = await client.query({
        query,
        variables: variables,
      });

      return {
        data: parseTableDataForExcelExport(
          result.data[tableName].data,
          columnPaths,
          parseArray,
          false
        ),
        labels: columnLabels,
        totalCount: result.data.count,
      };
    } catch (e) {
      console.error(e);
      return {
        data: [],
        totalCount: 0,
      };
    }
  };
};

export const loadTableDataBasic = (
  tableName,
  fields,
  columnFields
) => {
  const query = gql`
  query TABLE_QUERY_${tableName.toUpperCase()}(
    $orderBy: ${tableName}_order_by!
    $where: ${tableName}_bool_exp!
  ) {
    total: ${tableName}_aggregate(
      where: {
        _and: [$where]
      }
    ) {
      aggregate {
        count
      }
    }
    ${tableName}(
      where: {
        _and: [$where]
      }
      order_by: [$orderBy]
    ) {
      # fields to fetch
      ${fields}
    }
  }
  `;
  const columnLabels = [];
  const columnPaths =
    columnFields?.length > 0 && typeof columnFields[0] === "object"
      ? columnFields.map(item => {
          columnLabels.push(item.label);
          return item.path.split(".");
        })
      : columnFields.map(item => {
          const temp = item.split(".");
          columnLabels.push(
            temp?.length > 0
              ? temp?.length > 1
                ? `${temp[0]}_${temp[temp?.length - 1]}`
                : temp[0]
              : "noname"
          );
          return temp;
        });
  // console.log("columnPaths, columnLabels:", columnPaths, columnLabels)
  return async (tableQuery, where = {}) => {
    const orderBy =
      tableQuery.orderBy && tableQuery.orderBy.field
        ? pathToJsonValue(
            tableQuery.orderBy.field.split("."),
            tableQuery.orderDirection
          )
        : {};

    try {
      console.log("trying to query again");
      const result = await client.query({
        query,
        variables: {
          orderBy,
          where,
        },
      });
      // console.log("excelData:", parseTableDataForExcelExport(result.data[tableName],columnPaths))
      return {
        data: parseTableDataForExcelExport(
          result.data[tableName],
          columnPaths
        ),
        labels: columnLabels,
        totalCount: result.data.total.aggregate.count,
      };
    } catch (e) {
      console.error(e);
      return {
        data: [],
        totalCount: 0,
      };
    }
  };
};

export const loadTableDataNew = (
  tableName,
  fields,
  searchFields,
  fetchPolicy
) => {
  const query = gql`
    query TABLE_QUERY_${tableName.toUpperCase()}(
      $limit: Int!
      $offset: Int!
      $orderBy: ${tableName}_order_by!
      $where: ${tableName}_bool_exp!
      $search: ${tableName}_bool_exp!
    ) {
        ${tableName}(
        limit: $limit
        offset: $offset
        where: {
            _and: [$search, $where]
        }
        order_by: $orderBy
    ) {
        # fields to fetch
        ${fields}
    }
    }
    `;
  return async (tableQuery, where = {}, searchTextFromOutside) => {
    let orderByQueryArr;
    if (tableQuery.orderBy && tableQuery.orderBy.field) {
      let orderByQuery = tableQuery.orderBy.field;
      orderByQueryArr = orderByQuery.split(".");
      orderByQueryArr = orderByQueryArr.filter(
        name => name !== "aggregate"
      );
    }

    const orderBy =
      tableQuery.orderBy &&
      tableQuery.orderBy.field &&
      orderByQueryArr
        ? pathToJsonValue(orderByQueryArr, tableQuery.orderDirection)
        : {};

    const searchText =
      tableQuery.search || searchTextFromOutside || "";
    const searchWhere = {
      _ilike: `%${searchText}%`,
    };

    const searchNumber = Number.parseInt(searchText, 10);
    const numberSearchQuery = {
      _eq: searchNumber,
    };
    // console.log('searchFields', searchFields)
    const search = {
      _or: searchFields.reduce((acc, field) => {
        let fieldType = field?.type;
        field = field?.name ? field?.name : field;
        if (
          fieldType !== "string" &&
          (field.includes("_id") || field === "id")
        ) {
          return Number.isNaN(searchNumber) ||
            (!Number.isNaN(searchNumber) && searchNumber > 100000000)
            ? acc
            : [
                ...acc,
                pathToJsonValue(field.split("."), numberSearchQuery),
              ];
        }

        return [
          ...acc,
          pathToJsonValue(field.split("."), searchWhere),
        ];
      }, []),
    };

    if (search._or && search._or.length === 0) {
      delete search._or;
    }
    // console.log('orderBy', orderBy)
    // console.log('searchFields', searchFields)
    // console.log('searchText', searchText)
    // console.log('search', search)
    // console.log('where', where)

    try {
      const { page, pageSize } = tableQuery;
      let result;

      result = await client.query({
        query,
        variables: {
          limit: pageSize,
          offset: page * pageSize,
          search:
            searchFields.length > 0 && searchText && searchText !== ""
              ? search
              : {},
          orderBy,
          where,
        },
        fetchPolicy: fetchPolicy || "no-cache",
      });

      if (result?.data?.[tableName]?.count < page * pageSize) {
        result = await client.query({
          query,
          variables: {
            limit: pageSize,
            offset: 0,
            search:
              searchFields.length > 0 &&
              searchText &&
              searchText !== ""
                ? search
                : {},
            orderBy,
            where,
          },
          fetchPolicy: fetchPolicy || "no-cache",
        });
      }

      console.log("RESULT=", result);
      // console.log("TABLE DATAAAAAA", result.data[tableName].map((i) => ({
      //     ...i,
      // })),)
      return {
        page:
          result.data[tableName].count < page * pageSize ? 0 : page,
        data: result.data[tableName].data.map(i => ({
          ...i,
        })),
        // totalCount: result.data.total.aggregate.count,
        totalCount: result.data[tableName].count || 0,
      };
    } catch (e) {
      console.error(e);
      return {
        page: 1,
        data: [],
        totalCount: 0,
      };
    }
  };
};

export const loadTableDataNewArchive = (
  tableName,
  fields,
  searchFields,
  fetchPolicy
) => {
  const query = gql`
    query TABLE_QUERY_${tableName.toUpperCase()}(
      $limit: Int!
      $offset: Int!
      $orderBy: ${tableName}_order_by!
      $where: ${tableName}_bool_exp!
      $search: ${tableName}_bool_exp!
      $archive_search: Boolean
    ) {
        ${tableName}(
        limit: $limit
        offset: $offset
        where: {
            _and: [$search, $where]
        }
        order_by: $orderBy
        archive_search: $archive_search
    ) {
        # fields to fetch
        ${fields}
    }
    }
    `;
  return async (
    tableQuery,
    where = {},
    searchTextFromOutside,
    archive
  ) => {
    let orderByQueryArr;
    if (tableQuery.orderBy && tableQuery.orderBy.field) {
      let orderByQuery = tableQuery.orderBy.field;
      orderByQueryArr = orderByQuery.split(".");
      orderByQueryArr = orderByQueryArr.filter(
        name => name !== "aggregate"
      );
    }

    const orderBy =
      tableQuery.orderBy &&
      tableQuery.orderBy.field &&
      orderByQueryArr
        ? pathToJsonValue(orderByQueryArr, tableQuery.orderDirection)
        : {};

    const searchText =
      tableQuery.search || searchTextFromOutside || "";
    const searchWhere = {
      _ilike: `%${searchText}%`,
    };

    const searchNumber = Number.parseInt(searchText, 10);
    const numberSearchQuery = {
      _eq: searchNumber,
    };
    // console.log('searchFields', searchFields)
    const search = {
      _or: searchFields.reduce((acc, field) => {
        let fieldType = field?.type;
        field = field?.name ? field?.name : field;
        if (
          fieldType !== "string" &&
          (field.includes("_id") || field === "id")
        ) {
          return Number.isNaN(searchNumber) ||
            (!Number.isNaN(searchNumber) && searchNumber > 100000000)
            ? acc
            : [
                ...acc,
                pathToJsonValue(field.split("."), numberSearchQuery),
              ];
        }

        return [
          ...acc,
          pathToJsonValue(field.split("."), searchWhere),
        ];
      }, []),
    };

    if (search._or && search._or.length === 0) {
      delete search._or;
    }
    // console.log('orderBy', orderBy)
    // console.log('searchFields', searchFields)
    // console.log('searchText', searchText)
    // console.log('search', search)
    // console.log('where', where)

    try {
      const { page, pageSize } = tableQuery;
      let result;

      result = await client.query({
        query,
        variables: {
          limit: pageSize,
          offset: page * pageSize,
          search:
            searchFields.length > 0 && searchText && searchText !== ""
              ? search
              : {},
          orderBy,
          where,
          archive_search: archive,
        },
        fetchPolicy: fetchPolicy || "no-cache",
      });

      if (result?.data?.[tableName]?.count < page * pageSize) {
        result = await client.query({
          query,
          variables: {
            limit: pageSize,
            offset: 0,
            search:
              searchFields.length > 0 &&
              searchText &&
              searchText !== ""
                ? search
                : {},
            orderBy,
            where,
            archive_search: archive,
          },
          fetchPolicy: fetchPolicy || "no-cache",
        });
      }

      console.log("RESULT=", result);
      // console.log("TABLE DATAAAAAA", result.data[tableName].map((i) => ({
      //     ...i,
      // })),)
      return {
        page:
          result.data[tableName].count < page * pageSize ? 0 : page,
        data: result.data[tableName].data.map(i => ({
          ...i,
        })),
        // totalCount: result.data.total.aggregate.count,
        totalCount: result.data[tableName].count || 0,
      };
    } catch (e) {
      console.error(e);
      return {
        page: 1,
        data: [],
        totalCount: 0,
      };
    }
  };
};

export const loadTableDataNewWithoutAnd = (
  tableName,
  fields,
  searchFields,
  fetchPolicy
) => {
  const query = gql`
    query TABLE_QUERY_${tableName.toUpperCase()}(
      $limit: Int!
      $offset: Int!
      $orderBy: ${tableName}_order_by!
      $where: ${tableName}_bool_exp!
    ) {
        ${tableName}(
        limit: $limit
        offset: $offset
        where: $where
        order_by: $orderBy
    ) {
        # fields to fetch
        ${fields}
    }
    }
    `;
  return async (tableQuery, where = {}, searchTextFromOutside) => {
    let orderByQueryArr;
    if (tableQuery.orderBy && tableQuery.orderBy.field) {
      let orderByQuery = tableQuery.orderBy.field;
      orderByQueryArr = orderByQuery.split(".");
      orderByQueryArr = orderByQueryArr.filter(
        name => name !== "aggregate"
      );
    }

    const orderBy =
      tableQuery.orderBy &&
      tableQuery.orderBy.field &&
      orderByQueryArr
        ? pathToJsonValue(orderByQueryArr, tableQuery.orderDirection)
        : {};

    const searchText =
      tableQuery.search || searchTextFromOutside || "";
    const searchWhere = {
      _ilike: `%${searchText}%`,
    };

    const searchNumber = Number.parseInt(searchText, 10);
    const numberSearchQuery = {
      _eq: searchNumber,
    };
    // console.log('searchFields', searchFields)
    const search = {
      _or: searchFields.reduce((acc, field) => {
        let fieldType = field?.type;
        field = field?.name ? field?.name : field;
        if (
          fieldType !== "string" &&
          (field.includes("_id") || field === "id")
        ) {
          return Number.isNaN(searchNumber) ||
            (!Number.isNaN(searchNumber) && searchNumber > 100000000)
            ? acc
            : [
                ...acc,
                pathToJsonValue(field.split("."), numberSearchQuery),
              ];
        }

        return [
          ...acc,
          pathToJsonValue(field.split("."), searchWhere),
        ];
      }, []),
    };

    if (search._or && search._or.length === 0) {
      delete search._or;
    }
    // console.log('orderBy', orderBy)
    // console.log('searchFields', searchFields)
    // console.log('searchText', searchText)
    // console.log('search', search)
    // console.log('where', where)

    try {
      const { page, pageSize } = tableQuery;
      let result;

      const searchToWhere =
        searchFields.length > 0 && searchText && searchText !== ""
          ? search
          : {};

      result = await client.query({
        query,
        variables: {
          limit: pageSize,
          offset: page * pageSize,
          // search: searchFields.length > 0 && searchText && searchText !== '' ? search : {},
          orderBy,
          where: { ...where, ...searchToWhere },
        },
        fetchPolicy: fetchPolicy || "no-cache",
      });

      if (result?.data?.[tableName]?.count < page * pageSize) {
        result = await client.query({
          query,
          variables: {
            limit: pageSize,
            offset: 0,
            // search: searchFields.length > 0 && searchText && searchText !== '' ? search : {},
            orderBy,
            where: { ...where, ...searchToWhere },
          },
          fetchPolicy: fetchPolicy || "no-cache",
        });
      }

      console.log("RESULT=", result);
      // console.log("TABLE DATAAAAAA", result.data[tableName].map((i) => ({
      //     ...i,
      // })),)
      return {
        page:
          result.data[tableName].count < page * pageSize ? 0 : page,
        data: result.data[tableName].data.map(i => ({
          ...i,
        })),
        // totalCount: result.data.total.aggregate.count,
        totalCount: result.data[tableName].count || 0,
      };
    } catch (e) {
      console.error(e);
      return {
        page: 1,
        data: [],
        totalCount: 0,
      };
    }
  };
};

const getValueFromJSONWithPath = (obj, [key, ...rest]) => {
  //console.log("key",key, obj[key]);
  if (key === "coordinates" && obj[key].length === 2) {
    return obj[key][1] + "," + obj[key][0];
  }
  return obj[key]
    ? rest.length === 0
      ? obj[key]
      : getValueFromJSONWithPath(obj[key], rest)
    : "";
};

const parseTableDataForExcelExport = (data, paths = []) =>
  data.map(i => {
    const row = [];
    paths.forEach(path =>
      row.push(getValueFromJSONWithPath(i, path))
    );
    return row;
  });

export function arrayToMap(arr, key, value) {
  return arr.reduce(
    (acc, item) => ({ ...acc, [item[key]]: item[value] }),
    {}
  );
}

const parseSearchFields = (
  searchFields,
  searchFieldsAllowNullValues
) => {
  if (searchFieldsAllowNullValues) {
    return searchFields
      .map(
        searchField =>
          `{ _or: [{ ${searchField}: { _ilike: $search }}, { ${searchField}: { _is_null: true }} ]}`
      )
      .join(" ");
  } else {
    return searchFields
      .map(searchField => `{ ${searchField}: { _ilike: $search }}`)
      .join(" ");
  }
};

const parseSearchIntFields = searchIntFields =>
  searchIntFields
    .map(searchIntField => `{${searchIntField}: { _eq: $searchInt }}`)
    .join(" ");
export const useFetchOptions = (
  disableSearchInt = true,
  entity,
  limit,
  search = "",
  searchInt = null,
  fields = ["id", "name"],
  searchFields = ["name"],
  searchIntFields = ["id"],
  where = {},
  orderBy = {},
  searchFieldsAllowNullValues = false
) => {
  const searchQuery =
    searchFields.length > 0
      ? ` ${parseSearchFields(
          searchFields,
          searchFieldsAllowNullValues
        )} `
      : "";
  const searchIntQuery =
    searchIntFields.length > 0
      ? ` ${parseSearchIntFields(searchIntFields)} `
      : "";
  const { data, error, loading } = useQuery(
    disableSearchInt
      ? gql`
        query FETCH_${entity.toUpperCase()}_OPTIONS(
          $search: String!
          $limit: Int!
          $where: ${entity}_bool_exp = {}
          $orderBy: [${entity}_order_by!] = {}
        ) {
          options: ${entity}(
          where: { 
            _or: [${searchQuery}]
            _and: [$where] 
          } 
          limit: $limit
          order_by: $orderBy
          ) {
            ${fields}
          }
        }
      `
      : gql`
      query FETCH_${entity.toUpperCase()}_OPTIONS(
        ${
          /[a-zA-Z]/.test(search)
            ? "$search: String!"
            : "$searchInt: Int"
        }
        $limit: Int!
        $where: ${entity}_bool_exp = {}
        $orderBy: [${entity}_order_by!] = {}
      ) {
        options: ${entity}(
        where: { 
          _or: [${
            /[a-zA-Z]/.test(search) ? searchQuery : searchIntQuery
          }]
          _and: [$where] 
        } 
        limit: $limit
        order_by: $orderBy
        ) {
          ${fields}
        }
      }
    `,
    {
      variables: {
        search: `%${search}%`,
        searchInt,
        limit,
        where,
        orderBy,
      },
    }
  );
  return { data: data?.options, error, loading };
};

export const durationToMS = (duration, unit) => {
  let multiplier = 1;
  switch (unit) {
    case "m":
      multiplier = 60000;
      break;
    case "h":
      multiplier = 3600000;
      break;
    case "d":
      multiplier = 86400000;
      break;
    case "w":
      multiplier = 604800000;
      break;
    case "M":
      multiplier = 2628000000;
      break;
    case "y":
      multiplier = 31540000000;
      break;
    default:
      multiplier = -1;
      break;
  }
  return duration * multiplier;
};

export const getCitiesFromRegion = () => {
  return fetch(regionURL + "/cities", {
    method: "GET",
    // mode: "cors",
    headers: {
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin':'*',
      // "Access-Control-Allow-Methods": "*",
      // "Access-Control-Allow-Headers": "*"
    },
  });
  // .then(res=> res.json())
  // .then(res => {
  //   console.log("res",res);
  // }).catch(err => {
  //   console.log(err);
  // })
};

export const getDistrictsFromRegion = cityId => {
  return fetch(regionURL + "/cities/" + cityId + "/towns", {
    method: "GET",
    // mode: "cors",
    headers: {
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin':'*',
      // "Access-Control-Allow-Methods": "*",
      // "Access-Control-Allow-Headers": "*"
    },
  });
  // .then(res=> res.json())
  // .then(res => {
  //   console.log("res",res);
  // }).catch(err => {
  //   console.log(err);
  // })
};

export const getLatLonFromGeography = obj => {
  if (!obj) return undefined;
  return {
    lat: obj.coordinates[1],
    lng: obj.coordinates[0],
  };
};

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const useConditionalQueryHook = (condition, hook1, hook2) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    if (condition) {
      // use first hook
      const firstHookState = hook1;
      setState(firstHookState);
    } else {
      // use second hook
      const secondHookState = hook2;
      setState(secondHookState);
    }
  }, [condition, hook1.loading, hook2.loading]);

  return state;
};

export const checkDailyWeeklyMonthly = (startDate, endDate) => {
  const diff = endDate.getTime() - startDate.getTime();
  if (diff <= 86400000) {
    return "daily";
  } else if (diff <= 7 * 86400000) {
    return "weekly";
  } else {
    return "monthly";
  }
};

export const handleSelectedPeriod = (
  period,
  date,
  { next = false, fullCalendarWeeks = false } = {}
) => {
  switch (period) {
    case "daily":
      if (next) return getNextDays(date);
      return getLastDays(date);
    case "weekly":
      if (next) return getNextWeeks(date);
      return getLastWeeks(date, { fullCalendarWeeks });
    case "monthly":
      if (next) return getNextSixMonthNames(date);
      return getLastSixMonthNames(date);
    default:
      return null;
  }
};

export const getLastDays = (date = new Date(), last = 14) => {
  const currentDate = date;
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const lastDays = [];

  for (let i = 0; i < last; i++) {
    lastDays.push(
      new Date(addDays(currentDate, i + 1 - last)).toLocaleString(
        currentLanguage,
        {
          day: "numeric",
          month: "short",
        }
      )
    );
  }

  return lastDays;
};

export const getNextDays = (date = new Date(), next = 14) => {
  const currentDate = date;
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const nextDays = [];

  for (let i = 0; i < next; i++) {
    nextDays.push(
      new Date(addDays(currentDate, i)).toLocaleString(
        currentLanguage,
        {
          day: "numeric",
          month: "short",
        }
      )
    );
  }

  return nextDays;
};

export const getNextWeeks = (date = new Date(), next = 8) => {
  const currentDate = date;
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const nextWeeks = [];

  for (let i = 0; i < next; i++) {
    nextWeeks.push(
      new Date(addDays(currentDate, 7 * i)).toLocaleString(
        currentLanguage,
        {
          day: "numeric",
          month: "short",
        }
      ) +
        " - " +
        new Date(
          addDays(currentDate, 7 * (i + 1) - 1)
        ).toLocaleString(currentLanguage, {
          day: "numeric",
          month: "short",
        })
    );
  }

  return nextWeeks;
};

export const getLastWeeks = (
  date = new Date(),
  { last = 8, fullCalendarWeeks = false } = {}
) => {
  const currentDate = date;
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const lastWeeks = [];

  for (let i = 0; i < last; i++) {
    const startDate = fullCalendarWeeks
      ? addDays(
          startOfWeek(currentDate, {
            weekStartsOn: 1,
          }),
          7 * (i - last + 1)
        )
      : addDays(currentDate, 7 * (i - last) + 1);

    const endDate = fullCalendarWeeks
      ? addDays(
          endOfWeek(currentDate, {
            weekStartsOn: 1,
          }),
          7 * (i - last + 1)
        )
      : addDays(currentDate, 7 * (i + 1 - last));

    lastWeeks.push(
      startDate.toLocaleString(currentLanguage, {
        day: "numeric",
        month: "short",
      }) +
        " - " +
        endDate.toLocaleString(currentLanguage, {
          day: "numeric",
          month: "short",
        })
    );
  }

  return lastWeeks;
};

export const getNextSixMonthNames = (date = new Date(), next = 6) => {
  const currentDate = date;
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const nextSixMonths = [];

  for (let i = 0; i < next; i++) {
    nextSixMonths.push(
      new Date(
        addMonths(currentDate, i)
      ).toLocaleString(currentLanguage, { month: "long" })
    );
  }
  return nextSixMonths;
};

export const getLastSixMonthNames = (date = new Date(), last = 6) => {
  const currentDate = date;
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const lastSixMonths = [];

  for (let i = 0; i < last; i++) {
    lastSixMonths.push(
      new Date(
        addMonths(currentDate, i + 1 - last)
      ).toLocaleString(currentLanguage, { month: "long" })
    );
  }
  return lastSixMonths;
};

export function convertHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

export const dateFormatter = (
  date,
  timeZone,
  startOfDay,
  year,
  month,
  day,
  endOfMonth
) => {
  const dateYear = year ? "" : date.getFullYear();
  const dateMonth = year
    ? ""
    : date.getMonth() + 1 < 10
    ? `0${date.getMonth() + 1}`
    : date.getMonth() + 1;
  const dateDay = year
    ? ""
    : date.getDate() < 10
    ? `0${date.getDate()}`
    : date.getDate();
  const dateTime = startOfDay ? "00:00:00.000" : "23:59:59.999";

  if (!year) {
    return `${dateYear}-${dateMonth}-${dateDay}T${dateTime}${timeZone}`;
  } else if (!endOfMonth) {
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }T${dateTime}${timeZone}`;
  } else {
    return `${year}-${month < 10 ? "0" + month : month}-${new Date(
      year,
      month,
      0
    ).getDate()}T${dateTime}${timeZone}`;
  }
};

export const getDaysOfMonth = (selectedYear, selectedMonth) => {
  const daysInMonth = getDaysInMonth(
    new Date(selectedYear, selectedMonth - 1, 1)
  );
  const days = [];

  for (var i = 0; i < daysInMonth; i++) {
    days.push((i + 1).toString());
  }

  return days;
};

export const getMonthNamesofYear = () => {
  const firstMonth = new Date(2021, 0, 1);
  const currentLanguage = i18n.language === "tr" ? "tr-TR" : "en-GB";

  const allMonths = [
    new Date(addMonths(firstMonth, 0)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 1)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 2)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 3)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 4)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 5)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 6)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 7)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 8)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 9)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 10)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
    new Date(addMonths(firstMonth, 11)).toLocaleString(
      currentLanguage,
      {
        month: "long",
      }
    ),
  ];

  return allMonths;
};
