import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const LoadContainer = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadContainer;
