/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListNotifications = MatxLoadable({
  loader: () => import("../views/notification"),
});

const notificationRoutes = [
  {
    path: "/notification/list",
    component: ListNotifications,
    permission: ["manager"]
  },
];

export default notificationRoutes;
