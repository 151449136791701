/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { useForm } from "react-hook-form";
import Text from "app/ui/Atoms/Text";
import variables from "styles/_variables.scss";
import {
  TextareaAutosize,
  FormControl,
  Button,
  Box,
} from "@material-ui/core";
import i18n from "app/i18n";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { validateEmail } from "app/utils/functions";

function checkEmails(emails) {
  let isValid = true;
  emails.split("\n").forEach(e => {
    if (!validateEmail(e)) {
      isValid = false;
    }
  });
  return isValid;
}

const driversSchema = yup.object().shape({
  driverEmails: yup.string().test("checkSyntax", "", value => {
    if (value.length === 0) {
      return true;
    }
    return checkEmails(value);
  }),
});

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
  },
  form: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: "left",
  },
  elements: {
    marginBottom: "1rem",
  },
  passwordInput: {
    height: 54,
  },
  stepper: {
    padding: 48,
    [theme.breakpoints.down("sm")]: {
      padding: "48px 0px",
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
  },
  resetContainer: {
    display: "flex",
    padding: "0px 48px",
    textAlign: "left",
  },
  btn: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: variables.zipcar_primary,
    color: variables.zipcar_white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 6px -6px",
    width: 120,
    height: 40,
    padding: "14px 28px",
    borderRadius: "25px",
    transformStyle: "preserve-3d",
    "&:hover": {
      backgroundColor: variables.zipcar_primary,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 35px",
    },
  },
  requiredText: {
    color: `${variables.zipcar_salmon} !important`,
    textAlign: "center",
  },
}));

function getSteps() {
  return [
    `${i18n.t("corporateSign.forms.step3.tabs.driverInfo")}`,
    `${i18n.t("corporateSign.forms.step3.tabs.driversConfirmation")}`,
  ];
}

const form11Fields = ["driverEmails"];
const Step11Form = forwardRef(
  ({ allData, onSubmit, onChange }, ref) => {
    const classes = useStyles();
    const submit = useRef();

    const { handleSubmit, register, watch, errors } = useForm({
      mode: "onBlur", // replace "all"
      resolver: yupResolver(driversSchema),
    });

    const watchFields = watch(form11Fields);
    useEffect(() => {
      if (onChange) {
        let emails = [];
        if (
          watchFields.driverEmails &&
          watchFields.driverEmails.length > 0
        ) {
          emails = watchFields.driverEmails?.split("\n");
        }

        onChange({
          ...watchFields,
          driverEmails: emails,
        });
      }
    }, [watchFields.driverEmails]);

    useImperativeHandle(ref, () => ({
      validateForm() {
        submit.current.click();
      },
    }));

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Box className={classes.elements}>
          <Box marginBottom={3}>
            <Text>
              {i18n.t(
                "corporateSign.forms.step3.tabs.driverEmailsDescription"
              )}
            </Text>
          </Box>
          <Text weight="bold">
            {i18n.t("corporateSign.forms.step3.tabs.driverEmails")}
            {` `}(
            {watchFields.driverEmails
              ? watchFields.driverEmails?.split("\n").length
              : 0}
            )
          </Text>
          <FormControl margin="dense" fullWidth>
            <TextareaAutosize
              ref={register}
              name="driverEmails"
              helperText={errors.driverEmails?.message}
              error={!!errors.driverEmails}
              rowsMin={5}
              placeholder={`ahmet@sirketmail.com\nmehmet@sirketmail.com`}
              defaultValue={allData.step3?.step1?.driverEmails?.join(
                "\n"
              )}
            />
          </FormControl>
          {errors.driverEmails &&
            (watchFields.driverEmails?.split("\n").length === 1 ? (
              <Text>{i18n.t("validation.wrongEmailFormat")}</Text>
            ) : (
              <Text>{i18n.t("validation.wrongEmailsFormat")}</Text>
            ))}
        </Box>

        <input
          type="submit"
          ref={submit}
          style={{ display: "none" }}
        />
      </form>
    );
  }
);

// const form12Fields = [];
const Step12Form = forwardRef(({ allData, onSubmit }, ref) => {
  const classes = useStyles();
  const submit = useRef();
  const { handleSubmit } = useForm({
    mode: "onSubmit",
  });

  useImperativeHandle(ref, () => ({
    validateForm() {
      submit.current.click();
    },
  }));

  // const watchFields = watch(form12Fields);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Box>
        {allData.step3?.step1?.driverEmails &&
          allData.step3?.step1?.driverEmails?.map((email, index) => {
            return (
              <Box marginBottom={1}>
                <Text>{`${index + 1}. ${email}`}</Text>
              </Box>
            );
          })}
      </Box>
      <input type="submit" ref={submit} style={{ display: "none" }} />
    </form>
  );
});

const Step11Content = forwardRef(
  ({ allData, onChange, onSubmit }, ref) => {
    return (
      <div>
        <Step11Form
          ref={ref}
          allData={allData}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);

const Step12Content = forwardRef(
  ({ allData, onChange, onSubmit }, ref) => {
    return (
      <div>
        <Step12Form
          ref={ref}
          allData={allData}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);

function getStepContent(
  step,
  allData,
  setStep3Data,
  step3Data,
  currentStep,
  onSubmit
) {
  switch (step) {
    case 0:
      return (
        <div>
          <Step11Content
            ref={el => {
              currentStep.current[step] = el;
            }}
            allData={allData}
            onSubmit={onSubmit}
            onChange={e => {
              setStep3Data({ ...step3Data, step1: e });
            }}
          />
        </div>
      );
    case 1:
      return (
        <div>
          <Step12Content
            ref={el => {
              currentStep.current[step] = el;
            }}
            allData={allData}
            onSubmit={onSubmit}
          />
        </div>
      );
    default:
      return "Unknown step";
  }
}

export default function Step3({
  allData,
  setAllData,
  setFinishSteps,
}) {
  const steps = getSteps();
  const classes = useStyles();
  const currentStep = useRef([]);
  const [activeStep, setActiveStep] = useState(0);
  const [step3Data, setStep3Data] = useState();

  const handleNext = () => {
    if (currentStep.current) {
      currentStep.current[activeStep].validateForm();
    }
  };

  const onSubmit = () => {
    setAllData(step3Data);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    if (activeStep === 1) {
      setFinishSteps(3);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel className={classes.textAlignLeft}>
              <Text size={18} weight="bold">
                {label}
              </Text>
            </StepLabel>
            <StepContent>
              <Box>
                {getStepContent(
                  index,
                  allData,
                  setStep3Data,
                  step3Data,
                  currentStep,
                  onSubmit
                )}
              </Box>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {i18n.t("common.back")}
                  </Button>
                  {/* <Button
                    onClick={() =>
                      handleNext(setAllData, activeStep, step3Data)
                    }
                    className={classes.btn}
                  >
                    {i18n.t("corporateSign.forms.step3.addNewDriver")}
                  </Button> */}
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    className={classes.btn}
                  >
                    {i18n.t("common.finish")}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box square elevation={0} className={classes.resetContainer}>
          <Text size={16} weight="bold">
            {i18n.t("corporateSign.steps.driverInfo")}{" "}
            {i18n.t("corporateSign.steps.stepCompletedMessage")}
          </Text>
        </Box>
      )}
    </div>
  );
}
