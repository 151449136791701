/* eslint-disable import/no-unresolved */
import { MatxLoadable } from "matx";

const ListTasks = MatxLoadable({
  loader: () => import("../views/task"),
});

const TaskNew = MatxLoadable({
  loader: () => import("../views/task/newTask"),
});

const TaskMapLoad = MatxLoadable({
  loader: () => import("../views/task/taskMap"),
});

const taskRoutes = [
  {
    path: "/task/list",
    component: ListTasks,
    permission: ["manager"]
  },
  {
    path: "/task/task_map",
    component: TaskMapLoad,
    permission: ["manager"]
  },
  {
    path: "/task/new_task/:vehicleId/:name/:plate/:startTime/:endTime/:serResId",
    component: TaskNew,
    permission: ["manager"]
  },
  {
    path: "/task/new_task/:valetId",
    component: TaskNew,
    permission: ["manager"]
  },
  {
    path: "/task/new_task",
    component: TaskNew,
    permission: ["manager"]
  },
];

export default taskRoutes;
