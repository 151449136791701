/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";

import { FormControl, FormHelperText } from "@material-ui/core";

import constants from "common/constants";

import TicketEntityAndCategorySelector from "app/ui/Organism/TicketEnitiyAndCategorySelector";
import {
  PrioritySelector,
  StatusSelector,
  TicketAssigneeSelector,
} from "app/ui/Organism/CustomSimpleSelector";
import {
  AccountSelector,
  DriverSelector,
  LocationSelector,
  VehicleSelector,
  ValetSelector,
} from "app/ui/Organism/CustomGqlSelector";
import moment from "moment";
import { GotrueUserManagerSelector } from "./CustomGqlSelector";

const entitySelectors = {
  [constants.ENTITY.ACCOUNT]: AccountSelector,
  [constants.ENTITY.VEHICLE]: VehicleSelector,
  [constants.ENTITY.VALET]: ValetSelector,
  [constants.ENTITY.DRIVER]: DriverSelector,
  [constants.ENTITY.LOCATION]: LocationSelector,
  [constants.ENTITY.IUGO]: undefined,
};

const nullifyOtherEntities = () =>
  Object.values(constants.ENTITY).reduce(
    (a, e) => ({ ...a, [e.toLowerCase()]: null }),
    {}
  );
const TicketInput = ({ onChange, value = {}, error, id }) => {
  // console.log("value > ", value, error, id);
  const EntityItemSelector = entitySelectors[value?.entity];
  const entityKey = value?.entity?.toLowerCase();
  // console.log("TicketInput: entity:", entityKey, "value:", value);
  const categoryObj = value?.category;
  if(value?.category && (value?.category?.entity === null || value?.category?.entity === undefined)){
    categoryObj.__typename = "ticket_category";
    categoryObj.entity = value?.entity;
  }
  const where = {};
  if (
    value?.entity === constants.ENTITY.VEHICLE &&
    (value.driver || value.account)
  ) {
    const date = moment()
      .subtract(2, "months")
      .toISOString();
    where._or = [];
    if (value.driver) {
      where._or.push({
        reservations: {
          driver_id: { _eq: value.driver.id },
          start_date: { _gt: date },
        },
      });
    }
    if (value.account) {
      where._or.push({
        reservations: {
          account_id: { _eq: value.account.id },
          start_date: { _gt: date },
        },
      });
    }
  }

  // if (
  //   value?.entity === constants.ENTITY.ACCOUNT
  //   // && (value.driver || value.account)
  // ) {
  //   // eslint-disable-next-line no-underscore-dangle
  //   where._or = [
  //     { owner_id: { _eq: id } },
  //     {
  //       account_members: {
  //         member_id: { _eq: id },
  //       },
  //     },
  //   ];

  //   console.log(where);
  // }
  // console.log("TicketInput: entity: where", where)
  // useEffect(() => {
  //   console.log("onchange didmount", id);
  //   setTimeout(
  //     () =>
  //       onChange({
  //         ...value,
  //         priority: constants.PRIORITY.PRIMARY,
  //         status: constants.STATUS.ACTIVE,
  //         assignee:
  //           value?.entity &&
  //           (value?.entity === constants.ENTITY.VEHICLE ||
  //             value?.entity === constants.ENTITY.LOCATION)
  //             ? constants.ASSIGNEE.OPERATION
  //             : constants.ASSIGNEE.MEMBER,
  //       }),
  //     id * 5000
  //   );
  // }, []);
  console.log("value", value);
  //   "value, entityKey:",
  //   value,
  //   entityKey,
  //   value[entityKey],
  //   categoryObj
  // );
  return (
    <div id={`item${id}`}>
      <TicketEntityAndCategorySelector
        error={!!error}
        onChange={entityAndCategory =>
          onChange({ ...value, ...entityAndCategory })
        }
        value={{ entity: value.entity, category: categoryObj }}
      />

      <FormControl fullWidth margin="normal">
        {EntityItemSelector && (
          <EntityItemSelector
            onChange={item =>
              onChange({
                ...value,
                ...nullifyOtherEntities(),
                [entityKey]: item,
              })
            }
            value={value && value[entityKey]}
            where={where}
          />
        )}
      </FormControl>

      <FormControl
        fullWidth
        margin="normal"
        error={!!error?.priority}
      >
        <PrioritySelector
          onChange={priority => onChange({ ...value, priority })}
          value={value.priority}
        />
        {error?.priority && (
          <FormHelperText>{error?.priority.message}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        fullWidth
        margin="normal"
        error={!!error?.assignee}
      >
        <TicketAssigneeSelector
          onChange={assignee =>
            onChange({ ...value, assignee, assignedUser: null })
          }
          value={value.assignee}
        />
        {error?.assignee && (
          <FormHelperText>{error?.assignee.message}</FormHelperText>
        )}
      </FormControl>
      {value?.assignee && value?.assignee === "SPECIFIC" && (
        <FormControl
          fullWidth
          margin="normal"
          error={!!error?.assignedUser}
        >
          <GotrueUserManagerSelector
            onChange={assignedUser =>
              onChange({
                ...value,
                assignedUser,
              })
            }
            value={value.assignedUser}
          />
          {error?.assignedUser && (
            <FormHelperText>
              {error?.assignedUser.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl fullWidth margin="normal" error={!!error?.status}>
        <StatusSelector
          onChange={status => onChange({ ...value, status })}
          value={value.status}
        />
        {error?.status && (
          <FormHelperText>{error?.status.message}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default TicketInput;
