import { useMutation, gql } from '@apollo/client'

const CHECK_PASSWORD_STRENGTH_WITHOUT_AUTH = gql`
	mutation checkPasswordStrengthWithoutAuth($password: String!) {
		checkPasswordStrengthWithoutAuth(password: $password) {
			success
			message {
				en
				tr
			}
			response {
				is_valid
				strength
				threshold
			}
			code
		}
	}
`

export const useCheckPasswordStrengthWithoutAuth = () => {
	const [checkPasswordStrengthWithoutAuth, { data, error, loading }] = useMutation(CHECK_PASSWORD_STRENGTH_WITHOUT_AUTH)
	return {
		checkPasswordStrengthWithoutAuth: (password) =>
			checkPasswordStrengthWithoutAuth({
				variables: {
					password,
				},
			}),
		data: {
			isValid: data?.checkPasswordStrengthWithoutAuth?.response.is_valid,
			strength: data?.checkPasswordStrengthWithoutAuth?.response.strength,
			threshold: data?.checkPasswordStrengthWithoutAuth?.response.treshold,
		},
		error,
		loading,
	}
}
