import { useQuery, gql } from "@apollo/client";

const LIST_SALES_REPRESENTATIVES = gql`
  query LIST_SALES_REPRESENTATIVES {
    salesRepresentatives {
      salesReps {
        id
        name
      }
    }
  }
`;

export function useFetchSalesRepresentatives() {
  const { data, error, loading } = useQuery(
    LIST_SALES_REPRESENTATIVES
  );
  return {
    data,
    error,
    loading,
  };
}
