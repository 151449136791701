import React from "react";

import { useTranslation } from "react-i18next";

// import { CloudDownload } from "@material-ui/icons";
import { Box, IconButton, Typography } from "@material-ui/core";

import constants from "common/constants";
import {
  getTicketDeleteURL,
  getTicketMediaURL,
} from "app/utils/functions";

import ArrowHistory from "app/ui/Atoms/ArrowHistory";
import {
  TicketAssigneeChipSelect,
  TicketPriorityChipSelect,
  TicketStatusChipSelect,
} from "app/ui/Molecule/ChipSelect";
import Axios from "axios";
import { useUpdateTicketActionStatus } from "services/gql/ticket";
import { useSnackbar } from "notistack";
import Image from "app/ui/Atoms/Image";
import ImageList from "app/ui/Molecule/ImageList";
import variables from "styles/_variables.scss";
import Button from "../Atoms/Button";
import Text from "../Atoms/Text";

const fromToViewMap = {
  [constants.TICKET_ACTION_TYPE.ASSIGN]: TicketAssigneeChipSelect,
  [constants.TICKET_ACTION_TYPE.PRIORITY]: TicketPriorityChipSelect,
  [constants.TICKET_ACTION_TYPE.STATUS]: TicketStatusChipSelect,
};

export const TicketChangeActionView = ({ type, from, to }) => {
  const { t } = useTranslation();
  const ValueComp = fromToViewMap[type];
  if (!ValueComp) {
    if (type === constants.TICKET_ACTION_TYPE.CHANGEASSIGNED || type === constants.TICKET_ACTION_TYPE.CATEGORY) {
      return (
        <>
          <Typography variant="body2">
            {t(`ticketActions.actionTemplate`, {
              action: `$t(ticketActions.${type.toLowerCase()})`,
            })}
          </Typography>
          <Box display="flex" padding={1}>
            <Text color={variables.zipcar_black} weight={500}>
              {from || ""}
            </Text>
            <ArrowHistory />
            <Text color={variables.zipcar_black} weight={500}>
              {to || ""}
            </Text>
          </Box>
        </>
      );
    }
    return "invalid from to value";
  }
  return (
    <>
      <Typography variant="body2">
        {t(`ticketActions.actionTemplate`, {
          action: `$t(ticketActions.${type.toLowerCase()})`,
        })}
      </Typography>

      <Box display="flex" padding={1}>
        <ValueComp key="from" value={from} />
        <ArrowHistory />
        <ValueComp key="to" value={to} />
      </Box>
    </>
  );
};

export const TicketFileActionView = ({
  ticketId,
  file,
  preview = false,
  deleteVisible,
  actionId,
}) => {
  const { t } = useTranslation();
  // console.log("fileeee", file, actionId);
  const { updateTicketActionStatus } = useUpdateTicketActionStatus();
  const { enqueueSnackbar } = useSnackbar();
  const isImage = ["png", "jpeg", "jpg"].some(fileType =>
    file.endsWith(fileType)
  );
  const fileUrl = getTicketMediaURL(ticketId, file);
  return (
    <>
      {/* <IconButton onClick={() => downloadMedia(file, fileUrl)}> */}
      <ImageList
        bucket="ticket"
        pathPrefix={file}
        imageWidth={160}
        imageHeight={160}
        maxThumbCount={5}
        hideLaunchIcon
        lightboxSupport
      />
      {/* <a target="_blank" rel="noreferrer" href={fileUrl}>
        <CloudDownload />
      </a> */}
      {/* </IconButton> */}
      <Typography variant="body2" display="block">
        {`  ${t("ticketActions.file", { file })}`}
      </Typography>
      {/* {preview && isImage && <img src={fileUrl} alt="ticket-media" />} */}
      {preview && isImage && <Image imgAddress={fileUrl} alt="ticket-media" />}
      {deleteVisible && (
        <Button
          type="iconButton"
          icon="delete"
          iconSize={24}
          onClick={() =>
            updateTicketActionStatus({
              actionId,
              status: "DELETED",
            }).then(res => {
              if (res.data) {
                Axios.get(getTicketDeleteURL(ticketId, file));
                enqueueSnackbar(t("statusTypes.success"), {
                  variant: "success",
                });
              } else {
                enqueueSnackbar(t("statusTypes.failed"), {
                  variant: "failed",
                });
              }
            })
          }
        />
      )}
    </>
  );
};

export const TicketMessageActionView = ({ message }) => {
  return (
    <Typography variant="body2" display="block">
      {message}
    </Typography>
  );
};
