import React from "react";
import PropTypes from "prop-types";

import { Paper, makeStyles, Grid, Collapse } from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing }) => ({
  chatBoxHeader: {
    backgroundColor: palette.primary.main,
    borderTopLeftRadius: spacing(1),
    borderTopRightRadius: spacing(1),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

export const ChatBoxHeader = ({ children }) => {
  return children;
};

export const ChatBoxBody = ({ children }) => {
  return children;
};

/**
 *
 * @param open
 * @param header
 * @param body
 * @constructor
 * @example
 * import ChatBox, {
 *   ChatBoxHeader,
 *   ChatBoxBody,
 * } from "app/ui/Organism/ChatBox";
 * const BasicChatBox = ({ title }) => {
 *   const [open, setOpen] = useState(false);
 *   return (
 *     <ChatBox open={open}>
 *       <ChatBoxHeader>
 *         <Typography color="text.primary">{title}</Typography>
 *       </ChatBoxHeader>
 *       <ChatBoxBody>
 *         <Box>Chatbox Body</Box>
 *       </ChatBoxBody>
 *     </ChatBox>
 *   );
 * };
 */
const ChatBox = ({ open, header, body }) => {
  const classes = useStyles();
  return (
    <Grid
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      container
    >
      <Grid item>
        <Paper square className={classes.chatBoxHeader}>
          {header}
        </Paper>
      </Grid>
      <Grid item>
        <Collapse in={open}>
          <Paper square>{body}</Paper>
        </Collapse>
      </Grid>
    </Grid>
  );
};

ChatBox.propTypes = {
  open: PropTypes.bool,
};

ChatBox.defaultProps = {
  open: false,
};

export default ChatBox;
